// Configuration
@import 'functions';
@import 'functions-standard';
@import 'color-standard';
@import 'variables';
@import 'maps';
@import 'mixins';
@import 'utilities';
@import 'utilities/api';

// SPACING UTILITIES ML, PL, MR and PR
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $index, $space in $spacers {
      .ml#{$infix}-#{$index} {
        margin-left: map-get($spacers, $index) !important;
      }
      .mr#{$infix}-#{$index} {
        margin-right: map-get($spacers, $index) !important;
      }
      .ml#{$infix}-n#{$index} {
        margin-left: -#{map-get($spacers, $index)} !important;
      }
      .mr#{$infix}-n#{$index} {
        margin-right: -#{map-get($spacers, $index)} !important;
      }
      .pl#{$infix}-#{$index} {
        padding-left: map-get($spacers, $index) !important;
      }
      .pr#{$infix}-#{$index} {
        padding-right: map-get($spacers, $index) !important;
      }
    }
  }
}

// FONT SIZES
.text-small {
  font-size: 14px !important;
}
.text-large {
  font-size: 16px !important;
}
.text-xlarge {
  font-size: 18px !important;
}
.text-xxlarge {
  font-size: 21px !important;
}

// HEADING MARGINS
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 8px !important;
}

// PARAGRAPH MARGIN
p {
  margin-bottom: 1rem !important;
}

// FORM LABELS W/O CLASS NAME
label {
  color: $secondary;
  font-weight: 500;
  margin-bottom: 8px;
}

// NO GUTTERS CLASS
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

// SELECT USING FORM-CONTROL
select {
  &.form-control {
    background-repeat: no-repeat;
    background-position: right 0.625rem center;
    background-image: escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$secondary}'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5z'/></svg>")
    );
    background-size: $btn-icon-size;
    &:hover:not([disabled]):not(.disabled),
    &:focus:not([disabled]):not(.disabled) {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{primary}'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5z'/></svg>")
      );
    }
  }
  &.form-control-sm {
    min-height: $input-height-sm;
    background-size: $btn-icon-size-sm;
  }
  &.form-control-lg {
    min-height: $input-height-lg;
    background-size: $btn-icon-size-lg;
  }
  &.form-control-xl {
    min-height: $input-height-xl;
    background-size: $btn-icon-size-xl;
  }
}

// BADGES LIL SQUARES
$badge-padding-x-sm: px-to-rem(6) !default;
$badge-padding-x-lg: px-to-rem(10) !default;
$badge-padding-x-xl: px-to-rem(13) !default;

.badge {
  &::before {
    display: none;
  }

  padding-left: $badge-padding-x !important;

  &.badge-sm {
    padding-left: $badge-padding-x-sm !important;
  }
  &.badge-lg {
    padding-left: $badge-padding-x-lg !important;
  }
  &.badge-xl {
    padding-left: $badge-padding-x-xl !important;
  }
}

// BADGE PILL
.badge-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

// FONT WEIGHTS
.font-weight-bold {
  font-weight: $font-weight-bold !important;
}
.font-weight-bolder {
  font-weight: $font-weight-extrabold !important;
}
.font-weight-extrabold {
  font-weight: $font-weight-extrabold !important;
}
.font-weight-extrabolder {
  font-weight: $font-weight-extrabold !important;
}
.font-weight-normal {
  font-weight: $font-weight-normal !important;
}
.font-weight-light {
  font-weight: $font-weight-light !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}

// TEXT ALIGNMENT
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

// FLOATS
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

// LINE HEIGHT
.line-height-1 {
  line-height: 1 !important;
}

// OPACITIES
$polyfill_opacities: (
  xxl: 0.7,
  xl: 0.4,
  lg: 0.2,
  md: 0.1,
  sm: 0.05,
);
@mixin set-color-opacity-rules($colors) {
  @each $opacity in map-keys($polyfill_opacities) {
    @each $color_name, $color_value in $colors {
      .bg-#{$color_name}-alpha-#{$opacity} {
        background: rgba(
          $color_value,
          map-get($polyfill_opacities, $opacity)
        ) !important;
      }

      .alpha-#{$opacity} {
        opacity: map-get($polyfill_opacities, $opacity) !important;
      }

      .border-#{$color_name}-alpha-#{$opacity} {
        border-color: rgba(
          $color_value,
          map-get($polyfill_opacities, $opacity)
        ) !important;
      }

      .text-#{$color_name}-alpha-#{$opacity} {
        color: rgba(
          $color_value,
          map-get($polyfill_opacities, $opacity)
        ) !important;
      }
    }
  }
}

@include set-color-opacity-rules($theme-colors);
@include set-color-opacity-rules($custom-bg-colors);
@include set-color-opacity-rules($bg-alt-colors);
@include set-color-opacity-rules($secondary-colors);

// BORDER OPACITY

@mixin set-border-opacity-rules($colors) {
  @each $opacity in map-keys($polyfill_opacities) {
    .border.border-alpha-#{$opacity} {
      --#{$prefix}border-color: #{rgba(
          $border-color,
          map-get($polyfill_opacities, $opacity)
        )} !important;
    }

    @each $color_name, $color_value in $colors {
      .border-#{$color_name}-alpha-#{$opacity} {
        --#{$prefix}border-color: #{rgba(
            $color_value,
            map-get($polyfill_opacities, $opacity)
          )} !important;
      }

      .border-#{$color_name}.border-alpha-#{$opacity} {
        --#{$prefix}border-color: #{rgba(
            $color_value,
            map-get($polyfill_opacities, $opacity)
          )} !important;
      }
    }
  }
}

@include set-border-opacity-rules($theme-colors);
@include set-border-opacity-rules($custom-bg-colors);
@include set-border-opacity-rules($bg-alt-colors);
@include set-border-opacity-rules($secondary-colors);