/*
 * Design System variables introduced by SGBS
 */
.bg-primary {
  background-color: #000 !important;
}

a.bg-primary:not(.card):hover, a.bg-primary:not(.card):focus, a.bg-primary:not(.card) .focus,
button.bg-primary:not(.card):hover,
button.bg-primary:not(.card):focus,
button.bg-primary:not(.card) .focus {
  background-color: black !important;
}

.bg-secondary {
  background-color: #6e777a !important;
}

a.bg-secondary:not(.card):hover, a.bg-secondary:not(.card):focus, a.bg-secondary:not(.card) .focus,
button.bg-secondary:not(.card):hover,
button.bg-secondary:not(.card):focus,
button.bg-secondary:not(.card) .focus {
  background-color: #565d5f !important;
}

.bg-success {
  background-color: #00aa0c !important;
}

a.bg-success:not(.card):hover, a.bg-success:not(.card):focus, a.bg-success:not(.card) .focus,
button.bg-success:not(.card):hover,
button.bg-success:not(.card):focus,
button.bg-success:not(.card) .focus {
  background-color: #007708 !important;
}

.bg-info {
  background-color: #2469ff !important;
}

a.bg-info:not(.card):hover, a.bg-info:not(.card):focus, a.bg-info:not(.card) .focus,
button.bg-info:not(.card):hover,
button.bg-info:not(.card):focus,
button.bg-info:not(.card) .focus {
  background-color: #004cf0 !important;
}

.bg-warning {
  background-color: #da7400 !important;
}

a.bg-warning:not(.card):hover, a.bg-warning:not(.card):focus, a.bg-warning:not(.card) .focus,
button.bg-warning:not(.card):hover,
button.bg-warning:not(.card):focus,
button.bg-warning:not(.card) .focus {
  background-color: #a75900 !important;
}

.bg-danger {
  background-color: #dd3724 !important;
}

a.bg-danger:not(.card):hover, a.bg-danger:not(.card):focus, a.bg-danger:not(.card) .focus,
button.bg-danger:not(.card):hover,
button.bg-danger:not(.card):focus,
button.bg-danger:not(.card) .focus {
  background-color: #b22b1c !important;
}

.bg-light {
  background-color: #c4c8ca !important;
}

a.bg-light:not(.card):hover, a.bg-light:not(.card):focus, a.bg-light:not(.card) .focus,
button.bg-light:not(.card):hover,
button.bg-light:not(.card):focus,
button.bg-light:not(.card) .focus {
  background-color: #a9afb2 !important;
}

.bg-dark {
  background-color: #414949 !important;
}

a.bg-dark:not(.card):hover, a.bg-dark:not(.card):focus, a.bg-dark:not(.card) .focus,
button.bg-dark:not(.card):hover,
button.bg-dark:not(.card):focus,
button.bg-dark:not(.card) .focus {
  background-color: #292e2e !important;
}

.bg-white {
  background-color: white !important;
}

a.bg-white:not(.card):hover, a.bg-white:not(.card):focus, a.bg-white:not(.card) .focus,
button.bg-white:not(.card):hover,
button.bg-white:not(.card):focus,
button.bg-white:not(.card) .focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #231f20 !important;
}

a.bg-black:not(.card):hover, a.bg-black:not(.card):focus, a.bg-black:not(.card) .focus,
button.bg-black:not(.card):hover,
button.bg-black:not(.card):focus,
button.bg-black:not(.card) .focus {
  background-color: #080707 !important;
}

.bg-socgen {
  background-color: #e60028 !important;
}

a.bg-socgen:not(.card):hover, a.bg-socgen:not(.card):focus, a.bg-socgen:not(.card) .focus,
button.bg-socgen:not(.card):hover,
button.bg-socgen:not(.card):focus,
button.bg-socgen:not(.card) .focus {
  background-color: #b3001f !important;
}

.bg-primary-alt {
  background-color: #fff !important;
}

a.bg-primary-alt:not(.card):hover, a.bg-primary-alt:not(.card):focus, a.bg-primary-alt:not(.card) .focus,
button.bg-primary-alt:not(.card):hover,
button.bg-primary-alt:not(.card):focus,
button.bg-primary-alt:not(.card) .focus {
  background-color: #e6e6e6 !important;
}

.bg-apricot {
  background-color: #e88e5e !important;
}

a.bg-apricot:not(.card):hover, a.bg-apricot:not(.card):focus, a.bg-apricot:not(.card) .focus,
button.bg-apricot:not(.card):hover,
button.bg-apricot:not(.card):focus,
button.bg-apricot:not(.card) .focus {
  background-color: #e26f31 !important;
}

.bg-azure {
  background-color: #38699f !important;
}

a.bg-azure:not(.card):hover, a.bg-azure:not(.card):focus, a.bg-azure:not(.card) .focus,
button.bg-azure:not(.card):hover,
button.bg-azure:not(.card):focus,
button.bg-azure:not(.card) .focus {
  background-color: #2b5079 !important;
}

.bg-buttercup {
  background-color: #f6a416 !important;
}

a.bg-buttercup:not(.card):hover, a.bg-buttercup:not(.card):focus, a.bg-buttercup:not(.card) .focus,
button.bg-buttercup:not(.card):hover,
button.bg-buttercup:not(.card):focus,
button.bg-buttercup:not(.card) .focus {
  background-color: #d18708 !important;
}

.bg-copper-rust {
  background-color: #974a5a !important;
}

a.bg-copper-rust:not(.card):hover, a.bg-copper-rust:not(.card):focus, a.bg-copper-rust:not(.card) .focus,
button.bg-copper-rust:not(.card):hover,
button.bg-copper-rust:not(.card):focus,
button.bg-copper-rust:not(.card) .focus {
  background-color: #753946 !important;
}

.bg-cornflower-blue {
  background-color: #6488ff !important;
}

a.bg-cornflower-blue:not(.card):hover, a.bg-cornflower-blue:not(.card):focus, a.bg-cornflower-blue:not(.card) .focus,
button.bg-cornflower-blue:not(.card):hover,
button.bg-cornflower-blue:not(.card):focus,
button.bg-cornflower-blue:not(.card) .focus {
  background-color: #3161ff !important;
}

.bg-dodger-blue {
  background-color: #5d55ff !important;
}

a.bg-dodger-blue:not(.card):hover, a.bg-dodger-blue:not(.card):focus, a.bg-dodger-blue:not(.card) .focus,
button.bg-dodger-blue:not(.card):hover,
button.bg-dodger-blue:not(.card):focus,
button.bg-dodger-blue:not(.card) .focus {
  background-color: #2c22ff !important;
}

.bg-east-bay {
  background-color: #4a5885 !important;
}

a.bg-east-bay:not(.card):hover, a.bg-east-bay:not(.card):focus, a.bg-east-bay:not(.card) .focus,
button.bg-east-bay:not(.card):hover,
button.bg-east-bay:not(.card):focus,
button.bg-east-bay:not(.card) .focus {
  background-color: #384264 !important;
}

.bg-faded-jade {
  background-color: #408182 !important;
}

a.bg-faded-jade:not(.card):hover, a.bg-faded-jade:not(.card):focus, a.bg-faded-jade:not(.card) .focus,
button.bg-faded-jade:not(.card):hover,
button.bg-faded-jade:not(.card):focus,
button.bg-faded-jade:not(.card) .focus {
  background-color: #2f5f60 !important;
}

.bg-heather {
  background-color: #b4bbcb !important;
}

a.bg-heather:not(.card):hover, a.bg-heather:not(.card):focus, a.bg-heather:not(.card) .focus,
button.bg-heather:not(.card):hover,
button.bg-heather:not(.card):focus,
button.bg-heather:not(.card) .focus {
  background-color: #96a0b6 !important;
}

.bg-mako {
  background-color: #444852 !important;
}

a.bg-mako:not(.card):hover, a.bg-mako:not(.card):focus, a.bg-mako:not(.card) .focus,
button.bg-mako:not(.card):hover,
button.bg-mako:not(.card):focus,
button.bg-mako:not(.card) .focus {
  background-color: #2d3036 !important;
}

.bg-manatee {
  background-color: #8b93a8 !important;
}

a.bg-manatee:not(.card):hover, a.bg-manatee:not(.card):focus, a.bg-manatee:not(.card) .focus,
button.bg-manatee:not(.card):hover,
button.bg-manatee:not(.card):focus,
button.bg-manatee:not(.card) .focus {
  background-color: #6e7892 !important;
}

.bg-mars {
  background-color: #d53229 !important;
}

a.bg-mars:not(.card):hover, a.bg-mars:not(.card):focus, a.bg-mars:not(.card) .focus,
button.bg-mars:not(.card):hover,
button.bg-mars:not(.card):focus,
button.bg-mars:not(.card) .focus {
  background-color: #aa2821 !important;
}

.bg-mercury {
  background-color: #e8e8e8 !important;
}

a.bg-mercury:not(.card):hover, a.bg-mercury:not(.card):focus, a.bg-mercury:not(.card) .focus,
button.bg-mercury:not(.card):hover,
button.bg-mercury:not(.card):focus,
button.bg-mercury:not(.card) .focus {
  background-color: #cfcfcf !important;
}

.bg-pacific-blue {
  background-color: #00b4bb !important;
}

a.bg-pacific-blue:not(.card):hover, a.bg-pacific-blue:not(.card):focus, a.bg-pacific-blue:not(.card) .focus,
button.bg-pacific-blue:not(.card):hover,
button.bg-pacific-blue:not(.card):focus,
button.bg-pacific-blue:not(.card) .focus {
  background-color: #008388 !important;
}

.bg-plantation {
  background-color: #275855 !important;
}

a.bg-plantation:not(.card):hover, a.bg-plantation:not(.card):focus, a.bg-plantation:not(.card) .focus,
button.bg-plantation:not(.card):hover,
button.bg-plantation:not(.card):focus,
button.bg-plantation:not(.card) .focus {
  background-color: #173533 !important;
}

.bg-puerto-rico {
  background-color: #41c49d !important;
}

a.bg-puerto-rico:not(.card):hover, a.bg-puerto-rico:not(.card):focus, a.bg-puerto-rico:not(.card) .focus,
button.bg-puerto-rico:not(.card):hover,
button.bg-puerto-rico:not(.card):focus,
button.bg-puerto-rico:not(.card) .focus {
  background-color: #32a07f !important;
}

.bg-royal-blue {
  background-color: #5c56e8 !important;
}

a.bg-royal-blue:not(.card):hover, a.bg-royal-blue:not(.card):focus, a.bg-royal-blue:not(.card) .focus,
button.bg-royal-blue:not(.card):hover,
button.bg-royal-blue:not(.card):focus,
button.bg-royal-blue:not(.card) .focus {
  background-color: #3129e2 !important;
}

.bg-spicy-mix {
  background-color: #854b4b !important;
}

a.bg-spicy-mix:not(.card):hover, a.bg-spicy-mix:not(.card):focus, a.bg-spicy-mix:not(.card) .focus,
button.bg-spicy-mix:not(.card):hover,
button.bg-spicy-mix:not(.card):focus,
button.bg-spicy-mix:not(.card) .focus {
  background-color: #643939 !important;
}

.bg-turquoise-blue {
  background-color: #68efad !important;
}

a.bg-turquoise-blue:not(.card):hover, a.bg-turquoise-blue:not(.card):focus, a.bg-turquoise-blue:not(.card) .focus,
button.bg-turquoise-blue:not(.card):hover,
button.bg-turquoise-blue:not(.card):focus,
button.bg-turquoise-blue:not(.card) .focus {
  background-color: #3aea94 !important;
}

.bg-valencia {
  background-color: #dc4941 !important;
}

a.bg-valencia:not(.card):hover, a.bg-valencia:not(.card):focus, a.bg-valencia:not(.card) .focus,
button.bg-valencia:not(.card):hover,
button.bg-valencia:not(.card):focus,
button.bg-valencia:not(.card) .focus {
  background-color: #c62d24 !important;
}

.bg-victoria {
  background-color: #514b90 !important;
}

a.bg-victoria:not(.card):hover, a.bg-victoria:not(.card):focus, a.bg-victoria:not(.card) .focus,
button.bg-victoria:not(.card):hover,
button.bg-victoria:not(.card):focus,
button.bg-victoria:not(.card) .focus {
  background-color: #3e3a6e !important;
}

.bg-lvl1 {
  background-color: #fff !important;
}

a.bg-lvl1:not(.card):hover, a.bg-lvl1:not(.card):focus, a.bg-lvl1:not(.card) .focus,
button.bg-lvl1:not(.card):hover,
button.bg-lvl1:not(.card):focus,
button.bg-lvl1:not(.card) .focus {
  background-color: #e6e6e6 !important;
}

.bg-lvl2 {
  background-color: #f5f6f7 !important;
}

a.bg-lvl2:not(.card):hover, a.bg-lvl2:not(.card):focus, a.bg-lvl2:not(.card) .focus,
button.bg-lvl2:not(.card):hover,
button.bg-lvl2:not(.card):focus,
button.bg-lvl2:not(.card) .focus {
  background-color: #d9dde0 !important;
}

.bg-lvl3 {
  background-color: #eceeef !important;
}

a.bg-lvl3:not(.card):hover, a.bg-lvl3:not(.card):focus, a.bg-lvl3:not(.card) .focus,
button.bg-lvl3:not(.card):hover,
button.bg-lvl3:not(.card):focus,
button.bg-lvl3:not(.card) .focus {
  background-color: #d0d5d8 !important;
}

.bg-lvl4 {
  background-color: #e3e6e7 !important;
}

a.bg-lvl4:not(.card):hover, a.bg-lvl4:not(.card):focus, a.bg-lvl4:not(.card) .focus,
button.bg-lvl4:not(.card):hover,
button.bg-lvl4:not(.card):focus,
button.bg-lvl4:not(.card) .focus {
  background-color: #c8cdcf !important;
}

.bg-alt-lvl1 {
  background-color: #303333 !important;
}

a.bg-alt-lvl1:not(.card):hover, a.bg-alt-lvl1:not(.card):focus, a.bg-alt-lvl1:not(.card) .focus,
button.bg-alt-lvl1:not(.card):hover,
button.bg-alt-lvl1:not(.card):focus,
button.bg-alt-lvl1:not(.card) .focus {
  background-color: #171919 !important;
}

.bg-alt-lvl2 {
  background-color: #222424 !important;
}

a.bg-alt-lvl2:not(.card):hover, a.bg-alt-lvl2:not(.card):focus, a.bg-alt-lvl2:not(.card) .focus,
button.bg-alt-lvl2:not(.card):hover,
button.bg-alt-lvl2:not(.card):focus,
button.bg-alt-lvl2:not(.card) .focus {
  background-color: #090a0a !important;
}

.bg-alt-lvl3 {
  background-color: #151616 !important;
}

a.bg-alt-lvl3:not(.card):hover, a.bg-alt-lvl3:not(.card):focus, a.bg-alt-lvl3:not(.card) .focus,
button.bg-alt-lvl3:not(.card):hover,
button.bg-alt-lvl3:not(.card):focus,
button.bg-alt-lvl3:not(.card) .focus {
  background-color: black !important;
}

.bg-alt-lvl4 {
  background-color: #000 !important;
}

a.bg-alt-lvl4:not(.card):hover, a.bg-alt-lvl4:not(.card):focus, a.bg-alt-lvl4:not(.card) .focus,
button.bg-alt-lvl4:not(.card):hover,
button.bg-alt-lvl4:not(.card):focus,
button.bg-alt-lvl4:not(.card) .focus {
  background-color: black !important;
}

.bg-lvl4 .bg-secondary {
  background-color: rgba(95, 102, 104, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl4 .bg-light {
  background-color: rgba(177, 183, 185, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl4 .bg-dark {
  background-color: rgba(50, 57, 57, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl4 .bg-info {
  background-color: rgba(5, 84, 255, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl4 .bg-success {
  background-color: rgba(0, 138, 9, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl4 .bg-warning {
  background-color: rgba(188, 101, 0, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl4 .bg-danger {
  background-color: rgba(194, 47, 31, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl3 .bg-secondary {
  background-color: rgba(102, 110, 112, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl3 .bg-light {
  background-color: rgba(185, 190, 192, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl3 .bg-dark {
  background-color: rgba(55, 62, 62, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl3 .bg-info {
  background-color: rgba(15, 91, 255, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl3 .bg-success {
  background-color: rgba(0, 148, 10, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl3 .bg-warning {
  background-color: rgba(198, 106, 0, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl3 .bg-danger {
  background-color: rgba(203, 49, 32, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl2 .bg-secondary {
  background-color: rgba(104, 112, 115, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl2 .bg-light {
  background-color: rgba(193, 197, 199, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl2 .bg-dark {
  background-color: rgba(60, 67, 67, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl2 .bg-info {
  background-color: rgba(26, 98, 255, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl2 .bg-success {
  background-color: rgba(0, 158, 11, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl2 .bg-warning {
  background-color: rgba(208, 111, 0, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl2 .bg-danger {
  background-color: rgba(212, 51, 33, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl1 .bg-secondary {
  background-color: rgba(110, 119, 122, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl1 .bg-light {
  background-color: rgba(196, 200, 202, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl1 .bg-dark {
  background-color: rgba(65, 73, 73, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl1 .bg-info {
  background-color: rgba(36, 105, 255, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl1 .bg-success {
  background-color: rgba(0, 170, 12, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl1 .bg-warning {
  background-color: rgba(218, 116, 0, var(--bs-bg-opacity, 1)) !important;
}

.bg-lvl1 .bg-danger {
  background-color: rgba(221, 55, 36, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl4 .bg-secondary {
  background-color: rgba(112, 121, 122, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl4 .bg-light {
  background-color: rgba(163, 175, 179, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl4 .bg-dark {
  background-color: rgba(52, 55, 55, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl4 .bg-info {
  background-color: rgba(20, 118, 255, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl4 .bg-success {
  background-color: rgba(0, 168, 62, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl4 .bg-warning {
  background-color: rgba(204, 116, 0, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl4 .bg-danger {
  background-color: rgba(217, 61, 38, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl3 .bg-secondary {
  background-color: rgba(122, 131, 133, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl3 .bg-light {
  background-color: rgba(177, 187, 190, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl3 .bg-dark {
  background-color: rgba(59, 63, 63, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl3 .bg-info {
  background-color: rgba(41, 130, 255, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl3 .bg-success {
  background-color: rgba(0, 184, 68, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl3 .bg-warning {
  background-color: rgba(219, 124, 0, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl3 .bg-danger {
  background-color: rgba(232, 68, 44, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl2 .bg-secondary {
  background-color: rgba(132, 141, 143, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl2 .bg-light {
  background-color: rgba(194, 202, 204, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl2 .bg-dark {
  background-color: rgba(69, 74, 74, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl2 .bg-info {
  background-color: rgba(61, 142, 255, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl2 .bg-success {
  background-color: rgba(0, 199, 74, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl2 .bg-warning {
  background-color: rgba(235, 133, 0, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl2 .bg-danger {
  background-color: rgba(244, 77, 52, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl1 .bg-secondary {
  background-color: rgba(148, 156, 158, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl1 .bg-light {
  background-color: rgba(216, 221, 222, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl1 .bg-dark {
  background-color: rgba(81, 87, 87, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl1 .bg-info {
  background-color: rgba(82, 154, 255, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl1 .bg-success {
  background-color: rgba(0, 215, 80, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl1 .bg-warning {
  background-color: rgba(249, 141, 0, var(--bs-bg-opacity, 1)) !important;
}

.bg-alt-lvl1 .bg-danger {
  background-color: rgba(255, 86, 60, var(--bs-bg-opacity, 1)) !important;
}

.bg-red-10 {
  background: var(--bs-red-10) !important;
  color: var(--bs-red-60) !important;
}

.bg-red-20 {
  background: var(--bs-red-20) !important;
  color: var(--bs-red-70) !important;
}

.bg-red-30 {
  background: var(--bs-red-30) !important;
  color: var(--bs-red-80) !important;
}

.bg-red-40 {
  background: var(--bs-red-40) !important;
  color: var(--bs-red-90) !important;
}

.bg-red-50 {
  background: var(--bs-red-50) !important;
  color: var(--bs-red-100) !important;
}

.bg-red-60 {
  background: var(--bs-red-60) !important;
  color: var(--bs-red-10) !important;
}

.bg-red-70 {
  background: var(--bs-red-70) !important;
  color: var(--bs-red-20) !important;
}

.bg-red-80 {
  background: var(--bs-red-80) !important;
  color: var(--bs-red-30) !important;
}

.bg-red-90 {
  background: var(--bs-red-90) !important;
  color: var(--bs-red-40) !important;
}

.bg-red-100 {
  background: var(--bs-red-100) !important;
  color: var(--bs-red-50) !important;
}

.bg-orange-10 {
  background: var(--bs-orange-10) !important;
  color: var(--bs-orange-60) !important;
}

.bg-orange-20 {
  background: var(--bs-orange-20) !important;
  color: var(--bs-orange-70) !important;
}

.bg-orange-30 {
  background: var(--bs-orange-30) !important;
  color: var(--bs-orange-80) !important;
}

.bg-orange-40 {
  background: var(--bs-orange-40) !important;
  color: var(--bs-orange-90) !important;
}

.bg-orange-50 {
  background: var(--bs-orange-50) !important;
  color: var(--bs-orange-100) !important;
}

.bg-orange-60 {
  background: var(--bs-orange-60) !important;
  color: var(--bs-orange-10) !important;
}

.bg-orange-70 {
  background: var(--bs-orange-70) !important;
  color: var(--bs-orange-20) !important;
}

.bg-orange-80 {
  background: var(--bs-orange-80) !important;
  color: var(--bs-orange-30) !important;
}

.bg-orange-90 {
  background: var(--bs-orange-90) !important;
  color: var(--bs-orange-40) !important;
}

.bg-orange-100 {
  background: var(--bs-orange-100) !important;
  color: var(--bs-orange-50) !important;
}

.bg-yellow-10 {
  background: var(--bs-yellow-10) !important;
  color: var(--bs-yellow-60) !important;
}

.bg-yellow-20 {
  background: var(--bs-yellow-20) !important;
  color: var(--bs-yellow-70) !important;
}

.bg-yellow-30 {
  background: var(--bs-yellow-30) !important;
  color: var(--bs-yellow-80) !important;
}

.bg-yellow-40 {
  background: var(--bs-yellow-40) !important;
  color: var(--bs-yellow-90) !important;
}

.bg-yellow-50 {
  background: var(--bs-yellow-50) !important;
  color: var(--bs-yellow-100) !important;
}

.bg-yellow-60 {
  background: var(--bs-yellow-60) !important;
  color: var(--bs-yellow-10) !important;
}

.bg-yellow-70 {
  background: var(--bs-yellow-70) !important;
  color: var(--bs-yellow-20) !important;
}

.bg-yellow-80 {
  background: var(--bs-yellow-80) !important;
  color: var(--bs-yellow-30) !important;
}

.bg-yellow-90 {
  background: var(--bs-yellow-90) !important;
  color: var(--bs-yellow-40) !important;
}

.bg-yellow-100 {
  background: var(--bs-yellow-100) !important;
  color: var(--bs-yellow-50) !important;
}

.bg-green-10 {
  background: var(--bs-green-10) !important;
  color: var(--bs-green-60) !important;
}

.bg-green-20 {
  background: var(--bs-green-20) !important;
  color: var(--bs-green-70) !important;
}

.bg-green-30 {
  background: var(--bs-green-30) !important;
  color: var(--bs-green-80) !important;
}

.bg-green-40 {
  background: var(--bs-green-40) !important;
  color: var(--bs-green-90) !important;
}

.bg-green-50 {
  background: var(--bs-green-50) !important;
  color: var(--bs-green-100) !important;
}

.bg-green-60 {
  background: var(--bs-green-60) !important;
  color: var(--bs-green-10) !important;
}

.bg-green-70 {
  background: var(--bs-green-70) !important;
  color: var(--bs-green-20) !important;
}

.bg-green-80 {
  background: var(--bs-green-80) !important;
  color: var(--bs-green-30) !important;
}

.bg-green-90 {
  background: var(--bs-green-90) !important;
  color: var(--bs-green-40) !important;
}

.bg-green-100 {
  background: var(--bs-green-100) !important;
  color: var(--bs-green-50) !important;
}

.bg-turquoise-10 {
  background: var(--bs-turquoise-10) !important;
  color: var(--bs-turquoise-60) !important;
}

.bg-turquoise-20 {
  background: var(--bs-turquoise-20) !important;
  color: var(--bs-turquoise-70) !important;
}

.bg-turquoise-30 {
  background: var(--bs-turquoise-30) !important;
  color: var(--bs-turquoise-80) !important;
}

.bg-turquoise-40 {
  background: var(--bs-turquoise-40) !important;
  color: var(--bs-turquoise-90) !important;
}

.bg-turquoise-50 {
  background: var(--bs-turquoise-50) !important;
  color: var(--bs-turquoise-100) !important;
}

.bg-turquoise-60 {
  background: var(--bs-turquoise-60) !important;
  color: var(--bs-turquoise-10) !important;
}

.bg-turquoise-70 {
  background: var(--bs-turquoise-70) !important;
  color: var(--bs-turquoise-20) !important;
}

.bg-turquoise-80 {
  background: var(--bs-turquoise-80) !important;
  color: var(--bs-turquoise-30) !important;
}

.bg-turquoise-90 {
  background: var(--bs-turquoise-90) !important;
  color: var(--bs-turquoise-40) !important;
}

.bg-turquoise-100 {
  background: var(--bs-turquoise-100) !important;
  color: var(--bs-turquoise-50) !important;
}

.bg-blue-10 {
  background: var(--bs-blue-10) !important;
  color: var(--bs-blue-60) !important;
}

.bg-blue-20 {
  background: var(--bs-blue-20) !important;
  color: var(--bs-blue-70) !important;
}

.bg-blue-30 {
  background: var(--bs-blue-30) !important;
  color: var(--bs-blue-80) !important;
}

.bg-blue-40 {
  background: var(--bs-blue-40) !important;
  color: var(--bs-blue-90) !important;
}

.bg-blue-50 {
  background: var(--bs-blue-50) !important;
  color: var(--bs-blue-100) !important;
}

.bg-blue-60 {
  background: var(--bs-blue-60) !important;
  color: var(--bs-blue-10) !important;
}

.bg-blue-70 {
  background: var(--bs-blue-70) !important;
  color: var(--bs-blue-20) !important;
}

.bg-blue-80 {
  background: var(--bs-blue-80) !important;
  color: var(--bs-blue-30) !important;
}

.bg-blue-90 {
  background: var(--bs-blue-90) !important;
  color: var(--bs-blue-40) !important;
}

.bg-blue-100 {
  background: var(--bs-blue-100) !important;
  color: var(--bs-blue-50) !important;
}

.bg-purple-10 {
  background: var(--bs-purple-10) !important;
  color: var(--bs-purple-60) !important;
}

.bg-purple-20 {
  background: var(--bs-purple-20) !important;
  color: var(--bs-purple-70) !important;
}

.bg-purple-30 {
  background: var(--bs-purple-30) !important;
  color: var(--bs-purple-80) !important;
}

.bg-purple-40 {
  background: var(--bs-purple-40) !important;
  color: var(--bs-purple-90) !important;
}

.bg-purple-50 {
  background: var(--bs-purple-50) !important;
  color: var(--bs-purple-100) !important;
}

.bg-purple-60 {
  background: var(--bs-purple-60) !important;
  color: var(--bs-purple-10) !important;
}

.bg-purple-70 {
  background: var(--bs-purple-70) !important;
  color: var(--bs-purple-20) !important;
}

.bg-purple-80 {
  background: var(--bs-purple-80) !important;
  color: var(--bs-purple-30) !important;
}

.bg-purple-90 {
  background: var(--bs-purple-90) !important;
  color: var(--bs-purple-40) !important;
}

.bg-purple-100 {
  background: var(--bs-purple-100) !important;
  color: var(--bs-purple-50) !important;
}

.bg-pink-10 {
  background: var(--bs-pink-10) !important;
  color: var(--bs-pink-60) !important;
}

.bg-pink-20 {
  background: var(--bs-pink-20) !important;
  color: var(--bs-pink-70) !important;
}

.bg-pink-30 {
  background: var(--bs-pink-30) !important;
  color: var(--bs-pink-80) !important;
}

.bg-pink-40 {
  background: var(--bs-pink-40) !important;
  color: var(--bs-pink-90) !important;
}

.bg-pink-50 {
  background: var(--bs-pink-50) !important;
  color: var(--bs-pink-100) !important;
}

.bg-pink-60 {
  background: var(--bs-pink-60) !important;
  color: var(--bs-pink-10) !important;
}

.bg-pink-70 {
  background: var(--bs-pink-70) !important;
  color: var(--bs-pink-20) !important;
}

.bg-pink-80 {
  background: var(--bs-pink-80) !important;
  color: var(--bs-pink-30) !important;
}

.bg-pink-90 {
  background: var(--bs-pink-90) !important;
  color: var(--bs-pink-40) !important;
}

.bg-pink-100 {
  background: var(--bs-pink-100) !important;
  color: var(--bs-pink-50) !important;
}

.border {
  --bs-border-color: #c4c8ca !important;
  --bs-border-style: solid !important;
  --bs-border-width: 1px;
}

.border-top {
  --bs-border-opacity: 1;
  --bs-border-style: solid !important;
  --bs-border-width: 1px;
  --bs-border-color: rgba(196, 200, 202, var(--bs-border-opacity)) !important;
}

.border-end {
  --bs-border-opacity: 1;
  --bs-border-style: solid !important;
  --bs-border-width: 1px;
  --bs-border-color: rgba(196, 200, 202, var(--bs-border-opacity)) !important;
}

.border-bottom {
  --bs-border-opacity: 1;
  --bs-border-style: solid !important;
  --bs-border-width: 1px;
  --bs-border-color: rgba(196, 200, 202, var(--bs-border-opacity)) !important;
}

.border-start {
  --bs-border-opacity: 1;
  --bs-border-style: solid !important;
  --bs-border-width: 1px;
  --bs-border-color: rgba(196, 200, 202, var(--bs-border-opacity)) !important;
}

.border-sm {
  --bs-border-width: 0.03125rem !important;
}

.border-md {
  --bs-border-width: 0.0625rem !important;
}

.border-lg {
  --bs-border-width: 0.1875rem !important;
}

.border-xl {
  --bs-border-width: 0.375rem !important;
}

.border-primary {
  --bs-border-color: #000 !important;
}

.border-secondary {
  --bs-border-color: #6e777a !important;
}

.border-success {
  --bs-border-color: #00aa0c !important;
}

.border-info {
  --bs-border-color: #2469ff !important;
}

.border-warning {
  --bs-border-color: #da7400 !important;
}

.border-danger {
  --bs-border-color: #dd3724 !important;
}

.border-light {
  --bs-border-color: #c4c8ca !important;
}

.border-dark {
  --bs-border-color: #414949 !important;
}

.border-white {
  --bs-border-color: white !important;
}

.border-black {
  --bs-border-color: #231f20 !important;
}

.border-socgen {
  --bs-border-color: #e60028 !important;
}

.border-primary-alt {
  --bs-border-color: #fff !important;
}

.border-apricot {
  --bs-border-color: #e88e5e !important;
}

.border-azure {
  --bs-border-color: #38699f !important;
}

.border-buttercup {
  --bs-border-color: #f6a416 !important;
}

.border-copper-rust {
  --bs-border-color: #974a5a !important;
}

.border-cornflower-blue {
  --bs-border-color: #6488ff !important;
}

.border-dodger-blue {
  --bs-border-color: #5d55ff !important;
}

.border-east-bay {
  --bs-border-color: #4a5885 !important;
}

.border-faded-jade {
  --bs-border-color: #408182 !important;
}

.border-heather {
  --bs-border-color: #b4bbcb !important;
}

.border-mako {
  --bs-border-color: #444852 !important;
}

.border-manatee {
  --bs-border-color: #8b93a8 !important;
}

.border-mars {
  --bs-border-color: #d53229 !important;
}

.border-mercury {
  --bs-border-color: #e8e8e8 !important;
}

.border-pacific-blue {
  --bs-border-color: #00b4bb !important;
}

.border-plantation {
  --bs-border-color: #275855 !important;
}

.border-puerto-rico {
  --bs-border-color: #41c49d !important;
}

.border-royal-blue {
  --bs-border-color: #5c56e8 !important;
}

.border-spicy-mix {
  --bs-border-color: #854b4b !important;
}

.border-turquoise-blue {
  --bs-border-color: #68efad !important;
}

.border-valencia {
  --bs-border-color: #dc4941 !important;
}

.border-victoria {
  --bs-border-color: #514b90 !important;
}

.border.border-opacity-100 {
  --bs-border-color: #c4c8ca !important;
}

.border-opacity-100 {
  --bs-border-opacity: 1 !important;
}

.border.border-opacity-75 {
  --bs-border-color: rgba(196, 200, 202, 0.75) !important;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75 !important;
}

.border.border-opacity-70 {
  --bs-border-color: rgba(196, 200, 202, 0.7) !important;
}

.border-opacity-70 {
  --bs-border-opacity: 0.7 !important;
}

.border.border-opacity-50 {
  --bs-border-color: rgba(196, 200, 202, 0.5) !important;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5 !important;
}

.border.border-opacity-40 {
  --bs-border-color: rgba(196, 200, 202, 0.4) !important;
}

.border-opacity-40 {
  --bs-border-opacity: 0.4 !important;
}

.border.border-opacity-25 {
  --bs-border-color: rgba(196, 200, 202, 0.25) !important;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25 !important;
}

.border.border-opacity-20 {
  --bs-border-color: rgba(196, 200, 202, 0.2) !important;
}

.border-opacity-20 {
  --bs-border-opacity: 0.2 !important;
}

.border.border-opacity-10 {
  --bs-border-color: rgba(196, 200, 202, 0.1) !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1 !important;
}

.border.border-opacity-05 {
  --bs-border-color: rgba(196, 200, 202, 0.05) !important;
}

.border-opacity-05 {
  --bs-border-opacity: 0.05 !important;
}

.flex-basis-100 {
  flex-basis: 100% !important;
}

@media (min-width: 480px) {
  .flex-basis-sm-100 {
    flex-basis: 100% !important;
  }
}
@media (min-width: 768px) {
  .flex-basis-md-100 {
    flex-basis: 100% !important;
  }
}
@media (min-width: 1024px) {
  .flex-basis-lg-100 {
    flex-basis: 100% !important;
  }
}
@media (min-width: 1440px) {
  .flex-basis-xl-100 {
    flex-basis: 100% !important;
  }
}
@media (min-width: 1920px) {
  .flex-basis-xxl-100 {
    flex-basis: 100% !important;
  }
}
.flex-center, .flex-center-x {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-center-y {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.flex-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.flex-between.flex-column {
  justify-content: center !important;
  align-items: space-between !important;
}

.flex-x {
  display: flex !important;
  align-items: center !important;
}

.flex-y {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
}

.flex-between-x {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.flex-between-y {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: column !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-21 {
  font-size: 1.3125rem !important;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.fs-26 {
  font-size: 1.625rem !important;
}

.fs-30 {
  font-size: 1.875rem !important;
}

.fs-36 {
  font-size: 2.25rem !important;
}

.fs-42 {
  font-size: 2.625rem !important;
}

.fs-68 {
  font-size: 4.25rem !important;
}

.fs-112 {
  font-size: 7rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-y-0 {
  row-gap: 0 !important;
}

.gap-x-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-y-1 {
  row-gap: 0.25rem !important;
}

.gap-x-1 {
  -moz-column-gap: 0.25rem !important;
  column-gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-y-2 {
  row-gap: 0.5rem !important;
}

.gap-x-2 {
  -moz-column-gap: 0.5rem !important;
  column-gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-y-3 {
  row-gap: 1rem !important;
}

.gap-x-3 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-y-4 {
  row-gap: 1.5rem !important;
}

.gap-x-4 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-y-5 {
  row-gap: 3rem !important;
}

.gap-x-5 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}

.gap-4px {
  gap: 0.25rem !important;
}

.gap-y-4px {
  row-gap: 0.25rem !important;
}

.gap-x-4px {
  -moz-column-gap: 0.25rem !important;
  column-gap: 0.25rem !important;
}

.gap-8px {
  gap: 0.5rem !important;
}

.gap-y-8px {
  row-gap: 0.5rem !important;
}

.gap-x-8px {
  -moz-column-gap: 0.5rem !important;
  column-gap: 0.5rem !important;
}

.gap-12px {
  gap: 0.75rem !important;
}

.gap-y-12px {
  row-gap: 0.75rem !important;
}

.gap-x-12px {
  -moz-column-gap: 0.75rem !important;
  column-gap: 0.75rem !important;
}

.gap-16px {
  gap: 1rem !important;
}

.gap-y-16px {
  row-gap: 1rem !important;
}

.gap-x-16px {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.gap-24px {
  gap: 1.5rem !important;
}

.gap-y-24px {
  row-gap: 1.5rem !important;
}

.gap-x-24px {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.gap-32px {
  gap: 2rem !important;
}

.gap-y-32px {
  row-gap: 2rem !important;
}

.gap-x-32px {
  -moz-column-gap: 2rem !important;
  column-gap: 2rem !important;
}

.gap-48px {
  gap: 3rem !important;
}

.gap-y-48px {
  row-gap: 3rem !important;
}

.gap-x-48px {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}

.gap-64px {
  gap: 4rem !important;
}

.gap-y-64px {
  row-gap: 4rem !important;
}

.gap-x-64px {
  -moz-column-gap: 4rem !important;
  column-gap: 4rem !important;
}

.gap-96px {
  gap: 6rem !important;
}

.gap-y-96px {
  row-gap: 6rem !important;
}

.gap-x-96px {
  -moz-column-gap: 6rem !important;
  column-gap: 6rem !important;
}

.gap-128px {
  gap: 8rem !important;
}

.gap-y-128px {
  row-gap: 8rem !important;
}

.gap-x-128px {
  -moz-column-gap: 8rem !important;
  column-gap: 8rem !important;
}

@media (min-width: 480px) {
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-y-sm-0 {
    row-gap: 0 !important;
  }
  .gap-x-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-y-sm-1 {
    row-gap: 0.25rem !important;
  }
  .gap-x-sm-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-y-sm-2 {
    row-gap: 0.5rem !important;
  }
  .gap-x-sm-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-y-sm-3 {
    row-gap: 1rem !important;
  }
  .gap-x-sm-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-y-sm-4 {
    row-gap: 1.5rem !important;
  }
  .gap-x-sm-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-y-sm-5 {
    row-gap: 3rem !important;
  }
  .gap-x-sm-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-sm-4px {
    gap: 0.25rem !important;
  }
  .gap-y-sm-4px {
    row-gap: 0.25rem !important;
  }
  .gap-x-sm-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-sm-8px {
    gap: 0.5rem !important;
  }
  .gap-y-sm-8px {
    row-gap: 0.5rem !important;
  }
  .gap-x-sm-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-sm-12px {
    gap: 0.75rem !important;
  }
  .gap-y-sm-12px {
    row-gap: 0.75rem !important;
  }
  .gap-x-sm-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .gap-sm-16px {
    gap: 1rem !important;
  }
  .gap-y-sm-16px {
    row-gap: 1rem !important;
  }
  .gap-x-sm-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-sm-24px {
    gap: 1.5rem !important;
  }
  .gap-y-sm-24px {
    row-gap: 1.5rem !important;
  }
  .gap-x-sm-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-sm-32px {
    gap: 2rem !important;
  }
  .gap-y-sm-32px {
    row-gap: 2rem !important;
  }
  .gap-x-sm-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .gap-sm-48px {
    gap: 3rem !important;
  }
  .gap-y-sm-48px {
    row-gap: 3rem !important;
  }
  .gap-x-sm-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-sm-64px {
    gap: 4rem !important;
  }
  .gap-y-sm-64px {
    row-gap: 4rem !important;
  }
  .gap-x-sm-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .gap-sm-96px {
    gap: 6rem !important;
  }
  .gap-y-sm-96px {
    row-gap: 6rem !important;
  }
  .gap-x-sm-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .gap-sm-128px {
    gap: 8rem !important;
  }
  .gap-y-sm-128px {
    row-gap: 8rem !important;
  }
  .gap-x-sm-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-y-md-0 {
    row-gap: 0 !important;
  }
  .gap-x-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-y-md-1 {
    row-gap: 0.25rem !important;
  }
  .gap-x-md-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-y-md-2 {
    row-gap: 0.5rem !important;
  }
  .gap-x-md-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-y-md-3 {
    row-gap: 1rem !important;
  }
  .gap-x-md-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-y-md-4 {
    row-gap: 1.5rem !important;
  }
  .gap-x-md-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-y-md-5 {
    row-gap: 3rem !important;
  }
  .gap-x-md-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-md-4px {
    gap: 0.25rem !important;
  }
  .gap-y-md-4px {
    row-gap: 0.25rem !important;
  }
  .gap-x-md-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-md-8px {
    gap: 0.5rem !important;
  }
  .gap-y-md-8px {
    row-gap: 0.5rem !important;
  }
  .gap-x-md-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-md-12px {
    gap: 0.75rem !important;
  }
  .gap-y-md-12px {
    row-gap: 0.75rem !important;
  }
  .gap-x-md-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .gap-md-16px {
    gap: 1rem !important;
  }
  .gap-y-md-16px {
    row-gap: 1rem !important;
  }
  .gap-x-md-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-md-24px {
    gap: 1.5rem !important;
  }
  .gap-y-md-24px {
    row-gap: 1.5rem !important;
  }
  .gap-x-md-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-md-32px {
    gap: 2rem !important;
  }
  .gap-y-md-32px {
    row-gap: 2rem !important;
  }
  .gap-x-md-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .gap-md-48px {
    gap: 3rem !important;
  }
  .gap-y-md-48px {
    row-gap: 3rem !important;
  }
  .gap-x-md-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-md-64px {
    gap: 4rem !important;
  }
  .gap-y-md-64px {
    row-gap: 4rem !important;
  }
  .gap-x-md-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .gap-md-96px {
    gap: 6rem !important;
  }
  .gap-y-md-96px {
    row-gap: 6rem !important;
  }
  .gap-x-md-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .gap-md-128px {
    gap: 8rem !important;
  }
  .gap-y-md-128px {
    row-gap: 8rem !important;
  }
  .gap-x-md-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
}
@media (min-width: 1024px) {
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-y-lg-0 {
    row-gap: 0 !important;
  }
  .gap-x-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-y-lg-1 {
    row-gap: 0.25rem !important;
  }
  .gap-x-lg-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-y-lg-2 {
    row-gap: 0.5rem !important;
  }
  .gap-x-lg-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-y-lg-3 {
    row-gap: 1rem !important;
  }
  .gap-x-lg-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-y-lg-4 {
    row-gap: 1.5rem !important;
  }
  .gap-x-lg-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-y-lg-5 {
    row-gap: 3rem !important;
  }
  .gap-x-lg-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-lg-4px {
    gap: 0.25rem !important;
  }
  .gap-y-lg-4px {
    row-gap: 0.25rem !important;
  }
  .gap-x-lg-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-lg-8px {
    gap: 0.5rem !important;
  }
  .gap-y-lg-8px {
    row-gap: 0.5rem !important;
  }
  .gap-x-lg-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-lg-12px {
    gap: 0.75rem !important;
  }
  .gap-y-lg-12px {
    row-gap: 0.75rem !important;
  }
  .gap-x-lg-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .gap-lg-16px {
    gap: 1rem !important;
  }
  .gap-y-lg-16px {
    row-gap: 1rem !important;
  }
  .gap-x-lg-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-lg-24px {
    gap: 1.5rem !important;
  }
  .gap-y-lg-24px {
    row-gap: 1.5rem !important;
  }
  .gap-x-lg-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-lg-32px {
    gap: 2rem !important;
  }
  .gap-y-lg-32px {
    row-gap: 2rem !important;
  }
  .gap-x-lg-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .gap-lg-48px {
    gap: 3rem !important;
  }
  .gap-y-lg-48px {
    row-gap: 3rem !important;
  }
  .gap-x-lg-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-lg-64px {
    gap: 4rem !important;
  }
  .gap-y-lg-64px {
    row-gap: 4rem !important;
  }
  .gap-x-lg-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .gap-lg-96px {
    gap: 6rem !important;
  }
  .gap-y-lg-96px {
    row-gap: 6rem !important;
  }
  .gap-x-lg-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .gap-lg-128px {
    gap: 8rem !important;
  }
  .gap-y-lg-128px {
    row-gap: 8rem !important;
  }
  .gap-x-lg-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
}
@media (min-width: 1440px) {
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-y-xl-0 {
    row-gap: 0 !important;
  }
  .gap-x-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-y-xl-1 {
    row-gap: 0.25rem !important;
  }
  .gap-x-xl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-y-xl-2 {
    row-gap: 0.5rem !important;
  }
  .gap-x-xl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-y-xl-3 {
    row-gap: 1rem !important;
  }
  .gap-x-xl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-y-xl-4 {
    row-gap: 1.5rem !important;
  }
  .gap-x-xl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-y-xl-5 {
    row-gap: 3rem !important;
  }
  .gap-x-xl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-xl-4px {
    gap: 0.25rem !important;
  }
  .gap-y-xl-4px {
    row-gap: 0.25rem !important;
  }
  .gap-x-xl-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-xl-8px {
    gap: 0.5rem !important;
  }
  .gap-y-xl-8px {
    row-gap: 0.5rem !important;
  }
  .gap-x-xl-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-xl-12px {
    gap: 0.75rem !important;
  }
  .gap-y-xl-12px {
    row-gap: 0.75rem !important;
  }
  .gap-x-xl-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .gap-xl-16px {
    gap: 1rem !important;
  }
  .gap-y-xl-16px {
    row-gap: 1rem !important;
  }
  .gap-x-xl-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-xl-24px {
    gap: 1.5rem !important;
  }
  .gap-y-xl-24px {
    row-gap: 1.5rem !important;
  }
  .gap-x-xl-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-xl-32px {
    gap: 2rem !important;
  }
  .gap-y-xl-32px {
    row-gap: 2rem !important;
  }
  .gap-x-xl-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .gap-xl-48px {
    gap: 3rem !important;
  }
  .gap-y-xl-48px {
    row-gap: 3rem !important;
  }
  .gap-x-xl-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-xl-64px {
    gap: 4rem !important;
  }
  .gap-y-xl-64px {
    row-gap: 4rem !important;
  }
  .gap-x-xl-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .gap-xl-96px {
    gap: 6rem !important;
  }
  .gap-y-xl-96px {
    row-gap: 6rem !important;
  }
  .gap-x-xl-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .gap-xl-128px {
    gap: 8rem !important;
  }
  .gap-y-xl-128px {
    row-gap: 8rem !important;
  }
  .gap-x-xl-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-y-xxl-0 {
    row-gap: 0 !important;
  }
  .gap-x-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-y-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .gap-x-xxl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-y-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .gap-x-xxl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-y-xxl-3 {
    row-gap: 1rem !important;
  }
  .gap-x-xxl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-y-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .gap-x-xxl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-y-xxl-5 {
    row-gap: 3rem !important;
  }
  .gap-x-xxl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-xxl-4px {
    gap: 0.25rem !important;
  }
  .gap-y-xxl-4px {
    row-gap: 0.25rem !important;
  }
  .gap-x-xxl-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .gap-xxl-8px {
    gap: 0.5rem !important;
  }
  .gap-y-xxl-8px {
    row-gap: 0.5rem !important;
  }
  .gap-x-xxl-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .gap-xxl-12px {
    gap: 0.75rem !important;
  }
  .gap-y-xxl-12px {
    row-gap: 0.75rem !important;
  }
  .gap-x-xxl-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .gap-xxl-16px {
    gap: 1rem !important;
  }
  .gap-y-xxl-16px {
    row-gap: 1rem !important;
  }
  .gap-x-xxl-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .gap-xxl-24px {
    gap: 1.5rem !important;
  }
  .gap-y-xxl-24px {
    row-gap: 1.5rem !important;
  }
  .gap-x-xxl-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .gap-xxl-32px {
    gap: 2rem !important;
  }
  .gap-y-xxl-32px {
    row-gap: 2rem !important;
  }
  .gap-x-xxl-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .gap-xxl-48px {
    gap: 3rem !important;
  }
  .gap-y-xxl-48px {
    row-gap: 3rem !important;
  }
  .gap-x-xxl-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .gap-xxl-64px {
    gap: 4rem !important;
  }
  .gap-y-xxl-64px {
    row-gap: 4rem !important;
  }
  .gap-x-xxl-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .gap-xxl-96px {
    gap: 6rem !important;
  }
  .gap-y-xxl-96px {
    row-gap: 6rem !important;
  }
  .gap-x-xxl-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .gap-xxl-128px {
    gap: 8rem !important;
  }
  .gap-y-xxl-128px {
    row-gap: 8rem !important;
  }
  .gap-x-xxl-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
}
.opacity-100 {
  opacity: 1 !important;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1 !important;
}

.text-opacity-100 {
  --bs-text-opacity: 1 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75 !important;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.bg-opacity-70 {
  --bs-bg-opacity: 0.7 !important;
}

.text-opacity-70 {
  --bs-text-opacity: 0.7 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5 !important;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.bg-opacity-40 {
  --bs-bg-opacity: 0.4 !important;
}

.text-opacity-40 {
  --bs-text-opacity: 0.4 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25 !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.bg-opacity-20 {
  --bs-bg-opacity: 0.2 !important;
}

.text-opacity-20 {
  --bs-text-opacity: 0.2 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1 !important;
}

.text-opacity-10 {
  --bs-text-opacity: 0.1 !important;
}

.opacity-05 {
  opacity: 0.05 !important;
}

.bg-opacity-05 {
  --bs-bg-opacity: 0.05 !important;
}

.text-opacity-05 {
  --bs-text-opacity: 0.05 !important;
}

.bg-lvl1.bg-opacity-100 {
  background: white !important;
}

.bg-lvl2.bg-opacity-100 {
  background: #f5f6f7 !important;
}

.bg-lvl3.bg-opacity-100 {
  background: #eceeef !important;
}

.bg-lvl4.bg-opacity-100 {
  background: #e3e6e7 !important;
}

.bg-lvl1.bg-opacity-75 {
  background: rgba(255, 255, 255, 0.75) !important;
}

.bg-lvl2.bg-opacity-75 {
  background: rgba(245, 246, 247, 0.75) !important;
}

.bg-lvl3.bg-opacity-75 {
  background: rgba(236, 238, 239, 0.75) !important;
}

.bg-lvl4.bg-opacity-75 {
  background: rgba(227, 230, 231, 0.75) !important;
}

.bg-lvl1.bg-opacity-70 {
  background: rgba(255, 255, 255, 0.7) !important;
}

.bg-lvl2.bg-opacity-70 {
  background: rgba(245, 246, 247, 0.7) !important;
}

.bg-lvl3.bg-opacity-70 {
  background: rgba(236, 238, 239, 0.7) !important;
}

.bg-lvl4.bg-opacity-70 {
  background: rgba(227, 230, 231, 0.7) !important;
}

.bg-lvl1.bg-opacity-50 {
  background: rgba(255, 255, 255, 0.5) !important;
}

.bg-lvl2.bg-opacity-50 {
  background: rgba(245, 246, 247, 0.5) !important;
}

.bg-lvl3.bg-opacity-50 {
  background: rgba(236, 238, 239, 0.5) !important;
}

.bg-lvl4.bg-opacity-50 {
  background: rgba(227, 230, 231, 0.5) !important;
}

.bg-lvl1.bg-opacity-40 {
  background: rgba(255, 255, 255, 0.4) !important;
}

.bg-lvl2.bg-opacity-40 {
  background: rgba(245, 246, 247, 0.4) !important;
}

.bg-lvl3.bg-opacity-40 {
  background: rgba(236, 238, 239, 0.4) !important;
}

.bg-lvl4.bg-opacity-40 {
  background: rgba(227, 230, 231, 0.4) !important;
}

.bg-lvl1.bg-opacity-25 {
  background: rgba(255, 255, 255, 0.25) !important;
}

.bg-lvl2.bg-opacity-25 {
  background: rgba(245, 246, 247, 0.25) !important;
}

.bg-lvl3.bg-opacity-25 {
  background: rgba(236, 238, 239, 0.25) !important;
}

.bg-lvl4.bg-opacity-25 {
  background: rgba(227, 230, 231, 0.25) !important;
}

.bg-lvl1.bg-opacity-20 {
  background: rgba(255, 255, 255, 0.2) !important;
}

.bg-lvl2.bg-opacity-20 {
  background: rgba(245, 246, 247, 0.2) !important;
}

.bg-lvl3.bg-opacity-20 {
  background: rgba(236, 238, 239, 0.2) !important;
}

.bg-lvl4.bg-opacity-20 {
  background: rgba(227, 230, 231, 0.2) !important;
}

.bg-lvl1.bg-opacity-10 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.bg-lvl2.bg-opacity-10 {
  background: rgba(245, 246, 247, 0.1) !important;
}

.bg-lvl3.bg-opacity-10 {
  background: rgba(236, 238, 239, 0.1) !important;
}

.bg-lvl4.bg-opacity-10 {
  background: rgba(227, 230, 231, 0.1) !important;
}

.bg-lvl1.bg-opacity-05 {
  background: rgba(255, 255, 255, 0.05) !important;
}

.bg-lvl2.bg-opacity-05 {
  background: rgba(245, 246, 247, 0.05) !important;
}

.bg-lvl3.bg-opacity-05 {
  background: rgba(236, 238, 239, 0.05) !important;
}

.bg-lvl4.bg-opacity-05 {
  background: rgba(227, 230, 231, 0.05) !important;
}

.bg-alt-lvl1.bg-opacity-100 {
  background: #303333 !important;
}

.bg-alt-lvl2.bg-opacity-100 {
  background: #222424 !important;
}

.bg-alt-lvl3.bg-opacity-100 {
  background: #151616 !important;
}

.bg-alt-lvl4.bg-opacity-100 {
  background: black !important;
}

.bg-alt-lvl1.bg-opacity-75 {
  background: rgba(48, 51, 51, 0.75) !important;
}

.bg-alt-lvl2.bg-opacity-75 {
  background: rgba(34, 36, 36, 0.75) !important;
}

.bg-alt-lvl3.bg-opacity-75 {
  background: rgba(21, 22, 22, 0.75) !important;
}

.bg-alt-lvl4.bg-opacity-75 {
  background: rgba(0, 0, 0, 0.75) !important;
}

.bg-alt-lvl1.bg-opacity-70 {
  background: rgba(48, 51, 51, 0.7) !important;
}

.bg-alt-lvl2.bg-opacity-70 {
  background: rgba(34, 36, 36, 0.7) !important;
}

.bg-alt-lvl3.bg-opacity-70 {
  background: rgba(21, 22, 22, 0.7) !important;
}

.bg-alt-lvl4.bg-opacity-70 {
  background: rgba(0, 0, 0, 0.7) !important;
}

.bg-alt-lvl1.bg-opacity-50 {
  background: rgba(48, 51, 51, 0.5) !important;
}

.bg-alt-lvl2.bg-opacity-50 {
  background: rgba(34, 36, 36, 0.5) !important;
}

.bg-alt-lvl3.bg-opacity-50 {
  background: rgba(21, 22, 22, 0.5) !important;
}

.bg-alt-lvl4.bg-opacity-50 {
  background: rgba(0, 0, 0, 0.5) !important;
}

.bg-alt-lvl1.bg-opacity-40 {
  background: rgba(48, 51, 51, 0.4) !important;
}

.bg-alt-lvl2.bg-opacity-40 {
  background: rgba(34, 36, 36, 0.4) !important;
}

.bg-alt-lvl3.bg-opacity-40 {
  background: rgba(21, 22, 22, 0.4) !important;
}

.bg-alt-lvl4.bg-opacity-40 {
  background: rgba(0, 0, 0, 0.4) !important;
}

.bg-alt-lvl1.bg-opacity-25 {
  background: rgba(48, 51, 51, 0.25) !important;
}

.bg-alt-lvl2.bg-opacity-25 {
  background: rgba(34, 36, 36, 0.25) !important;
}

.bg-alt-lvl3.bg-opacity-25 {
  background: rgba(21, 22, 22, 0.25) !important;
}

.bg-alt-lvl4.bg-opacity-25 {
  background: rgba(0, 0, 0, 0.25) !important;
}

.bg-alt-lvl1.bg-opacity-20 {
  background: rgba(48, 51, 51, 0.2) !important;
}

.bg-alt-lvl2.bg-opacity-20 {
  background: rgba(34, 36, 36, 0.2) !important;
}

.bg-alt-lvl3.bg-opacity-20 {
  background: rgba(21, 22, 22, 0.2) !important;
}

.bg-alt-lvl4.bg-opacity-20 {
  background: rgba(0, 0, 0, 0.2) !important;
}

.bg-alt-lvl1.bg-opacity-10 {
  background: rgba(48, 51, 51, 0.1) !important;
}

.bg-alt-lvl2.bg-opacity-10 {
  background: rgba(34, 36, 36, 0.1) !important;
}

.bg-alt-lvl3.bg-opacity-10 {
  background: rgba(21, 22, 22, 0.1) !important;
}

.bg-alt-lvl4.bg-opacity-10 {
  background: rgba(0, 0, 0, 0.1) !important;
}

.bg-alt-lvl1.bg-opacity-05 {
  background: rgba(48, 51, 51, 0.05) !important;
}

.bg-alt-lvl2.bg-opacity-05 {
  background: rgba(34, 36, 36, 0.05) !important;
}

.bg-alt-lvl3.bg-opacity-05 {
  background: rgba(21, 22, 22, 0.05) !important;
}

.bg-alt-lvl4.bg-opacity-05 {
  background: rgba(0, 0, 0, 0.05) !important;
}

.bg-apricot.bg-opacity-100 {
  background: #e88e5e !important;
}

.bg-azure.bg-opacity-100 {
  background: #38699f !important;
}

.bg-buttercup.bg-opacity-100 {
  background: #f6a416 !important;
}

.bg-copper-rust.bg-opacity-100 {
  background: #974a5a !important;
}

.bg-cornflower-blue.bg-opacity-100 {
  background: #6488ff !important;
}

.bg-dodger-blue.bg-opacity-100 {
  background: #5d55ff !important;
}

.bg-east-bay.bg-opacity-100 {
  background: #4a5885 !important;
}

.bg-faded-jade.bg-opacity-100 {
  background: #408182 !important;
}

.bg-heather.bg-opacity-100 {
  background: #b4bbcb !important;
}

.bg-mako.bg-opacity-100 {
  background: #444852 !important;
}

.bg-manatee.bg-opacity-100 {
  background: #8b93a8 !important;
}

.bg-mars.bg-opacity-100 {
  background: #d53229 !important;
}

.bg-mercury.bg-opacity-100 {
  background: #e8e8e8 !important;
}

.bg-pacific-blue.bg-opacity-100 {
  background: #00b4bb !important;
}

.bg-plantation.bg-opacity-100 {
  background: #275855 !important;
}

.bg-puerto-rico.bg-opacity-100 {
  background: #41c49d !important;
}

.bg-royal-blue.bg-opacity-100 {
  background: #5c56e8 !important;
}

.bg-spicy-mix.bg-opacity-100 {
  background: #854b4b !important;
}

.bg-turquoise-blue.bg-opacity-100 {
  background: #68efad !important;
}

.bg-valencia.bg-opacity-100 {
  background: #dc4941 !important;
}

.bg-victoria.bg-opacity-100 {
  background: #514b90 !important;
}

.bg-apricot.bg-opacity-75 {
  background: rgba(232, 142, 94, 0.75) !important;
}

.bg-azure.bg-opacity-75 {
  background: rgba(56, 105, 159, 0.75) !important;
}

.bg-buttercup.bg-opacity-75 {
  background: rgba(246, 164, 22, 0.75) !important;
}

.bg-copper-rust.bg-opacity-75 {
  background: rgba(151, 74, 90, 0.75) !important;
}

.bg-cornflower-blue.bg-opacity-75 {
  background: rgba(100, 136, 255, 0.75) !important;
}

.bg-dodger-blue.bg-opacity-75 {
  background: rgba(93, 85, 255, 0.75) !important;
}

.bg-east-bay.bg-opacity-75 {
  background: rgba(74, 88, 133, 0.75) !important;
}

.bg-faded-jade.bg-opacity-75 {
  background: rgba(64, 129, 130, 0.75) !important;
}

.bg-heather.bg-opacity-75 {
  background: rgba(180, 187, 203, 0.75) !important;
}

.bg-mako.bg-opacity-75 {
  background: rgba(68, 72, 82, 0.75) !important;
}

.bg-manatee.bg-opacity-75 {
  background: rgba(139, 147, 168, 0.75) !important;
}

.bg-mars.bg-opacity-75 {
  background: rgba(213, 50, 41, 0.75) !important;
}

.bg-mercury.bg-opacity-75 {
  background: rgba(232, 232, 232, 0.75) !important;
}

.bg-pacific-blue.bg-opacity-75 {
  background: rgba(0, 180, 187, 0.75) !important;
}

.bg-plantation.bg-opacity-75 {
  background: rgba(39, 88, 85, 0.75) !important;
}

.bg-puerto-rico.bg-opacity-75 {
  background: rgba(65, 196, 157, 0.75) !important;
}

.bg-royal-blue.bg-opacity-75 {
  background: rgba(92, 86, 232, 0.75) !important;
}

.bg-spicy-mix.bg-opacity-75 {
  background: rgba(133, 75, 75, 0.75) !important;
}

.bg-turquoise-blue.bg-opacity-75 {
  background: rgba(104, 239, 173, 0.75) !important;
}

.bg-valencia.bg-opacity-75 {
  background: rgba(220, 73, 65, 0.75) !important;
}

.bg-victoria.bg-opacity-75 {
  background: rgba(81, 75, 144, 0.75) !important;
}

.bg-apricot.bg-opacity-70 {
  background: rgba(232, 142, 94, 0.7) !important;
}

.bg-azure.bg-opacity-70 {
  background: rgba(56, 105, 159, 0.7) !important;
}

.bg-buttercup.bg-opacity-70 {
  background: rgba(246, 164, 22, 0.7) !important;
}

.bg-copper-rust.bg-opacity-70 {
  background: rgba(151, 74, 90, 0.7) !important;
}

.bg-cornflower-blue.bg-opacity-70 {
  background: rgba(100, 136, 255, 0.7) !important;
}

.bg-dodger-blue.bg-opacity-70 {
  background: rgba(93, 85, 255, 0.7) !important;
}

.bg-east-bay.bg-opacity-70 {
  background: rgba(74, 88, 133, 0.7) !important;
}

.bg-faded-jade.bg-opacity-70 {
  background: rgba(64, 129, 130, 0.7) !important;
}

.bg-heather.bg-opacity-70 {
  background: rgba(180, 187, 203, 0.7) !important;
}

.bg-mako.bg-opacity-70 {
  background: rgba(68, 72, 82, 0.7) !important;
}

.bg-manatee.bg-opacity-70 {
  background: rgba(139, 147, 168, 0.7) !important;
}

.bg-mars.bg-opacity-70 {
  background: rgba(213, 50, 41, 0.7) !important;
}

.bg-mercury.bg-opacity-70 {
  background: rgba(232, 232, 232, 0.7) !important;
}

.bg-pacific-blue.bg-opacity-70 {
  background: rgba(0, 180, 187, 0.7) !important;
}

.bg-plantation.bg-opacity-70 {
  background: rgba(39, 88, 85, 0.7) !important;
}

.bg-puerto-rico.bg-opacity-70 {
  background: rgba(65, 196, 157, 0.7) !important;
}

.bg-royal-blue.bg-opacity-70 {
  background: rgba(92, 86, 232, 0.7) !important;
}

.bg-spicy-mix.bg-opacity-70 {
  background: rgba(133, 75, 75, 0.7) !important;
}

.bg-turquoise-blue.bg-opacity-70 {
  background: rgba(104, 239, 173, 0.7) !important;
}

.bg-valencia.bg-opacity-70 {
  background: rgba(220, 73, 65, 0.7) !important;
}

.bg-victoria.bg-opacity-70 {
  background: rgba(81, 75, 144, 0.7) !important;
}

.bg-apricot.bg-opacity-50 {
  background: rgba(232, 142, 94, 0.5) !important;
}

.bg-azure.bg-opacity-50 {
  background: rgba(56, 105, 159, 0.5) !important;
}

.bg-buttercup.bg-opacity-50 {
  background: rgba(246, 164, 22, 0.5) !important;
}

.bg-copper-rust.bg-opacity-50 {
  background: rgba(151, 74, 90, 0.5) !important;
}

.bg-cornflower-blue.bg-opacity-50 {
  background: rgba(100, 136, 255, 0.5) !important;
}

.bg-dodger-blue.bg-opacity-50 {
  background: rgba(93, 85, 255, 0.5) !important;
}

.bg-east-bay.bg-opacity-50 {
  background: rgba(74, 88, 133, 0.5) !important;
}

.bg-faded-jade.bg-opacity-50 {
  background: rgba(64, 129, 130, 0.5) !important;
}

.bg-heather.bg-opacity-50 {
  background: rgba(180, 187, 203, 0.5) !important;
}

.bg-mako.bg-opacity-50 {
  background: rgba(68, 72, 82, 0.5) !important;
}

.bg-manatee.bg-opacity-50 {
  background: rgba(139, 147, 168, 0.5) !important;
}

.bg-mars.bg-opacity-50 {
  background: rgba(213, 50, 41, 0.5) !important;
}

.bg-mercury.bg-opacity-50 {
  background: rgba(232, 232, 232, 0.5) !important;
}

.bg-pacific-blue.bg-opacity-50 {
  background: rgba(0, 180, 187, 0.5) !important;
}

.bg-plantation.bg-opacity-50 {
  background: rgba(39, 88, 85, 0.5) !important;
}

.bg-puerto-rico.bg-opacity-50 {
  background: rgba(65, 196, 157, 0.5) !important;
}

.bg-royal-blue.bg-opacity-50 {
  background: rgba(92, 86, 232, 0.5) !important;
}

.bg-spicy-mix.bg-opacity-50 {
  background: rgba(133, 75, 75, 0.5) !important;
}

.bg-turquoise-blue.bg-opacity-50 {
  background: rgba(104, 239, 173, 0.5) !important;
}

.bg-valencia.bg-opacity-50 {
  background: rgba(220, 73, 65, 0.5) !important;
}

.bg-victoria.bg-opacity-50 {
  background: rgba(81, 75, 144, 0.5) !important;
}

.bg-apricot.bg-opacity-40 {
  background: rgba(232, 142, 94, 0.4) !important;
}

.bg-azure.bg-opacity-40 {
  background: rgba(56, 105, 159, 0.4) !important;
}

.bg-buttercup.bg-opacity-40 {
  background: rgba(246, 164, 22, 0.4) !important;
}

.bg-copper-rust.bg-opacity-40 {
  background: rgba(151, 74, 90, 0.4) !important;
}

.bg-cornflower-blue.bg-opacity-40 {
  background: rgba(100, 136, 255, 0.4) !important;
}

.bg-dodger-blue.bg-opacity-40 {
  background: rgba(93, 85, 255, 0.4) !important;
}

.bg-east-bay.bg-opacity-40 {
  background: rgba(74, 88, 133, 0.4) !important;
}

.bg-faded-jade.bg-opacity-40 {
  background: rgba(64, 129, 130, 0.4) !important;
}

.bg-heather.bg-opacity-40 {
  background: rgba(180, 187, 203, 0.4) !important;
}

.bg-mako.bg-opacity-40 {
  background: rgba(68, 72, 82, 0.4) !important;
}

.bg-manatee.bg-opacity-40 {
  background: rgba(139, 147, 168, 0.4) !important;
}

.bg-mars.bg-opacity-40 {
  background: rgba(213, 50, 41, 0.4) !important;
}

.bg-mercury.bg-opacity-40 {
  background: rgba(232, 232, 232, 0.4) !important;
}

.bg-pacific-blue.bg-opacity-40 {
  background: rgba(0, 180, 187, 0.4) !important;
}

.bg-plantation.bg-opacity-40 {
  background: rgba(39, 88, 85, 0.4) !important;
}

.bg-puerto-rico.bg-opacity-40 {
  background: rgba(65, 196, 157, 0.4) !important;
}

.bg-royal-blue.bg-opacity-40 {
  background: rgba(92, 86, 232, 0.4) !important;
}

.bg-spicy-mix.bg-opacity-40 {
  background: rgba(133, 75, 75, 0.4) !important;
}

.bg-turquoise-blue.bg-opacity-40 {
  background: rgba(104, 239, 173, 0.4) !important;
}

.bg-valencia.bg-opacity-40 {
  background: rgba(220, 73, 65, 0.4) !important;
}

.bg-victoria.bg-opacity-40 {
  background: rgba(81, 75, 144, 0.4) !important;
}

.bg-apricot.bg-opacity-25 {
  background: rgba(232, 142, 94, 0.25) !important;
}

.bg-azure.bg-opacity-25 {
  background: rgba(56, 105, 159, 0.25) !important;
}

.bg-buttercup.bg-opacity-25 {
  background: rgba(246, 164, 22, 0.25) !important;
}

.bg-copper-rust.bg-opacity-25 {
  background: rgba(151, 74, 90, 0.25) !important;
}

.bg-cornflower-blue.bg-opacity-25 {
  background: rgba(100, 136, 255, 0.25) !important;
}

.bg-dodger-blue.bg-opacity-25 {
  background: rgba(93, 85, 255, 0.25) !important;
}

.bg-east-bay.bg-opacity-25 {
  background: rgba(74, 88, 133, 0.25) !important;
}

.bg-faded-jade.bg-opacity-25 {
  background: rgba(64, 129, 130, 0.25) !important;
}

.bg-heather.bg-opacity-25 {
  background: rgba(180, 187, 203, 0.25) !important;
}

.bg-mako.bg-opacity-25 {
  background: rgba(68, 72, 82, 0.25) !important;
}

.bg-manatee.bg-opacity-25 {
  background: rgba(139, 147, 168, 0.25) !important;
}

.bg-mars.bg-opacity-25 {
  background: rgba(213, 50, 41, 0.25) !important;
}

.bg-mercury.bg-opacity-25 {
  background: rgba(232, 232, 232, 0.25) !important;
}

.bg-pacific-blue.bg-opacity-25 {
  background: rgba(0, 180, 187, 0.25) !important;
}

.bg-plantation.bg-opacity-25 {
  background: rgba(39, 88, 85, 0.25) !important;
}

.bg-puerto-rico.bg-opacity-25 {
  background: rgba(65, 196, 157, 0.25) !important;
}

.bg-royal-blue.bg-opacity-25 {
  background: rgba(92, 86, 232, 0.25) !important;
}

.bg-spicy-mix.bg-opacity-25 {
  background: rgba(133, 75, 75, 0.25) !important;
}

.bg-turquoise-blue.bg-opacity-25 {
  background: rgba(104, 239, 173, 0.25) !important;
}

.bg-valencia.bg-opacity-25 {
  background: rgba(220, 73, 65, 0.25) !important;
}

.bg-victoria.bg-opacity-25 {
  background: rgba(81, 75, 144, 0.25) !important;
}

.bg-apricot.bg-opacity-20 {
  background: rgba(232, 142, 94, 0.2) !important;
}

.bg-azure.bg-opacity-20 {
  background: rgba(56, 105, 159, 0.2) !important;
}

.bg-buttercup.bg-opacity-20 {
  background: rgba(246, 164, 22, 0.2) !important;
}

.bg-copper-rust.bg-opacity-20 {
  background: rgba(151, 74, 90, 0.2) !important;
}

.bg-cornflower-blue.bg-opacity-20 {
  background: rgba(100, 136, 255, 0.2) !important;
}

.bg-dodger-blue.bg-opacity-20 {
  background: rgba(93, 85, 255, 0.2) !important;
}

.bg-east-bay.bg-opacity-20 {
  background: rgba(74, 88, 133, 0.2) !important;
}

.bg-faded-jade.bg-opacity-20 {
  background: rgba(64, 129, 130, 0.2) !important;
}

.bg-heather.bg-opacity-20 {
  background: rgba(180, 187, 203, 0.2) !important;
}

.bg-mako.bg-opacity-20 {
  background: rgba(68, 72, 82, 0.2) !important;
}

.bg-manatee.bg-opacity-20 {
  background: rgba(139, 147, 168, 0.2) !important;
}

.bg-mars.bg-opacity-20 {
  background: rgba(213, 50, 41, 0.2) !important;
}

.bg-mercury.bg-opacity-20 {
  background: rgba(232, 232, 232, 0.2) !important;
}

.bg-pacific-blue.bg-opacity-20 {
  background: rgba(0, 180, 187, 0.2) !important;
}

.bg-plantation.bg-opacity-20 {
  background: rgba(39, 88, 85, 0.2) !important;
}

.bg-puerto-rico.bg-opacity-20 {
  background: rgba(65, 196, 157, 0.2) !important;
}

.bg-royal-blue.bg-opacity-20 {
  background: rgba(92, 86, 232, 0.2) !important;
}

.bg-spicy-mix.bg-opacity-20 {
  background: rgba(133, 75, 75, 0.2) !important;
}

.bg-turquoise-blue.bg-opacity-20 {
  background: rgba(104, 239, 173, 0.2) !important;
}

.bg-valencia.bg-opacity-20 {
  background: rgba(220, 73, 65, 0.2) !important;
}

.bg-victoria.bg-opacity-20 {
  background: rgba(81, 75, 144, 0.2) !important;
}

.bg-apricot.bg-opacity-10 {
  background: rgba(232, 142, 94, 0.1) !important;
}

.bg-azure.bg-opacity-10 {
  background: rgba(56, 105, 159, 0.1) !important;
}

.bg-buttercup.bg-opacity-10 {
  background: rgba(246, 164, 22, 0.1) !important;
}

.bg-copper-rust.bg-opacity-10 {
  background: rgba(151, 74, 90, 0.1) !important;
}

.bg-cornflower-blue.bg-opacity-10 {
  background: rgba(100, 136, 255, 0.1) !important;
}

.bg-dodger-blue.bg-opacity-10 {
  background: rgba(93, 85, 255, 0.1) !important;
}

.bg-east-bay.bg-opacity-10 {
  background: rgba(74, 88, 133, 0.1) !important;
}

.bg-faded-jade.bg-opacity-10 {
  background: rgba(64, 129, 130, 0.1) !important;
}

.bg-heather.bg-opacity-10 {
  background: rgba(180, 187, 203, 0.1) !important;
}

.bg-mako.bg-opacity-10 {
  background: rgba(68, 72, 82, 0.1) !important;
}

.bg-manatee.bg-opacity-10 {
  background: rgba(139, 147, 168, 0.1) !important;
}

.bg-mars.bg-opacity-10 {
  background: rgba(213, 50, 41, 0.1) !important;
}

.bg-mercury.bg-opacity-10 {
  background: rgba(232, 232, 232, 0.1) !important;
}

.bg-pacific-blue.bg-opacity-10 {
  background: rgba(0, 180, 187, 0.1) !important;
}

.bg-plantation.bg-opacity-10 {
  background: rgba(39, 88, 85, 0.1) !important;
}

.bg-puerto-rico.bg-opacity-10 {
  background: rgba(65, 196, 157, 0.1) !important;
}

.bg-royal-blue.bg-opacity-10 {
  background: rgba(92, 86, 232, 0.1) !important;
}

.bg-spicy-mix.bg-opacity-10 {
  background: rgba(133, 75, 75, 0.1) !important;
}

.bg-turquoise-blue.bg-opacity-10 {
  background: rgba(104, 239, 173, 0.1) !important;
}

.bg-valencia.bg-opacity-10 {
  background: rgba(220, 73, 65, 0.1) !important;
}

.bg-victoria.bg-opacity-10 {
  background: rgba(81, 75, 144, 0.1) !important;
}

.bg-apricot.bg-opacity-05 {
  background: rgba(232, 142, 94, 0.05) !important;
}

.bg-azure.bg-opacity-05 {
  background: rgba(56, 105, 159, 0.05) !important;
}

.bg-buttercup.bg-opacity-05 {
  background: rgba(246, 164, 22, 0.05) !important;
}

.bg-copper-rust.bg-opacity-05 {
  background: rgba(151, 74, 90, 0.05) !important;
}

.bg-cornflower-blue.bg-opacity-05 {
  background: rgba(100, 136, 255, 0.05) !important;
}

.bg-dodger-blue.bg-opacity-05 {
  background: rgba(93, 85, 255, 0.05) !important;
}

.bg-east-bay.bg-opacity-05 {
  background: rgba(74, 88, 133, 0.05) !important;
}

.bg-faded-jade.bg-opacity-05 {
  background: rgba(64, 129, 130, 0.05) !important;
}

.bg-heather.bg-opacity-05 {
  background: rgba(180, 187, 203, 0.05) !important;
}

.bg-mako.bg-opacity-05 {
  background: rgba(68, 72, 82, 0.05) !important;
}

.bg-manatee.bg-opacity-05 {
  background: rgba(139, 147, 168, 0.05) !important;
}

.bg-mars.bg-opacity-05 {
  background: rgba(213, 50, 41, 0.05) !important;
}

.bg-mercury.bg-opacity-05 {
  background: rgba(232, 232, 232, 0.05) !important;
}

.bg-pacific-blue.bg-opacity-05 {
  background: rgba(0, 180, 187, 0.05) !important;
}

.bg-plantation.bg-opacity-05 {
  background: rgba(39, 88, 85, 0.05) !important;
}

.bg-puerto-rico.bg-opacity-05 {
  background: rgba(65, 196, 157, 0.05) !important;
}

.bg-royal-blue.bg-opacity-05 {
  background: rgba(92, 86, 232, 0.05) !important;
}

.bg-spicy-mix.bg-opacity-05 {
  background: rgba(133, 75, 75, 0.05) !important;
}

.bg-turquoise-blue.bg-opacity-05 {
  background: rgba(104, 239, 173, 0.05) !important;
}

.bg-valencia.bg-opacity-05 {
  background: rgba(220, 73, 65, 0.05) !important;
}

.bg-victoria.bg-opacity-05 {
  background: rgba(81, 75, 144, 0.05) !important;
}

.border-radius {
  border-radius: 0.25rem;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius-2 {
  border-radius: 0.125rem !important;
}

.border-radius-4 {
  border-radius: 0.25rem !important;
}

.border-radius-8 {
  border-radius: 0.5rem !important;
}

.border-radius-12 {
  border-radius: 0.75rem !important;
}

.border-radius-16 {
  border-radius: 1rem !important;
}

.border-radius-24 {
  border-radius: 1.5rem !important;
}

.border-radius-pill {
  border-radius: 50rem !important;
}

.border-radius-circle {
  border-radius: 50% !important;
}

.border-radius-end {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.border-radius-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.border-radius-end-2 {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.border-radius-end-4 {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.border-radius-end-8 {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.border-radius-end-12 {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.border-radius-end-16 {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.border-radius-end-24 {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.border-radius-end-pill {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.border-radius-end-circle {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.border-radius-start {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.border-radius-start-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.border-radius-start-2 {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.border-radius-start-4 {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.border-radius-start-8 {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.border-radius-start-12 {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.border-radius-start-16 {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.border-radius-start-24 {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.border-radius-start-pill {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}

.border-radius-start-circle {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.border-radius-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.border-radius-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.border-radius-top-2 {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.border-radius-top-4 {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.border-radius-top-8 {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.border-radius-top-12 {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.border-radius-top-16 {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.border-radius-top-24 {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.border-radius-top-pill {
  border-top-left-radius: 50rem;
  border-top-right-radius: 50rem;
}

.border-radius-top-circle {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.border-radius-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.border-radius-bottom-0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.border-radius-bottom-2 {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.border-radius-bottom-4 {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.border-radius-bottom-8 {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.border-radius-bottom-12 {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.border-radius-bottom-16 {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.border-radius-bottom-24 {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.border-radius-bottom-pill {
  border-bottom-right-radius: 50rem;
  border-bottom-left-radius: 50rem;
}

.border-radius-bottom-circle {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.shadow-xl {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.07), 0 3px 14px 2px rgba(0, 0, 0, 0.06) !important;
}

.shadow-xxl {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.1), 0 16px 24px 2px rgba(0, 0, 0, 0.07), 0 6px 30px 5px rgba(0, 0, 0, 0.06) !important;
}

.shadow-max {
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.1), 0 22px 35px 3px rgba(0, 0, 0, 0.07), 0 8px 42px 7px rgba(0, 0, 0, 0.06) !important;
}

.text-figures {
  line-height: 1 !important;
  font-weight: 500 !important;
}

.monospace-numbers,
.tabular-figures {
  font-feature-settings: "cv01", "cv10", "tnum" !important;
  letter-spacing: -0.07em !important;
  line-height: 1 !important;
  font-weight: 500 !important;
}

.fw-extrabold {
  font-weight: 800;
}

.fw-medium {
  font-weight: 500;
}

.text-socgen {
  color: #e60028 !important;
}

.text-primary-alt {
  color: #fff !important;
}

.text-apricot {
  color: #e88e5e !important;
}

.text-azure {
  color: #38699f !important;
}

.text-buttercup {
  color: #f6a416 !important;
}

.text-copper-rust {
  color: #974a5a !important;
}

.text-cornflower-blue {
  color: #6488ff !important;
}

.text-dodger-blue {
  color: #5d55ff !important;
}

.text-east-bay {
  color: #4a5885 !important;
}

.text-faded-jade {
  color: #408182 !important;
}

.text-heather {
  color: #b4bbcb !important;
}

.text-mako {
  color: #444852 !important;
}

.text-manatee {
  color: #8b93a8 !important;
}

.text-mars {
  color: #d53229 !important;
}

.text-mercury {
  color: #e8e8e8 !important;
}

.text-pacific-blue {
  color: #00b4bb !important;
}

.text-plantation {
  color: #275855 !important;
}

.text-puerto-rico {
  color: #41c49d !important;
}

.text-royal-blue {
  color: #5c56e8 !important;
}

.text-spicy-mix {
  color: #854b4b !important;
}

.text-turquoise-blue {
  color: #68efad !important;
}

.text-valencia {
  color: #dc4941 !important;
}

.text-victoria {
  color: #514b90 !important;
}

.bg-lvl4 {
  --bs-secondary-rgb: 95, 102, 104;
  --bs-light-rgb: 177, 183, 185;
  --bs-dark-rgb: 50, 57, 57;
  --bs-info-rgb: 5, 84, 255;
  --bs-success-rgb: 0, 138, 9;
  --bs-warning-rgb: 188, 101, 0;
  --bs-danger-rgb: 194, 47, 31;
}

.bg-lvl3 {
  --bs-secondary-rgb: 102, 110, 112;
  --bs-light-rgb: 185, 190, 192;
  --bs-dark-rgb: 55, 62, 62;
  --bs-info-rgb: 15, 91, 255;
  --bs-success-rgb: 0, 148, 10;
  --bs-warning-rgb: 198, 106, 0;
  --bs-danger-rgb: 203, 49, 32;
}

.bg-lvl2 {
  --bs-secondary-rgb: 104, 112, 115;
  --bs-light-rgb: 193, 197, 199;
  --bs-dark-rgb: 60, 67, 67;
  --bs-info-rgb: 26, 98, 255;
  --bs-success-rgb: 0, 158, 11;
  --bs-warning-rgb: 208, 111, 0;
  --bs-danger-rgb: 212, 51, 33;
}

.bg-lvl1 {
  --bs-secondary-rgb: 110, 119, 122;
  --bs-light-rgb: 196, 200, 202;
  --bs-dark-rgb: 65, 73, 73;
  --bs-info-rgb: 36, 105, 255;
  --bs-success-rgb: 0, 170, 12;
  --bs-warning-rgb: 218, 116, 0;
  --bs-danger-rgb: 221, 55, 36;
}

.bg-alt-lvl4 {
  --bs-secondary-rgb: 112, 121, 122;
  --bs-light-rgb: 163, 175, 179;
  --bs-dark-rgb: 52, 55, 55;
  --bs-info-rgb: 20, 118, 255;
  --bs-success-rgb: 0, 168, 62;
  --bs-warning-rgb: 204, 116, 0;
  --bs-danger-rgb: 217, 61, 38;
}

.bg-alt-lvl3 {
  --bs-secondary-rgb: 122, 131, 133;
  --bs-light-rgb: 177, 187, 190;
  --bs-dark-rgb: 59, 63, 63;
  --bs-info-rgb: 41, 130, 255;
  --bs-success-rgb: 0, 184, 68;
  --bs-warning-rgb: 219, 124, 0;
  --bs-danger-rgb: 232, 68, 44;
}

.bg-alt-lvl2 {
  --bs-secondary-rgb: 132, 141, 143;
  --bs-light-rgb: 194, 202, 204;
  --bs-dark-rgb: 69, 74, 74;
  --bs-info-rgb: 61, 142, 255;
  --bs-success-rgb: 0, 199, 74;
  --bs-warning-rgb: 235, 133, 0;
  --bs-danger-rgb: 244, 77, 52;
}

.bg-alt-lvl1 {
  --bs-secondary-rgb: 148, 156, 158;
  --bs-light-rgb: 216, 221, 222;
  --bs-dark-rgb: 81, 87, 87;
  --bs-info-rgb: 82, 154, 255;
  --bs-success-rgb: 0, 215, 80;
  --bs-warning-rgb: 249, 141, 0;
  --bs-danger-rgb: 255, 86, 60;
}

.bg-lvl4 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-success-rgb: 0, 107, 7;
}
.bg-lvl4 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-warning-rgb: 147, 79, 0;
}
.bg-lvl4 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-danger-rgb: 194, 47, 31;
}

.bg-lvl3 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-success-rgb: 0, 117, 8;
}
.bg-lvl3 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-warning-rgb: 157, 84, 0;
}
.bg-lvl3 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-danger-rgb: 203, 49, 32;
}

.bg-lvl2 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-success-rgb: 0, 127, 9;
}
.bg-lvl2 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-warning-rgb: 167, 89, 0;
}
.bg-lvl2 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-danger-rgb: 212, 51, 33;
}

.bg-lvl1 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-success-rgb: 0, 139, 10;
}
.bg-lvl1 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-warning-rgb: 177, 94, 0;
}
.bg-lvl1 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-danger-rgb: 221, 55, 36;
}

.bg-alt-lvl4 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-danger-rgb: 219, 78, 51;
}
.bg-alt-lvl4 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-success-rgb: 0, 168, 62;
}
.bg-alt-lvl4 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-warning-rgb: 204, 116, 0;
}

.bg-alt-lvl3 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-danger-rgb: 234, 86, 58;
}
.bg-alt-lvl3 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-success-rgb: 0, 184, 68;
}
.bg-alt-lvl3 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-warning-rgb: 219, 124, 0;
}

.bg-alt-lvl2 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-danger-rgb: 245, 96, 67;
}
.bg-alt-lvl2 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-success-rgb: 0, 199, 74;
}
.bg-alt-lvl2 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-warning-rgb: 235, 133, 0;
}

.bg-alt-lvl1 .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-danger-rgb: 255, 105, 75;
}
.bg-alt-lvl1 .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-success-rgb: 0, 215, 80;
}
.bg-alt-lvl1 .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --bs-warning-rgb: 249, 141, 0;
}

.text-red-10 {
  color: var(--bs-red-10) !important;
}

.text-red-20 {
  color: var(--bs-red-20) !important;
}

.text-red-30 {
  color: var(--bs-red-30) !important;
}

.text-red-40 {
  color: var(--bs-red-40) !important;
}

.text-red-50 {
  color: var(--bs-red-50) !important;
}

.text-red-60 {
  color: var(--bs-red-60) !important;
}

.text-red-70 {
  color: var(--bs-red-70) !important;
}

.text-red-80 {
  color: var(--bs-red-80) !important;
}

.text-red-90 {
  color: var(--bs-red-90) !important;
}

.text-red-100 {
  color: var(--bs-red-100) !important;
}

.text-orange-10 {
  color: var(--bs-orange-10) !important;
}

.text-orange-20 {
  color: var(--bs-orange-20) !important;
}

.text-orange-30 {
  color: var(--bs-orange-30) !important;
}

.text-orange-40 {
  color: var(--bs-orange-40) !important;
}

.text-orange-50 {
  color: var(--bs-orange-50) !important;
}

.text-orange-60 {
  color: var(--bs-orange-60) !important;
}

.text-orange-70 {
  color: var(--bs-orange-70) !important;
}

.text-orange-80 {
  color: var(--bs-orange-80) !important;
}

.text-orange-90 {
  color: var(--bs-orange-90) !important;
}

.text-orange-100 {
  color: var(--bs-orange-100) !important;
}

.text-yellow-10 {
  color: var(--bs-yellow-10) !important;
}

.text-yellow-20 {
  color: var(--bs-yellow-20) !important;
}

.text-yellow-30 {
  color: var(--bs-yellow-30) !important;
}

.text-yellow-40 {
  color: var(--bs-yellow-40) !important;
}

.text-yellow-50 {
  color: var(--bs-yellow-50) !important;
}

.text-yellow-60 {
  color: var(--bs-yellow-60) !important;
}

.text-yellow-70 {
  color: var(--bs-yellow-70) !important;
}

.text-yellow-80 {
  color: var(--bs-yellow-80) !important;
}

.text-yellow-90 {
  color: var(--bs-yellow-90) !important;
}

.text-yellow-100 {
  color: var(--bs-yellow-100) !important;
}

.text-green-10 {
  color: var(--bs-green-10) !important;
}

.text-green-20 {
  color: var(--bs-green-20) !important;
}

.text-green-30 {
  color: var(--bs-green-30) !important;
}

.text-green-40 {
  color: var(--bs-green-40) !important;
}

.text-green-50 {
  color: var(--bs-green-50) !important;
}

.text-green-60 {
  color: var(--bs-green-60) !important;
}

.text-green-70 {
  color: var(--bs-green-70) !important;
}

.text-green-80 {
  color: var(--bs-green-80) !important;
}

.text-green-90 {
  color: var(--bs-green-90) !important;
}

.text-green-100 {
  color: var(--bs-green-100) !important;
}

.text-turquoise-10 {
  color: var(--bs-turquoise-10) !important;
}

.text-turquoise-20 {
  color: var(--bs-turquoise-20) !important;
}

.text-turquoise-30 {
  color: var(--bs-turquoise-30) !important;
}

.text-turquoise-40 {
  color: var(--bs-turquoise-40) !important;
}

.text-turquoise-50 {
  color: var(--bs-turquoise-50) !important;
}

.text-turquoise-60 {
  color: var(--bs-turquoise-60) !important;
}

.text-turquoise-70 {
  color: var(--bs-turquoise-70) !important;
}

.text-turquoise-80 {
  color: var(--bs-turquoise-80) !important;
}

.text-turquoise-90 {
  color: var(--bs-turquoise-90) !important;
}

.text-turquoise-100 {
  color: var(--bs-turquoise-100) !important;
}

.text-blue-10 {
  color: var(--bs-blue-10) !important;
}

.text-blue-20 {
  color: var(--bs-blue-20) !important;
}

.text-blue-30 {
  color: var(--bs-blue-30) !important;
}

.text-blue-40 {
  color: var(--bs-blue-40) !important;
}

.text-blue-50 {
  color: var(--bs-blue-50) !important;
}

.text-blue-60 {
  color: var(--bs-blue-60) !important;
}

.text-blue-70 {
  color: var(--bs-blue-70) !important;
}

.text-blue-80 {
  color: var(--bs-blue-80) !important;
}

.text-blue-90 {
  color: var(--bs-blue-90) !important;
}

.text-blue-100 {
  color: var(--bs-blue-100) !important;
}

.text-purple-10 {
  color: var(--bs-purple-10) !important;
}

.text-purple-20 {
  color: var(--bs-purple-20) !important;
}

.text-purple-30 {
  color: var(--bs-purple-30) !important;
}

.text-purple-40 {
  color: var(--bs-purple-40) !important;
}

.text-purple-50 {
  color: var(--bs-purple-50) !important;
}

.text-purple-60 {
  color: var(--bs-purple-60) !important;
}

.text-purple-70 {
  color: var(--bs-purple-70) !important;
}

.text-purple-80 {
  color: var(--bs-purple-80) !important;
}

.text-purple-90 {
  color: var(--bs-purple-90) !important;
}

.text-purple-100 {
  color: var(--bs-purple-100) !important;
}

.text-pink-10 {
  color: var(--bs-pink-10) !important;
}

.text-pink-20 {
  color: var(--bs-pink-20) !important;
}

.text-pink-30 {
  color: var(--bs-pink-30) !important;
}

.text-pink-40 {
  color: var(--bs-pink-40) !important;
}

.text-pink-50 {
  color: var(--bs-pink-50) !important;
}

.text-pink-60 {
  color: var(--bs-pink-60) !important;
}

.text-pink-70 {
  color: var(--bs-pink-70) !important;
}

.text-pink-80 {
  color: var(--bs-pink-80) !important;
}

.text-pink-90 {
  color: var(--bs-pink-90) !important;
}

.text-pink-100 {
  color: var(--bs-pink-100) !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-socgen {
  --bs-focus-ring-color: rgba(var(--bs-socgen-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-alt {
  --bs-focus-ring-color: rgba(var(--bs-primary-alt-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-socgen {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-socgen-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-alt {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-alt-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-4px {
  margin: 0.25rem !important;
}

.m-8px {
  margin: 0.5rem !important;
}

.m-12px {
  margin: 0.75rem !important;
}

.m-16px {
  margin: 1rem !important;
}

.m-24px {
  margin: 1.5rem !important;
}

.m-32px {
  margin: 2rem !important;
}

.m-48px {
  margin: 3rem !important;
}

.m-64px {
  margin: 4rem !important;
}

.m-96px {
  margin: 6rem !important;
}

.m-128px {
  margin: 8rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-4px {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-8px {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-12px {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-16px {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-24px {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-32px {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-48px {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-64px {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-96px {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-128px {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-4px {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-8px {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-12px {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-16px {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-24px {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-32px {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-48px {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-64px {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-96px {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-128px {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-4px {
  margin-top: 0.25rem !important;
}

.mt-8px {
  margin-top: 0.5rem !important;
}

.mt-12px {
  margin-top: 0.75rem !important;
}

.mt-16px {
  margin-top: 1rem !important;
}

.mt-24px {
  margin-top: 1.5rem !important;
}

.mt-32px {
  margin-top: 2rem !important;
}

.mt-48px {
  margin-top: 3rem !important;
}

.mt-64px {
  margin-top: 4rem !important;
}

.mt-96px {
  margin-top: 6rem !important;
}

.mt-128px {
  margin-top: 8rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-4px {
  margin-right: 0.25rem !important;
}

.me-8px {
  margin-right: 0.5rem !important;
}

.me-12px {
  margin-right: 0.75rem !important;
}

.me-16px {
  margin-right: 1rem !important;
}

.me-24px {
  margin-right: 1.5rem !important;
}

.me-32px {
  margin-right: 2rem !important;
}

.me-48px {
  margin-right: 3rem !important;
}

.me-64px {
  margin-right: 4rem !important;
}

.me-96px {
  margin-right: 6rem !important;
}

.me-128px {
  margin-right: 8rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-4px {
  margin-bottom: 0.25rem !important;
}

.mb-8px {
  margin-bottom: 0.5rem !important;
}

.mb-12px {
  margin-bottom: 0.75rem !important;
}

.mb-16px {
  margin-bottom: 1rem !important;
}

.mb-24px {
  margin-bottom: 1.5rem !important;
}

.mb-32px {
  margin-bottom: 2rem !important;
}

.mb-48px {
  margin-bottom: 3rem !important;
}

.mb-64px {
  margin-bottom: 4rem !important;
}

.mb-96px {
  margin-bottom: 6rem !important;
}

.mb-128px {
  margin-bottom: 8rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-4px {
  margin-left: 0.25rem !important;
}

.ms-8px {
  margin-left: 0.5rem !important;
}

.ms-12px {
  margin-left: 0.75rem !important;
}

.ms-16px {
  margin-left: 1rem !important;
}

.ms-24px {
  margin-left: 1.5rem !important;
}

.ms-32px {
  margin-left: 2rem !important;
}

.ms-48px {
  margin-left: 3rem !important;
}

.ms-64px {
  margin-left: 4rem !important;
}

.ms-96px {
  margin-left: 6rem !important;
}

.ms-128px {
  margin-left: 8rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n4px {
  margin: -0.25rem !important;
}

.m-n8px {
  margin: -0.5rem !important;
}

.m-n12px {
  margin: -0.75rem !important;
}

.m-n16px {
  margin: -1rem !important;
}

.m-n24px {
  margin: -1.5rem !important;
}

.m-n32px {
  margin: -2rem !important;
}

.m-n48px {
  margin: -3rem !important;
}

.m-n64px {
  margin: -4rem !important;
}

.m-n96px {
  margin: -6rem !important;
}

.m-n128px {
  margin: -8rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n4px {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n8px {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n12px {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n16px {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n24px {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n32px {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n48px {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n64px {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n96px {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n128px {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n4px {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n8px {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n12px {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n16px {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n24px {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n32px {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n48px {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n64px {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n96px {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n128px {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n4px {
  margin-top: -0.25rem !important;
}

.mt-n8px {
  margin-top: -0.5rem !important;
}

.mt-n12px {
  margin-top: -0.75rem !important;
}

.mt-n16px {
  margin-top: -1rem !important;
}

.mt-n24px {
  margin-top: -1.5rem !important;
}

.mt-n32px {
  margin-top: -2rem !important;
}

.mt-n48px {
  margin-top: -3rem !important;
}

.mt-n64px {
  margin-top: -4rem !important;
}

.mt-n96px {
  margin-top: -6rem !important;
}

.mt-n128px {
  margin-top: -8rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n4px {
  margin-right: -0.25rem !important;
}

.me-n8px {
  margin-right: -0.5rem !important;
}

.me-n12px {
  margin-right: -0.75rem !important;
}

.me-n16px {
  margin-right: -1rem !important;
}

.me-n24px {
  margin-right: -1.5rem !important;
}

.me-n32px {
  margin-right: -2rem !important;
}

.me-n48px {
  margin-right: -3rem !important;
}

.me-n64px {
  margin-right: -4rem !important;
}

.me-n96px {
  margin-right: -6rem !important;
}

.me-n128px {
  margin-right: -8rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n4px {
  margin-bottom: -0.25rem !important;
}

.mb-n8px {
  margin-bottom: -0.5rem !important;
}

.mb-n12px {
  margin-bottom: -0.75rem !important;
}

.mb-n16px {
  margin-bottom: -1rem !important;
}

.mb-n24px {
  margin-bottom: -1.5rem !important;
}

.mb-n32px {
  margin-bottom: -2rem !important;
}

.mb-n48px {
  margin-bottom: -3rem !important;
}

.mb-n64px {
  margin-bottom: -4rem !important;
}

.mb-n96px {
  margin-bottom: -6rem !important;
}

.mb-n128px {
  margin-bottom: -8rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n4px {
  margin-left: -0.25rem !important;
}

.ms-n8px {
  margin-left: -0.5rem !important;
}

.ms-n12px {
  margin-left: -0.75rem !important;
}

.ms-n16px {
  margin-left: -1rem !important;
}

.ms-n24px {
  margin-left: -1.5rem !important;
}

.ms-n32px {
  margin-left: -2rem !important;
}

.ms-n48px {
  margin-left: -3rem !important;
}

.ms-n64px {
  margin-left: -4rem !important;
}

.ms-n96px {
  margin-left: -6rem !important;
}

.ms-n128px {
  margin-left: -8rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-4px {
  padding: 0.25rem !important;
}

.p-8px {
  padding: 0.5rem !important;
}

.p-12px {
  padding: 0.75rem !important;
}

.p-16px {
  padding: 1rem !important;
}

.p-24px {
  padding: 1.5rem !important;
}

.p-32px {
  padding: 2rem !important;
}

.p-48px {
  padding: 3rem !important;
}

.p-64px {
  padding: 4rem !important;
}

.p-96px {
  padding: 6rem !important;
}

.p-128px {
  padding: 8rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-4px {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-8px {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-12px {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-16px {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-24px {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-32px {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-48px {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-64px {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-96px {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-128px {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-4px {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-8px {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-12px {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-16px {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-24px {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-32px {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-48px {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-64px {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-96px {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-128px {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-4px {
  padding-top: 0.25rem !important;
}

.pt-8px {
  padding-top: 0.5rem !important;
}

.pt-12px {
  padding-top: 0.75rem !important;
}

.pt-16px {
  padding-top: 1rem !important;
}

.pt-24px {
  padding-top: 1.5rem !important;
}

.pt-32px {
  padding-top: 2rem !important;
}

.pt-48px {
  padding-top: 3rem !important;
}

.pt-64px {
  padding-top: 4rem !important;
}

.pt-96px {
  padding-top: 6rem !important;
}

.pt-128px {
  padding-top: 8rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-4px {
  padding-right: 0.25rem !important;
}

.pe-8px {
  padding-right: 0.5rem !important;
}

.pe-12px {
  padding-right: 0.75rem !important;
}

.pe-16px {
  padding-right: 1rem !important;
}

.pe-24px {
  padding-right: 1.5rem !important;
}

.pe-32px {
  padding-right: 2rem !important;
}

.pe-48px {
  padding-right: 3rem !important;
}

.pe-64px {
  padding-right: 4rem !important;
}

.pe-96px {
  padding-right: 6rem !important;
}

.pe-128px {
  padding-right: 8rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-4px {
  padding-bottom: 0.25rem !important;
}

.pb-8px {
  padding-bottom: 0.5rem !important;
}

.pb-12px {
  padding-bottom: 0.75rem !important;
}

.pb-16px {
  padding-bottom: 1rem !important;
}

.pb-24px {
  padding-bottom: 1.5rem !important;
}

.pb-32px {
  padding-bottom: 2rem !important;
}

.pb-48px {
  padding-bottom: 3rem !important;
}

.pb-64px {
  padding-bottom: 4rem !important;
}

.pb-96px {
  padding-bottom: 6rem !important;
}

.pb-128px {
  padding-bottom: 8rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-4px {
  padding-left: 0.25rem !important;
}

.ps-8px {
  padding-left: 0.5rem !important;
}

.ps-12px {
  padding-left: 0.75rem !important;
}

.ps-16px {
  padding-left: 1rem !important;
}

.ps-24px {
  padding-left: 1.5rem !important;
}

.ps-32px {
  padding-left: 2rem !important;
}

.ps-48px {
  padding-left: 3rem !important;
}

.ps-64px {
  padding-left: 4rem !important;
}

.ps-96px {
  padding-left: 6rem !important;
}

.ps-128px {
  padding-left: 8rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-4px {
  gap: 0.25rem !important;
}

.gap-8px {
  gap: 0.5rem !important;
}

.gap-12px {
  gap: 0.75rem !important;
}

.gap-16px {
  gap: 1rem !important;
}

.gap-24px {
  gap: 1.5rem !important;
}

.gap-32px {
  gap: 2rem !important;
}

.gap-48px {
  gap: 3rem !important;
}

.gap-64px {
  gap: 4rem !important;
}

.gap-96px {
  gap: 6rem !important;
}

.gap-128px {
  gap: 8rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-4px {
  row-gap: 0.25rem !important;
}

.row-gap-8px {
  row-gap: 0.5rem !important;
}

.row-gap-12px {
  row-gap: 0.75rem !important;
}

.row-gap-16px {
  row-gap: 1rem !important;
}

.row-gap-24px {
  row-gap: 1.5rem !important;
}

.row-gap-32px {
  row-gap: 2rem !important;
}

.row-gap-48px {
  row-gap: 3rem !important;
}

.row-gap-64px {
  row-gap: 4rem !important;
}

.row-gap-96px {
  row-gap: 6rem !important;
}

.row-gap-128px {
  row-gap: 8rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}

.column-gap-4px {
  -moz-column-gap: 0.25rem !important;
  column-gap: 0.25rem !important;
}

.column-gap-8px {
  -moz-column-gap: 0.5rem !important;
  column-gap: 0.5rem !important;
}

.column-gap-12px {
  -moz-column-gap: 0.75rem !important;
  column-gap: 0.75rem !important;
}

.column-gap-16px {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.column-gap-24px {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-32px {
  -moz-column-gap: 2rem !important;
  column-gap: 2rem !important;
}

.column-gap-48px {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}

.column-gap-64px {
  -moz-column-gap: 4rem !important;
  column-gap: 4rem !important;
}

.column-gap-96px {
  -moz-column-gap: 6rem !important;
  column-gap: 6rem !important;
}

.column-gap-128px {
  -moz-column-gap: 8rem !important;
  column-gap: 8rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-6 {
  font-size: 0.75rem !important;
}

.fs-5 {
  font-size: 0.875rem !important;
}

.fs-4 {
  font-size: 1rem !important;
}

.fs-3 {
  font-size: 1.125rem !important;
}

.fs-2 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}

.fs-1 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-21 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}

.fs-24 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-26 {
  font-size: calc(1.2875rem + 0.45vw) !important;
}

.fs-30 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-36 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-42 {
  font-size: 2.625 !important;
}

.fs-68 {
  font-size: 4.25 !important;
}

.fs-112 {
  font-size: 7 !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.4285714286 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline, .text-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through, .text-strikethrough {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-socgen {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-socgen-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-alt {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-alt-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(35, 31, 32, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-white {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-black {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-socgen {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-socgen-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-socgen-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-alt {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-alt-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-primary-alt-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-socgen {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-socgen-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-alt {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-alt-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 480px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-4px {
    margin: 0.25rem !important;
  }
  .m-sm-8px {
    margin: 0.5rem !important;
  }
  .m-sm-12px {
    margin: 0.75rem !important;
  }
  .m-sm-16px {
    margin: 1rem !important;
  }
  .m-sm-24px {
    margin: 1.5rem !important;
  }
  .m-sm-32px {
    margin: 2rem !important;
  }
  .m-sm-48px {
    margin: 3rem !important;
  }
  .m-sm-64px {
    margin: 4rem !important;
  }
  .m-sm-96px {
    margin: 6rem !important;
  }
  .m-sm-128px {
    margin: 8rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-4px {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-8px {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-12px {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-16px {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-24px {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-32px {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-48px {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-64px {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-96px {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-128px {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-4px {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-8px {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-12px {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-16px {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-24px {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-32px {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-48px {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-64px {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-96px {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-128px {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-4px {
    margin-top: 0.25rem !important;
  }
  .mt-sm-8px {
    margin-top: 0.5rem !important;
  }
  .mt-sm-12px {
    margin-top: 0.75rem !important;
  }
  .mt-sm-16px {
    margin-top: 1rem !important;
  }
  .mt-sm-24px {
    margin-top: 1.5rem !important;
  }
  .mt-sm-32px {
    margin-top: 2rem !important;
  }
  .mt-sm-48px {
    margin-top: 3rem !important;
  }
  .mt-sm-64px {
    margin-top: 4rem !important;
  }
  .mt-sm-96px {
    margin-top: 6rem !important;
  }
  .mt-sm-128px {
    margin-top: 8rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-4px {
    margin-right: 0.25rem !important;
  }
  .me-sm-8px {
    margin-right: 0.5rem !important;
  }
  .me-sm-12px {
    margin-right: 0.75rem !important;
  }
  .me-sm-16px {
    margin-right: 1rem !important;
  }
  .me-sm-24px {
    margin-right: 1.5rem !important;
  }
  .me-sm-32px {
    margin-right: 2rem !important;
  }
  .me-sm-48px {
    margin-right: 3rem !important;
  }
  .me-sm-64px {
    margin-right: 4rem !important;
  }
  .me-sm-96px {
    margin-right: 6rem !important;
  }
  .me-sm-128px {
    margin-right: 8rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-4px {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-8px {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-12px {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-16px {
    margin-bottom: 1rem !important;
  }
  .mb-sm-24px {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-32px {
    margin-bottom: 2rem !important;
  }
  .mb-sm-48px {
    margin-bottom: 3rem !important;
  }
  .mb-sm-64px {
    margin-bottom: 4rem !important;
  }
  .mb-sm-96px {
    margin-bottom: 6rem !important;
  }
  .mb-sm-128px {
    margin-bottom: 8rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-4px {
    margin-left: 0.25rem !important;
  }
  .ms-sm-8px {
    margin-left: 0.5rem !important;
  }
  .ms-sm-12px {
    margin-left: 0.75rem !important;
  }
  .ms-sm-16px {
    margin-left: 1rem !important;
  }
  .ms-sm-24px {
    margin-left: 1.5rem !important;
  }
  .ms-sm-32px {
    margin-left: 2rem !important;
  }
  .ms-sm-48px {
    margin-left: 3rem !important;
  }
  .ms-sm-64px {
    margin-left: 4rem !important;
  }
  .ms-sm-96px {
    margin-left: 6rem !important;
  }
  .ms-sm-128px {
    margin-left: 8rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n4px {
    margin: -0.25rem !important;
  }
  .m-sm-n8px {
    margin: -0.5rem !important;
  }
  .m-sm-n12px {
    margin: -0.75rem !important;
  }
  .m-sm-n16px {
    margin: -1rem !important;
  }
  .m-sm-n24px {
    margin: -1.5rem !important;
  }
  .m-sm-n32px {
    margin: -2rem !important;
  }
  .m-sm-n48px {
    margin: -3rem !important;
  }
  .m-sm-n64px {
    margin: -4rem !important;
  }
  .m-sm-n96px {
    margin: -6rem !important;
  }
  .m-sm-n128px {
    margin: -8rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n4px {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n8px {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n12px {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n16px {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n24px {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n32px {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n48px {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n64px {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n96px {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n128px {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n4px {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n8px {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n12px {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n16px {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n24px {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n32px {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n48px {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n64px {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n96px {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n128px {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n4px {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n8px {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n12px {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n16px {
    margin-top: -1rem !important;
  }
  .mt-sm-n24px {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n32px {
    margin-top: -2rem !important;
  }
  .mt-sm-n48px {
    margin-top: -3rem !important;
  }
  .mt-sm-n64px {
    margin-top: -4rem !important;
  }
  .mt-sm-n96px {
    margin-top: -6rem !important;
  }
  .mt-sm-n128px {
    margin-top: -8rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n4px {
    margin-right: -0.25rem !important;
  }
  .me-sm-n8px {
    margin-right: -0.5rem !important;
  }
  .me-sm-n12px {
    margin-right: -0.75rem !important;
  }
  .me-sm-n16px {
    margin-right: -1rem !important;
  }
  .me-sm-n24px {
    margin-right: -1.5rem !important;
  }
  .me-sm-n32px {
    margin-right: -2rem !important;
  }
  .me-sm-n48px {
    margin-right: -3rem !important;
  }
  .me-sm-n64px {
    margin-right: -4rem !important;
  }
  .me-sm-n96px {
    margin-right: -6rem !important;
  }
  .me-sm-n128px {
    margin-right: -8rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n4px {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n8px {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n12px {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n16px {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n24px {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n32px {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n48px {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n64px {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n96px {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n128px {
    margin-bottom: -8rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n4px {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n8px {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n12px {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n16px {
    margin-left: -1rem !important;
  }
  .ms-sm-n24px {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n32px {
    margin-left: -2rem !important;
  }
  .ms-sm-n48px {
    margin-left: -3rem !important;
  }
  .ms-sm-n64px {
    margin-left: -4rem !important;
  }
  .ms-sm-n96px {
    margin-left: -6rem !important;
  }
  .ms-sm-n128px {
    margin-left: -8rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-4px {
    padding: 0.25rem !important;
  }
  .p-sm-8px {
    padding: 0.5rem !important;
  }
  .p-sm-12px {
    padding: 0.75rem !important;
  }
  .p-sm-16px {
    padding: 1rem !important;
  }
  .p-sm-24px {
    padding: 1.5rem !important;
  }
  .p-sm-32px {
    padding: 2rem !important;
  }
  .p-sm-48px {
    padding: 3rem !important;
  }
  .p-sm-64px {
    padding: 4rem !important;
  }
  .p-sm-96px {
    padding: 6rem !important;
  }
  .p-sm-128px {
    padding: 8rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-4px {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-8px {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-12px {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-16px {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-24px {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-32px {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-48px {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-64px {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-96px {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-128px {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-4px {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-8px {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-12px {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-16px {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-24px {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-32px {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-48px {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-64px {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-96px {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-128px {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-4px {
    padding-top: 0.25rem !important;
  }
  .pt-sm-8px {
    padding-top: 0.5rem !important;
  }
  .pt-sm-12px {
    padding-top: 0.75rem !important;
  }
  .pt-sm-16px {
    padding-top: 1rem !important;
  }
  .pt-sm-24px {
    padding-top: 1.5rem !important;
  }
  .pt-sm-32px {
    padding-top: 2rem !important;
  }
  .pt-sm-48px {
    padding-top: 3rem !important;
  }
  .pt-sm-64px {
    padding-top: 4rem !important;
  }
  .pt-sm-96px {
    padding-top: 6rem !important;
  }
  .pt-sm-128px {
    padding-top: 8rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-4px {
    padding-right: 0.25rem !important;
  }
  .pe-sm-8px {
    padding-right: 0.5rem !important;
  }
  .pe-sm-12px {
    padding-right: 0.75rem !important;
  }
  .pe-sm-16px {
    padding-right: 1rem !important;
  }
  .pe-sm-24px {
    padding-right: 1.5rem !important;
  }
  .pe-sm-32px {
    padding-right: 2rem !important;
  }
  .pe-sm-48px {
    padding-right: 3rem !important;
  }
  .pe-sm-64px {
    padding-right: 4rem !important;
  }
  .pe-sm-96px {
    padding-right: 6rem !important;
  }
  .pe-sm-128px {
    padding-right: 8rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-4px {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-8px {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-12px {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-16px {
    padding-bottom: 1rem !important;
  }
  .pb-sm-24px {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-32px {
    padding-bottom: 2rem !important;
  }
  .pb-sm-48px {
    padding-bottom: 3rem !important;
  }
  .pb-sm-64px {
    padding-bottom: 4rem !important;
  }
  .pb-sm-96px {
    padding-bottom: 6rem !important;
  }
  .pb-sm-128px {
    padding-bottom: 8rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-4px {
    padding-left: 0.25rem !important;
  }
  .ps-sm-8px {
    padding-left: 0.5rem !important;
  }
  .ps-sm-12px {
    padding-left: 0.75rem !important;
  }
  .ps-sm-16px {
    padding-left: 1rem !important;
  }
  .ps-sm-24px {
    padding-left: 1.5rem !important;
  }
  .ps-sm-32px {
    padding-left: 2rem !important;
  }
  .ps-sm-48px {
    padding-left: 3rem !important;
  }
  .ps-sm-64px {
    padding-left: 4rem !important;
  }
  .ps-sm-96px {
    padding-left: 6rem !important;
  }
  .ps-sm-128px {
    padding-left: 8rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-4px {
    gap: 0.25rem !important;
  }
  .gap-sm-8px {
    gap: 0.5rem !important;
  }
  .gap-sm-12px {
    gap: 0.75rem !important;
  }
  .gap-sm-16px {
    gap: 1rem !important;
  }
  .gap-sm-24px {
    gap: 1.5rem !important;
  }
  .gap-sm-32px {
    gap: 2rem !important;
  }
  .gap-sm-48px {
    gap: 3rem !important;
  }
  .gap-sm-64px {
    gap: 4rem !important;
  }
  .gap-sm-96px {
    gap: 6rem !important;
  }
  .gap-sm-128px {
    gap: 8rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-4px {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-8px {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-12px {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-16px {
    row-gap: 1rem !important;
  }
  .row-gap-sm-24px {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-32px {
    row-gap: 2rem !important;
  }
  .row-gap-sm-48px {
    row-gap: 3rem !important;
  }
  .row-gap-sm-64px {
    row-gap: 4rem !important;
  }
  .row-gap-sm-96px {
    row-gap: 6rem !important;
  }
  .row-gap-sm-128px {
    row-gap: 8rem !important;
  }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-sm-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-sm-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-sm-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-sm-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-sm-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-sm-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-4px {
    margin: 0.25rem !important;
  }
  .m-md-8px {
    margin: 0.5rem !important;
  }
  .m-md-12px {
    margin: 0.75rem !important;
  }
  .m-md-16px {
    margin: 1rem !important;
  }
  .m-md-24px {
    margin: 1.5rem !important;
  }
  .m-md-32px {
    margin: 2rem !important;
  }
  .m-md-48px {
    margin: 3rem !important;
  }
  .m-md-64px {
    margin: 4rem !important;
  }
  .m-md-96px {
    margin: 6rem !important;
  }
  .m-md-128px {
    margin: 8rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-4px {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-8px {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-12px {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-16px {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-24px {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-32px {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-48px {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-64px {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-96px {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-128px {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-4px {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-8px {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-12px {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-16px {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-24px {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-32px {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-48px {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-64px {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-96px {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-128px {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-4px {
    margin-top: 0.25rem !important;
  }
  .mt-md-8px {
    margin-top: 0.5rem !important;
  }
  .mt-md-12px {
    margin-top: 0.75rem !important;
  }
  .mt-md-16px {
    margin-top: 1rem !important;
  }
  .mt-md-24px {
    margin-top: 1.5rem !important;
  }
  .mt-md-32px {
    margin-top: 2rem !important;
  }
  .mt-md-48px {
    margin-top: 3rem !important;
  }
  .mt-md-64px {
    margin-top: 4rem !important;
  }
  .mt-md-96px {
    margin-top: 6rem !important;
  }
  .mt-md-128px {
    margin-top: 8rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-4px {
    margin-right: 0.25rem !important;
  }
  .me-md-8px {
    margin-right: 0.5rem !important;
  }
  .me-md-12px {
    margin-right: 0.75rem !important;
  }
  .me-md-16px {
    margin-right: 1rem !important;
  }
  .me-md-24px {
    margin-right: 1.5rem !important;
  }
  .me-md-32px {
    margin-right: 2rem !important;
  }
  .me-md-48px {
    margin-right: 3rem !important;
  }
  .me-md-64px {
    margin-right: 4rem !important;
  }
  .me-md-96px {
    margin-right: 6rem !important;
  }
  .me-md-128px {
    margin-right: 8rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-4px {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-8px {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-12px {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-16px {
    margin-bottom: 1rem !important;
  }
  .mb-md-24px {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-32px {
    margin-bottom: 2rem !important;
  }
  .mb-md-48px {
    margin-bottom: 3rem !important;
  }
  .mb-md-64px {
    margin-bottom: 4rem !important;
  }
  .mb-md-96px {
    margin-bottom: 6rem !important;
  }
  .mb-md-128px {
    margin-bottom: 8rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-4px {
    margin-left: 0.25rem !important;
  }
  .ms-md-8px {
    margin-left: 0.5rem !important;
  }
  .ms-md-12px {
    margin-left: 0.75rem !important;
  }
  .ms-md-16px {
    margin-left: 1rem !important;
  }
  .ms-md-24px {
    margin-left: 1.5rem !important;
  }
  .ms-md-32px {
    margin-left: 2rem !important;
  }
  .ms-md-48px {
    margin-left: 3rem !important;
  }
  .ms-md-64px {
    margin-left: 4rem !important;
  }
  .ms-md-96px {
    margin-left: 6rem !important;
  }
  .ms-md-128px {
    margin-left: 8rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n4px {
    margin: -0.25rem !important;
  }
  .m-md-n8px {
    margin: -0.5rem !important;
  }
  .m-md-n12px {
    margin: -0.75rem !important;
  }
  .m-md-n16px {
    margin: -1rem !important;
  }
  .m-md-n24px {
    margin: -1.5rem !important;
  }
  .m-md-n32px {
    margin: -2rem !important;
  }
  .m-md-n48px {
    margin: -3rem !important;
  }
  .m-md-n64px {
    margin: -4rem !important;
  }
  .m-md-n96px {
    margin: -6rem !important;
  }
  .m-md-n128px {
    margin: -8rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n4px {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n8px {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n12px {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n16px {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n24px {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n32px {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n48px {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n64px {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n96px {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n128px {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n4px {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n8px {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n12px {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n16px {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n24px {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n32px {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n48px {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n64px {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n96px {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n128px {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n4px {
    margin-top: -0.25rem !important;
  }
  .mt-md-n8px {
    margin-top: -0.5rem !important;
  }
  .mt-md-n12px {
    margin-top: -0.75rem !important;
  }
  .mt-md-n16px {
    margin-top: -1rem !important;
  }
  .mt-md-n24px {
    margin-top: -1.5rem !important;
  }
  .mt-md-n32px {
    margin-top: -2rem !important;
  }
  .mt-md-n48px {
    margin-top: -3rem !important;
  }
  .mt-md-n64px {
    margin-top: -4rem !important;
  }
  .mt-md-n96px {
    margin-top: -6rem !important;
  }
  .mt-md-n128px {
    margin-top: -8rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n4px {
    margin-right: -0.25rem !important;
  }
  .me-md-n8px {
    margin-right: -0.5rem !important;
  }
  .me-md-n12px {
    margin-right: -0.75rem !important;
  }
  .me-md-n16px {
    margin-right: -1rem !important;
  }
  .me-md-n24px {
    margin-right: -1.5rem !important;
  }
  .me-md-n32px {
    margin-right: -2rem !important;
  }
  .me-md-n48px {
    margin-right: -3rem !important;
  }
  .me-md-n64px {
    margin-right: -4rem !important;
  }
  .me-md-n96px {
    margin-right: -6rem !important;
  }
  .me-md-n128px {
    margin-right: -8rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n4px {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n8px {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n12px {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n16px {
    margin-bottom: -1rem !important;
  }
  .mb-md-n24px {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n32px {
    margin-bottom: -2rem !important;
  }
  .mb-md-n48px {
    margin-bottom: -3rem !important;
  }
  .mb-md-n64px {
    margin-bottom: -4rem !important;
  }
  .mb-md-n96px {
    margin-bottom: -6rem !important;
  }
  .mb-md-n128px {
    margin-bottom: -8rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n4px {
    margin-left: -0.25rem !important;
  }
  .ms-md-n8px {
    margin-left: -0.5rem !important;
  }
  .ms-md-n12px {
    margin-left: -0.75rem !important;
  }
  .ms-md-n16px {
    margin-left: -1rem !important;
  }
  .ms-md-n24px {
    margin-left: -1.5rem !important;
  }
  .ms-md-n32px {
    margin-left: -2rem !important;
  }
  .ms-md-n48px {
    margin-left: -3rem !important;
  }
  .ms-md-n64px {
    margin-left: -4rem !important;
  }
  .ms-md-n96px {
    margin-left: -6rem !important;
  }
  .ms-md-n128px {
    margin-left: -8rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-4px {
    padding: 0.25rem !important;
  }
  .p-md-8px {
    padding: 0.5rem !important;
  }
  .p-md-12px {
    padding: 0.75rem !important;
  }
  .p-md-16px {
    padding: 1rem !important;
  }
  .p-md-24px {
    padding: 1.5rem !important;
  }
  .p-md-32px {
    padding: 2rem !important;
  }
  .p-md-48px {
    padding: 3rem !important;
  }
  .p-md-64px {
    padding: 4rem !important;
  }
  .p-md-96px {
    padding: 6rem !important;
  }
  .p-md-128px {
    padding: 8rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-4px {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-8px {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-12px {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-16px {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-24px {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-32px {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-48px {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-64px {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-96px {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-128px {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-4px {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-8px {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-12px {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-16px {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-24px {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-32px {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-48px {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-64px {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-96px {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-128px {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-4px {
    padding-top: 0.25rem !important;
  }
  .pt-md-8px {
    padding-top: 0.5rem !important;
  }
  .pt-md-12px {
    padding-top: 0.75rem !important;
  }
  .pt-md-16px {
    padding-top: 1rem !important;
  }
  .pt-md-24px {
    padding-top: 1.5rem !important;
  }
  .pt-md-32px {
    padding-top: 2rem !important;
  }
  .pt-md-48px {
    padding-top: 3rem !important;
  }
  .pt-md-64px {
    padding-top: 4rem !important;
  }
  .pt-md-96px {
    padding-top: 6rem !important;
  }
  .pt-md-128px {
    padding-top: 8rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-4px {
    padding-right: 0.25rem !important;
  }
  .pe-md-8px {
    padding-right: 0.5rem !important;
  }
  .pe-md-12px {
    padding-right: 0.75rem !important;
  }
  .pe-md-16px {
    padding-right: 1rem !important;
  }
  .pe-md-24px {
    padding-right: 1.5rem !important;
  }
  .pe-md-32px {
    padding-right: 2rem !important;
  }
  .pe-md-48px {
    padding-right: 3rem !important;
  }
  .pe-md-64px {
    padding-right: 4rem !important;
  }
  .pe-md-96px {
    padding-right: 6rem !important;
  }
  .pe-md-128px {
    padding-right: 8rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-4px {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-8px {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-12px {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-16px {
    padding-bottom: 1rem !important;
  }
  .pb-md-24px {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-32px {
    padding-bottom: 2rem !important;
  }
  .pb-md-48px {
    padding-bottom: 3rem !important;
  }
  .pb-md-64px {
    padding-bottom: 4rem !important;
  }
  .pb-md-96px {
    padding-bottom: 6rem !important;
  }
  .pb-md-128px {
    padding-bottom: 8rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-4px {
    padding-left: 0.25rem !important;
  }
  .ps-md-8px {
    padding-left: 0.5rem !important;
  }
  .ps-md-12px {
    padding-left: 0.75rem !important;
  }
  .ps-md-16px {
    padding-left: 1rem !important;
  }
  .ps-md-24px {
    padding-left: 1.5rem !important;
  }
  .ps-md-32px {
    padding-left: 2rem !important;
  }
  .ps-md-48px {
    padding-left: 3rem !important;
  }
  .ps-md-64px {
    padding-left: 4rem !important;
  }
  .ps-md-96px {
    padding-left: 6rem !important;
  }
  .ps-md-128px {
    padding-left: 8rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-4px {
    gap: 0.25rem !important;
  }
  .gap-md-8px {
    gap: 0.5rem !important;
  }
  .gap-md-12px {
    gap: 0.75rem !important;
  }
  .gap-md-16px {
    gap: 1rem !important;
  }
  .gap-md-24px {
    gap: 1.5rem !important;
  }
  .gap-md-32px {
    gap: 2rem !important;
  }
  .gap-md-48px {
    gap: 3rem !important;
  }
  .gap-md-64px {
    gap: 4rem !important;
  }
  .gap-md-96px {
    gap: 6rem !important;
  }
  .gap-md-128px {
    gap: 8rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-4px {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-8px {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-12px {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-16px {
    row-gap: 1rem !important;
  }
  .row-gap-md-24px {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-32px {
    row-gap: 2rem !important;
  }
  .row-gap-md-48px {
    row-gap: 3rem !important;
  }
  .row-gap-md-64px {
    row-gap: 4rem !important;
  }
  .row-gap-md-96px {
    row-gap: 6rem !important;
  }
  .row-gap-md-128px {
    row-gap: 8rem !important;
  }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-md-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-md-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-md-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-md-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-md-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-md-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-md-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-md-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-md-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-md-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-4px {
    margin: 0.25rem !important;
  }
  .m-lg-8px {
    margin: 0.5rem !important;
  }
  .m-lg-12px {
    margin: 0.75rem !important;
  }
  .m-lg-16px {
    margin: 1rem !important;
  }
  .m-lg-24px {
    margin: 1.5rem !important;
  }
  .m-lg-32px {
    margin: 2rem !important;
  }
  .m-lg-48px {
    margin: 3rem !important;
  }
  .m-lg-64px {
    margin: 4rem !important;
  }
  .m-lg-96px {
    margin: 6rem !important;
  }
  .m-lg-128px {
    margin: 8rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-4px {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-8px {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-12px {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-16px {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-24px {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-32px {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-48px {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-64px {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-96px {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-128px {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-4px {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-8px {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-12px {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-16px {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-24px {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-32px {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-48px {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-64px {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-96px {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-128px {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-4px {
    margin-top: 0.25rem !important;
  }
  .mt-lg-8px {
    margin-top: 0.5rem !important;
  }
  .mt-lg-12px {
    margin-top: 0.75rem !important;
  }
  .mt-lg-16px {
    margin-top: 1rem !important;
  }
  .mt-lg-24px {
    margin-top: 1.5rem !important;
  }
  .mt-lg-32px {
    margin-top: 2rem !important;
  }
  .mt-lg-48px {
    margin-top: 3rem !important;
  }
  .mt-lg-64px {
    margin-top: 4rem !important;
  }
  .mt-lg-96px {
    margin-top: 6rem !important;
  }
  .mt-lg-128px {
    margin-top: 8rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-4px {
    margin-right: 0.25rem !important;
  }
  .me-lg-8px {
    margin-right: 0.5rem !important;
  }
  .me-lg-12px {
    margin-right: 0.75rem !important;
  }
  .me-lg-16px {
    margin-right: 1rem !important;
  }
  .me-lg-24px {
    margin-right: 1.5rem !important;
  }
  .me-lg-32px {
    margin-right: 2rem !important;
  }
  .me-lg-48px {
    margin-right: 3rem !important;
  }
  .me-lg-64px {
    margin-right: 4rem !important;
  }
  .me-lg-96px {
    margin-right: 6rem !important;
  }
  .me-lg-128px {
    margin-right: 8rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-4px {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-8px {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-12px {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-16px {
    margin-bottom: 1rem !important;
  }
  .mb-lg-24px {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-32px {
    margin-bottom: 2rem !important;
  }
  .mb-lg-48px {
    margin-bottom: 3rem !important;
  }
  .mb-lg-64px {
    margin-bottom: 4rem !important;
  }
  .mb-lg-96px {
    margin-bottom: 6rem !important;
  }
  .mb-lg-128px {
    margin-bottom: 8rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-4px {
    margin-left: 0.25rem !important;
  }
  .ms-lg-8px {
    margin-left: 0.5rem !important;
  }
  .ms-lg-12px {
    margin-left: 0.75rem !important;
  }
  .ms-lg-16px {
    margin-left: 1rem !important;
  }
  .ms-lg-24px {
    margin-left: 1.5rem !important;
  }
  .ms-lg-32px {
    margin-left: 2rem !important;
  }
  .ms-lg-48px {
    margin-left: 3rem !important;
  }
  .ms-lg-64px {
    margin-left: 4rem !important;
  }
  .ms-lg-96px {
    margin-left: 6rem !important;
  }
  .ms-lg-128px {
    margin-left: 8rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n4px {
    margin: -0.25rem !important;
  }
  .m-lg-n8px {
    margin: -0.5rem !important;
  }
  .m-lg-n12px {
    margin: -0.75rem !important;
  }
  .m-lg-n16px {
    margin: -1rem !important;
  }
  .m-lg-n24px {
    margin: -1.5rem !important;
  }
  .m-lg-n32px {
    margin: -2rem !important;
  }
  .m-lg-n48px {
    margin: -3rem !important;
  }
  .m-lg-n64px {
    margin: -4rem !important;
  }
  .m-lg-n96px {
    margin: -6rem !important;
  }
  .m-lg-n128px {
    margin: -8rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n4px {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n8px {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n12px {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n16px {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n24px {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n32px {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n48px {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n64px {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n96px {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n128px {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n4px {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n8px {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n12px {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n16px {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n24px {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n32px {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n48px {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n64px {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n96px {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n128px {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n4px {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n8px {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n12px {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n16px {
    margin-top: -1rem !important;
  }
  .mt-lg-n24px {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n32px {
    margin-top: -2rem !important;
  }
  .mt-lg-n48px {
    margin-top: -3rem !important;
  }
  .mt-lg-n64px {
    margin-top: -4rem !important;
  }
  .mt-lg-n96px {
    margin-top: -6rem !important;
  }
  .mt-lg-n128px {
    margin-top: -8rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n4px {
    margin-right: -0.25rem !important;
  }
  .me-lg-n8px {
    margin-right: -0.5rem !important;
  }
  .me-lg-n12px {
    margin-right: -0.75rem !important;
  }
  .me-lg-n16px {
    margin-right: -1rem !important;
  }
  .me-lg-n24px {
    margin-right: -1.5rem !important;
  }
  .me-lg-n32px {
    margin-right: -2rem !important;
  }
  .me-lg-n48px {
    margin-right: -3rem !important;
  }
  .me-lg-n64px {
    margin-right: -4rem !important;
  }
  .me-lg-n96px {
    margin-right: -6rem !important;
  }
  .me-lg-n128px {
    margin-right: -8rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n4px {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n8px {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n12px {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n16px {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n24px {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n32px {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n48px {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n64px {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n96px {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n128px {
    margin-bottom: -8rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n4px {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n8px {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n12px {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n16px {
    margin-left: -1rem !important;
  }
  .ms-lg-n24px {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n32px {
    margin-left: -2rem !important;
  }
  .ms-lg-n48px {
    margin-left: -3rem !important;
  }
  .ms-lg-n64px {
    margin-left: -4rem !important;
  }
  .ms-lg-n96px {
    margin-left: -6rem !important;
  }
  .ms-lg-n128px {
    margin-left: -8rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-4px {
    padding: 0.25rem !important;
  }
  .p-lg-8px {
    padding: 0.5rem !important;
  }
  .p-lg-12px {
    padding: 0.75rem !important;
  }
  .p-lg-16px {
    padding: 1rem !important;
  }
  .p-lg-24px {
    padding: 1.5rem !important;
  }
  .p-lg-32px {
    padding: 2rem !important;
  }
  .p-lg-48px {
    padding: 3rem !important;
  }
  .p-lg-64px {
    padding: 4rem !important;
  }
  .p-lg-96px {
    padding: 6rem !important;
  }
  .p-lg-128px {
    padding: 8rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-4px {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-8px {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-12px {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-16px {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-24px {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-32px {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-48px {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-64px {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-96px {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-128px {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-4px {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-8px {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-12px {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-16px {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-24px {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-32px {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-48px {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-64px {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-96px {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-128px {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-4px {
    padding-top: 0.25rem !important;
  }
  .pt-lg-8px {
    padding-top: 0.5rem !important;
  }
  .pt-lg-12px {
    padding-top: 0.75rem !important;
  }
  .pt-lg-16px {
    padding-top: 1rem !important;
  }
  .pt-lg-24px {
    padding-top: 1.5rem !important;
  }
  .pt-lg-32px {
    padding-top: 2rem !important;
  }
  .pt-lg-48px {
    padding-top: 3rem !important;
  }
  .pt-lg-64px {
    padding-top: 4rem !important;
  }
  .pt-lg-96px {
    padding-top: 6rem !important;
  }
  .pt-lg-128px {
    padding-top: 8rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-4px {
    padding-right: 0.25rem !important;
  }
  .pe-lg-8px {
    padding-right: 0.5rem !important;
  }
  .pe-lg-12px {
    padding-right: 0.75rem !important;
  }
  .pe-lg-16px {
    padding-right: 1rem !important;
  }
  .pe-lg-24px {
    padding-right: 1.5rem !important;
  }
  .pe-lg-32px {
    padding-right: 2rem !important;
  }
  .pe-lg-48px {
    padding-right: 3rem !important;
  }
  .pe-lg-64px {
    padding-right: 4rem !important;
  }
  .pe-lg-96px {
    padding-right: 6rem !important;
  }
  .pe-lg-128px {
    padding-right: 8rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-4px {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-8px {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-12px {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-16px {
    padding-bottom: 1rem !important;
  }
  .pb-lg-24px {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-32px {
    padding-bottom: 2rem !important;
  }
  .pb-lg-48px {
    padding-bottom: 3rem !important;
  }
  .pb-lg-64px {
    padding-bottom: 4rem !important;
  }
  .pb-lg-96px {
    padding-bottom: 6rem !important;
  }
  .pb-lg-128px {
    padding-bottom: 8rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-4px {
    padding-left: 0.25rem !important;
  }
  .ps-lg-8px {
    padding-left: 0.5rem !important;
  }
  .ps-lg-12px {
    padding-left: 0.75rem !important;
  }
  .ps-lg-16px {
    padding-left: 1rem !important;
  }
  .ps-lg-24px {
    padding-left: 1.5rem !important;
  }
  .ps-lg-32px {
    padding-left: 2rem !important;
  }
  .ps-lg-48px {
    padding-left: 3rem !important;
  }
  .ps-lg-64px {
    padding-left: 4rem !important;
  }
  .ps-lg-96px {
    padding-left: 6rem !important;
  }
  .ps-lg-128px {
    padding-left: 8rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-4px {
    gap: 0.25rem !important;
  }
  .gap-lg-8px {
    gap: 0.5rem !important;
  }
  .gap-lg-12px {
    gap: 0.75rem !important;
  }
  .gap-lg-16px {
    gap: 1rem !important;
  }
  .gap-lg-24px {
    gap: 1.5rem !important;
  }
  .gap-lg-32px {
    gap: 2rem !important;
  }
  .gap-lg-48px {
    gap: 3rem !important;
  }
  .gap-lg-64px {
    gap: 4rem !important;
  }
  .gap-lg-96px {
    gap: 6rem !important;
  }
  .gap-lg-128px {
    gap: 8rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-4px {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-8px {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-12px {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-16px {
    row-gap: 1rem !important;
  }
  .row-gap-lg-24px {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-32px {
    row-gap: 2rem !important;
  }
  .row-gap-lg-48px {
    row-gap: 3rem !important;
  }
  .row-gap-lg-64px {
    row-gap: 4rem !important;
  }
  .row-gap-lg-96px {
    row-gap: 6rem !important;
  }
  .row-gap-lg-128px {
    row-gap: 8rem !important;
  }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-lg-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-lg-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-lg-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-lg-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-lg-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-lg-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-4px {
    margin: 0.25rem !important;
  }
  .m-xl-8px {
    margin: 0.5rem !important;
  }
  .m-xl-12px {
    margin: 0.75rem !important;
  }
  .m-xl-16px {
    margin: 1rem !important;
  }
  .m-xl-24px {
    margin: 1.5rem !important;
  }
  .m-xl-32px {
    margin: 2rem !important;
  }
  .m-xl-48px {
    margin: 3rem !important;
  }
  .m-xl-64px {
    margin: 4rem !important;
  }
  .m-xl-96px {
    margin: 6rem !important;
  }
  .m-xl-128px {
    margin: 8rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-4px {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-8px {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-12px {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-16px {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-24px {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-32px {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-48px {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-64px {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-96px {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-128px {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-4px {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-8px {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-12px {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-16px {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-24px {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-32px {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-48px {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-64px {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-96px {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-128px {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-4px {
    margin-top: 0.25rem !important;
  }
  .mt-xl-8px {
    margin-top: 0.5rem !important;
  }
  .mt-xl-12px {
    margin-top: 0.75rem !important;
  }
  .mt-xl-16px {
    margin-top: 1rem !important;
  }
  .mt-xl-24px {
    margin-top: 1.5rem !important;
  }
  .mt-xl-32px {
    margin-top: 2rem !important;
  }
  .mt-xl-48px {
    margin-top: 3rem !important;
  }
  .mt-xl-64px {
    margin-top: 4rem !important;
  }
  .mt-xl-96px {
    margin-top: 6rem !important;
  }
  .mt-xl-128px {
    margin-top: 8rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-4px {
    margin-right: 0.25rem !important;
  }
  .me-xl-8px {
    margin-right: 0.5rem !important;
  }
  .me-xl-12px {
    margin-right: 0.75rem !important;
  }
  .me-xl-16px {
    margin-right: 1rem !important;
  }
  .me-xl-24px {
    margin-right: 1.5rem !important;
  }
  .me-xl-32px {
    margin-right: 2rem !important;
  }
  .me-xl-48px {
    margin-right: 3rem !important;
  }
  .me-xl-64px {
    margin-right: 4rem !important;
  }
  .me-xl-96px {
    margin-right: 6rem !important;
  }
  .me-xl-128px {
    margin-right: 8rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-4px {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-8px {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-12px {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-16px {
    margin-bottom: 1rem !important;
  }
  .mb-xl-24px {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-32px {
    margin-bottom: 2rem !important;
  }
  .mb-xl-48px {
    margin-bottom: 3rem !important;
  }
  .mb-xl-64px {
    margin-bottom: 4rem !important;
  }
  .mb-xl-96px {
    margin-bottom: 6rem !important;
  }
  .mb-xl-128px {
    margin-bottom: 8rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-4px {
    margin-left: 0.25rem !important;
  }
  .ms-xl-8px {
    margin-left: 0.5rem !important;
  }
  .ms-xl-12px {
    margin-left: 0.75rem !important;
  }
  .ms-xl-16px {
    margin-left: 1rem !important;
  }
  .ms-xl-24px {
    margin-left: 1.5rem !important;
  }
  .ms-xl-32px {
    margin-left: 2rem !important;
  }
  .ms-xl-48px {
    margin-left: 3rem !important;
  }
  .ms-xl-64px {
    margin-left: 4rem !important;
  }
  .ms-xl-96px {
    margin-left: 6rem !important;
  }
  .ms-xl-128px {
    margin-left: 8rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n4px {
    margin: -0.25rem !important;
  }
  .m-xl-n8px {
    margin: -0.5rem !important;
  }
  .m-xl-n12px {
    margin: -0.75rem !important;
  }
  .m-xl-n16px {
    margin: -1rem !important;
  }
  .m-xl-n24px {
    margin: -1.5rem !important;
  }
  .m-xl-n32px {
    margin: -2rem !important;
  }
  .m-xl-n48px {
    margin: -3rem !important;
  }
  .m-xl-n64px {
    margin: -4rem !important;
  }
  .m-xl-n96px {
    margin: -6rem !important;
  }
  .m-xl-n128px {
    margin: -8rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n4px {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n8px {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n12px {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n16px {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n24px {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n32px {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n48px {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n64px {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n96px {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n128px {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n4px {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n8px {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n12px {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n16px {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n24px {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n32px {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n48px {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n64px {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n96px {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n128px {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n4px {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n8px {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n12px {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n16px {
    margin-top: -1rem !important;
  }
  .mt-xl-n24px {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n32px {
    margin-top: -2rem !important;
  }
  .mt-xl-n48px {
    margin-top: -3rem !important;
  }
  .mt-xl-n64px {
    margin-top: -4rem !important;
  }
  .mt-xl-n96px {
    margin-top: -6rem !important;
  }
  .mt-xl-n128px {
    margin-top: -8rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n4px {
    margin-right: -0.25rem !important;
  }
  .me-xl-n8px {
    margin-right: -0.5rem !important;
  }
  .me-xl-n12px {
    margin-right: -0.75rem !important;
  }
  .me-xl-n16px {
    margin-right: -1rem !important;
  }
  .me-xl-n24px {
    margin-right: -1.5rem !important;
  }
  .me-xl-n32px {
    margin-right: -2rem !important;
  }
  .me-xl-n48px {
    margin-right: -3rem !important;
  }
  .me-xl-n64px {
    margin-right: -4rem !important;
  }
  .me-xl-n96px {
    margin-right: -6rem !important;
  }
  .me-xl-n128px {
    margin-right: -8rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n4px {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n8px {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n12px {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n16px {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n24px {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n32px {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n48px {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n64px {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n96px {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n128px {
    margin-bottom: -8rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n4px {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n8px {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n12px {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n16px {
    margin-left: -1rem !important;
  }
  .ms-xl-n24px {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n32px {
    margin-left: -2rem !important;
  }
  .ms-xl-n48px {
    margin-left: -3rem !important;
  }
  .ms-xl-n64px {
    margin-left: -4rem !important;
  }
  .ms-xl-n96px {
    margin-left: -6rem !important;
  }
  .ms-xl-n128px {
    margin-left: -8rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-4px {
    padding: 0.25rem !important;
  }
  .p-xl-8px {
    padding: 0.5rem !important;
  }
  .p-xl-12px {
    padding: 0.75rem !important;
  }
  .p-xl-16px {
    padding: 1rem !important;
  }
  .p-xl-24px {
    padding: 1.5rem !important;
  }
  .p-xl-32px {
    padding: 2rem !important;
  }
  .p-xl-48px {
    padding: 3rem !important;
  }
  .p-xl-64px {
    padding: 4rem !important;
  }
  .p-xl-96px {
    padding: 6rem !important;
  }
  .p-xl-128px {
    padding: 8rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-4px {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-8px {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-12px {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-16px {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-24px {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-32px {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-48px {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-64px {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-96px {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-128px {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-4px {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-8px {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-12px {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-16px {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-24px {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-32px {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-48px {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-64px {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-96px {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-128px {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-4px {
    padding-top: 0.25rem !important;
  }
  .pt-xl-8px {
    padding-top: 0.5rem !important;
  }
  .pt-xl-12px {
    padding-top: 0.75rem !important;
  }
  .pt-xl-16px {
    padding-top: 1rem !important;
  }
  .pt-xl-24px {
    padding-top: 1.5rem !important;
  }
  .pt-xl-32px {
    padding-top: 2rem !important;
  }
  .pt-xl-48px {
    padding-top: 3rem !important;
  }
  .pt-xl-64px {
    padding-top: 4rem !important;
  }
  .pt-xl-96px {
    padding-top: 6rem !important;
  }
  .pt-xl-128px {
    padding-top: 8rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-4px {
    padding-right: 0.25rem !important;
  }
  .pe-xl-8px {
    padding-right: 0.5rem !important;
  }
  .pe-xl-12px {
    padding-right: 0.75rem !important;
  }
  .pe-xl-16px {
    padding-right: 1rem !important;
  }
  .pe-xl-24px {
    padding-right: 1.5rem !important;
  }
  .pe-xl-32px {
    padding-right: 2rem !important;
  }
  .pe-xl-48px {
    padding-right: 3rem !important;
  }
  .pe-xl-64px {
    padding-right: 4rem !important;
  }
  .pe-xl-96px {
    padding-right: 6rem !important;
  }
  .pe-xl-128px {
    padding-right: 8rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-4px {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-8px {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-12px {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-16px {
    padding-bottom: 1rem !important;
  }
  .pb-xl-24px {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-32px {
    padding-bottom: 2rem !important;
  }
  .pb-xl-48px {
    padding-bottom: 3rem !important;
  }
  .pb-xl-64px {
    padding-bottom: 4rem !important;
  }
  .pb-xl-96px {
    padding-bottom: 6rem !important;
  }
  .pb-xl-128px {
    padding-bottom: 8rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-4px {
    padding-left: 0.25rem !important;
  }
  .ps-xl-8px {
    padding-left: 0.5rem !important;
  }
  .ps-xl-12px {
    padding-left: 0.75rem !important;
  }
  .ps-xl-16px {
    padding-left: 1rem !important;
  }
  .ps-xl-24px {
    padding-left: 1.5rem !important;
  }
  .ps-xl-32px {
    padding-left: 2rem !important;
  }
  .ps-xl-48px {
    padding-left: 3rem !important;
  }
  .ps-xl-64px {
    padding-left: 4rem !important;
  }
  .ps-xl-96px {
    padding-left: 6rem !important;
  }
  .ps-xl-128px {
    padding-left: 8rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-4px {
    gap: 0.25rem !important;
  }
  .gap-xl-8px {
    gap: 0.5rem !important;
  }
  .gap-xl-12px {
    gap: 0.75rem !important;
  }
  .gap-xl-16px {
    gap: 1rem !important;
  }
  .gap-xl-24px {
    gap: 1.5rem !important;
  }
  .gap-xl-32px {
    gap: 2rem !important;
  }
  .gap-xl-48px {
    gap: 3rem !important;
  }
  .gap-xl-64px {
    gap: 4rem !important;
  }
  .gap-xl-96px {
    gap: 6rem !important;
  }
  .gap-xl-128px {
    gap: 8rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-4px {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-8px {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-12px {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-16px {
    row-gap: 1rem !important;
  }
  .row-gap-xl-24px {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-32px {
    row-gap: 2rem !important;
  }
  .row-gap-xl-48px {
    row-gap: 3rem !important;
  }
  .row-gap-xl-64px {
    row-gap: 4rem !important;
  }
  .row-gap-xl-96px {
    row-gap: 6rem !important;
  }
  .row-gap-xl-128px {
    row-gap: 8rem !important;
  }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-xl-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-xl-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-xl-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-xl-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-xl-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-xl-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-4px {
    margin: 0.25rem !important;
  }
  .m-xxl-8px {
    margin: 0.5rem !important;
  }
  .m-xxl-12px {
    margin: 0.75rem !important;
  }
  .m-xxl-16px {
    margin: 1rem !important;
  }
  .m-xxl-24px {
    margin: 1.5rem !important;
  }
  .m-xxl-32px {
    margin: 2rem !important;
  }
  .m-xxl-48px {
    margin: 3rem !important;
  }
  .m-xxl-64px {
    margin: 4rem !important;
  }
  .m-xxl-96px {
    margin: 6rem !important;
  }
  .m-xxl-128px {
    margin: 8rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-4px {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-8px {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-12px {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-16px {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-24px {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-32px {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-48px {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-64px {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-96px {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-128px {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-4px {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-8px {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-12px {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-16px {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-24px {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-32px {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-48px {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-64px {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-96px {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-128px {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-4px {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-8px {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-12px {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-16px {
    margin-top: 1rem !important;
  }
  .mt-xxl-24px {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-32px {
    margin-top: 2rem !important;
  }
  .mt-xxl-48px {
    margin-top: 3rem !important;
  }
  .mt-xxl-64px {
    margin-top: 4rem !important;
  }
  .mt-xxl-96px {
    margin-top: 6rem !important;
  }
  .mt-xxl-128px {
    margin-top: 8rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-4px {
    margin-right: 0.25rem !important;
  }
  .me-xxl-8px {
    margin-right: 0.5rem !important;
  }
  .me-xxl-12px {
    margin-right: 0.75rem !important;
  }
  .me-xxl-16px {
    margin-right: 1rem !important;
  }
  .me-xxl-24px {
    margin-right: 1.5rem !important;
  }
  .me-xxl-32px {
    margin-right: 2rem !important;
  }
  .me-xxl-48px {
    margin-right: 3rem !important;
  }
  .me-xxl-64px {
    margin-right: 4rem !important;
  }
  .me-xxl-96px {
    margin-right: 6rem !important;
  }
  .me-xxl-128px {
    margin-right: 8rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-4px {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-8px {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-12px {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-16px {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-24px {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-32px {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-48px {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-64px {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-96px {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-128px {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-4px {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-8px {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-12px {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-16px {
    margin-left: 1rem !important;
  }
  .ms-xxl-24px {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-32px {
    margin-left: 2rem !important;
  }
  .ms-xxl-48px {
    margin-left: 3rem !important;
  }
  .ms-xxl-64px {
    margin-left: 4rem !important;
  }
  .ms-xxl-96px {
    margin-left: 6rem !important;
  }
  .ms-xxl-128px {
    margin-left: 8rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n4px {
    margin: -0.25rem !important;
  }
  .m-xxl-n8px {
    margin: -0.5rem !important;
  }
  .m-xxl-n12px {
    margin: -0.75rem !important;
  }
  .m-xxl-n16px {
    margin: -1rem !important;
  }
  .m-xxl-n24px {
    margin: -1.5rem !important;
  }
  .m-xxl-n32px {
    margin: -2rem !important;
  }
  .m-xxl-n48px {
    margin: -3rem !important;
  }
  .m-xxl-n64px {
    margin: -4rem !important;
  }
  .m-xxl-n96px {
    margin: -6rem !important;
  }
  .m-xxl-n128px {
    margin: -8rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n4px {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n8px {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n12px {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xxl-n16px {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n24px {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n32px {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n48px {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n64px {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n96px {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n128px {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n4px {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n8px {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n12px {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xxl-n16px {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n24px {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n32px {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n48px {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n64px {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n96px {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n128px {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n4px {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n8px {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n12px {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n16px {
    margin-top: -1rem !important;
  }
  .mt-xxl-n24px {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n32px {
    margin-top: -2rem !important;
  }
  .mt-xxl-n48px {
    margin-top: -3rem !important;
  }
  .mt-xxl-n64px {
    margin-top: -4rem !important;
  }
  .mt-xxl-n96px {
    margin-top: -6rem !important;
  }
  .mt-xxl-n128px {
    margin-top: -8rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n4px {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n8px {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n12px {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n16px {
    margin-right: -1rem !important;
  }
  .me-xxl-n24px {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n32px {
    margin-right: -2rem !important;
  }
  .me-xxl-n48px {
    margin-right: -3rem !important;
  }
  .me-xxl-n64px {
    margin-right: -4rem !important;
  }
  .me-xxl-n96px {
    margin-right: -6rem !important;
  }
  .me-xxl-n128px {
    margin-right: -8rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n4px {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n8px {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n12px {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n16px {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n24px {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n32px {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n48px {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n64px {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n96px {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n128px {
    margin-bottom: -8rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n4px {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n8px {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n12px {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n16px {
    margin-left: -1rem !important;
  }
  .ms-xxl-n24px {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n32px {
    margin-left: -2rem !important;
  }
  .ms-xxl-n48px {
    margin-left: -3rem !important;
  }
  .ms-xxl-n64px {
    margin-left: -4rem !important;
  }
  .ms-xxl-n96px {
    margin-left: -6rem !important;
  }
  .ms-xxl-n128px {
    margin-left: -8rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-4px {
    padding: 0.25rem !important;
  }
  .p-xxl-8px {
    padding: 0.5rem !important;
  }
  .p-xxl-12px {
    padding: 0.75rem !important;
  }
  .p-xxl-16px {
    padding: 1rem !important;
  }
  .p-xxl-24px {
    padding: 1.5rem !important;
  }
  .p-xxl-32px {
    padding: 2rem !important;
  }
  .p-xxl-48px {
    padding: 3rem !important;
  }
  .p-xxl-64px {
    padding: 4rem !important;
  }
  .p-xxl-96px {
    padding: 6rem !important;
  }
  .p-xxl-128px {
    padding: 8rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-4px {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-8px {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-12px {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-16px {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-24px {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-32px {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-48px {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-64px {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-96px {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-128px {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-4px {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-8px {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-12px {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-16px {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-24px {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-32px {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-48px {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-64px {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-96px {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-128px {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-4px {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-8px {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-12px {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-16px {
    padding-top: 1rem !important;
  }
  .pt-xxl-24px {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-32px {
    padding-top: 2rem !important;
  }
  .pt-xxl-48px {
    padding-top: 3rem !important;
  }
  .pt-xxl-64px {
    padding-top: 4rem !important;
  }
  .pt-xxl-96px {
    padding-top: 6rem !important;
  }
  .pt-xxl-128px {
    padding-top: 8rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-4px {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-8px {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-12px {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-16px {
    padding-right: 1rem !important;
  }
  .pe-xxl-24px {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-32px {
    padding-right: 2rem !important;
  }
  .pe-xxl-48px {
    padding-right: 3rem !important;
  }
  .pe-xxl-64px {
    padding-right: 4rem !important;
  }
  .pe-xxl-96px {
    padding-right: 6rem !important;
  }
  .pe-xxl-128px {
    padding-right: 8rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-4px {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-8px {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-12px {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-16px {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-24px {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-32px {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-48px {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-64px {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-96px {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-128px {
    padding-bottom: 8rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-4px {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-8px {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-12px {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-16px {
    padding-left: 1rem !important;
  }
  .ps-xxl-24px {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-32px {
    padding-left: 2rem !important;
  }
  .ps-xxl-48px {
    padding-left: 3rem !important;
  }
  .ps-xxl-64px {
    padding-left: 4rem !important;
  }
  .ps-xxl-96px {
    padding-left: 6rem !important;
  }
  .ps-xxl-128px {
    padding-left: 8rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-4px {
    gap: 0.25rem !important;
  }
  .gap-xxl-8px {
    gap: 0.5rem !important;
  }
  .gap-xxl-12px {
    gap: 0.75rem !important;
  }
  .gap-xxl-16px {
    gap: 1rem !important;
  }
  .gap-xxl-24px {
    gap: 1.5rem !important;
  }
  .gap-xxl-32px {
    gap: 2rem !important;
  }
  .gap-xxl-48px {
    gap: 3rem !important;
  }
  .gap-xxl-64px {
    gap: 4rem !important;
  }
  .gap-xxl-96px {
    gap: 6rem !important;
  }
  .gap-xxl-128px {
    gap: 8rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-4px {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-8px {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-12px {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-16px {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-24px {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-32px {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-48px {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-64px {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-96px {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-128px {
    row-gap: 8rem !important;
  }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-xxl-4px {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-8px {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-12px {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-16px {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-xxl-24px {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-32px {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-xxl-48px {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-xxl-64px {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-xxl-96px {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-xxl-128px {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-2 {
    font-size: 1.3125rem !important;
  }
  .fs-1 {
    font-size: 1.5rem !important;
  }
  .fs-21 {
    font-size: 1.3125rem !important;
  }
  .fs-24 {
    font-size: 1.5rem !important;
  }
  .fs-26 {
    font-size: 1.625rem !important;
  }
  .fs-30 {
    font-size: 1.875rem !important;
  }
  .fs-36 {
    font-size: 2.25rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-n0 {
  margin-left: -0 !important;
}

.mr-n0 {
  margin-right: -0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.ml-n1 {
  margin-left: -0.25rem !important;
}

.mr-n1 {
  margin-right: -0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-n2 {
  margin-left: -0.5rem !important;
}

.mr-n2 {
  margin-right: -0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.ml-n3 {
  margin-left: -1rem !important;
}

.mr-n3 {
  margin-right: -1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.ml-n4 {
  margin-left: -1.5rem !important;
}

.mr-n4 {
  margin-right: -1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.ml-n5 {
  margin-left: -3rem !important;
}

.mr-n5 {
  margin-right: -3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.ml-4px {
  margin-left: 0.25rem !important;
}

.mr-4px {
  margin-right: 0.25rem !important;
}

.ml-n4px {
  margin-left: -0.25rem !important;
}

.mr-n4px {
  margin-right: -0.25rem !important;
}

.pl-4px {
  padding-left: 0.25rem !important;
}

.pr-4px {
  padding-right: 0.25rem !important;
}

.ml-8px {
  margin-left: 0.5rem !important;
}

.mr-8px {
  margin-right: 0.5rem !important;
}

.ml-n8px {
  margin-left: -0.5rem !important;
}

.mr-n8px {
  margin-right: -0.5rem !important;
}

.pl-8px {
  padding-left: 0.5rem !important;
}

.pr-8px {
  padding-right: 0.5rem !important;
}

.ml-12px {
  margin-left: 0.75rem !important;
}

.mr-12px {
  margin-right: 0.75rem !important;
}

.ml-n12px {
  margin-left: -0.75rem !important;
}

.mr-n12px {
  margin-right: -0.75rem !important;
}

.pl-12px {
  padding-left: 0.75rem !important;
}

.pr-12px {
  padding-right: 0.75rem !important;
}

.ml-16px {
  margin-left: 1rem !important;
}

.mr-16px {
  margin-right: 1rem !important;
}

.ml-n16px {
  margin-left: -1rem !important;
}

.mr-n16px {
  margin-right: -1rem !important;
}

.pl-16px {
  padding-left: 1rem !important;
}

.pr-16px {
  padding-right: 1rem !important;
}

.ml-24px {
  margin-left: 1.5rem !important;
}

.mr-24px {
  margin-right: 1.5rem !important;
}

.ml-n24px {
  margin-left: -1.5rem !important;
}

.mr-n24px {
  margin-right: -1.5rem !important;
}

.pl-24px {
  padding-left: 1.5rem !important;
}

.pr-24px {
  padding-right: 1.5rem !important;
}

.ml-32px {
  margin-left: 2rem !important;
}

.mr-32px {
  margin-right: 2rem !important;
}

.ml-n32px {
  margin-left: -2rem !important;
}

.mr-n32px {
  margin-right: -2rem !important;
}

.pl-32px {
  padding-left: 2rem !important;
}

.pr-32px {
  padding-right: 2rem !important;
}

.ml-48px {
  margin-left: 3rem !important;
}

.mr-48px {
  margin-right: 3rem !important;
}

.ml-n48px {
  margin-left: -3rem !important;
}

.mr-n48px {
  margin-right: -3rem !important;
}

.pl-48px {
  padding-left: 3rem !important;
}

.pr-48px {
  padding-right: 3rem !important;
}

.ml-64px {
  margin-left: 4rem !important;
}

.mr-64px {
  margin-right: 4rem !important;
}

.ml-n64px {
  margin-left: -4rem !important;
}

.mr-n64px {
  margin-right: -4rem !important;
}

.pl-64px {
  padding-left: 4rem !important;
}

.pr-64px {
  padding-right: 4rem !important;
}

.ml-96px {
  margin-left: 6rem !important;
}

.mr-96px {
  margin-right: 6rem !important;
}

.ml-n96px {
  margin-left: -6rem !important;
}

.mr-n96px {
  margin-right: -6rem !important;
}

.pl-96px {
  padding-left: 6rem !important;
}

.pr-96px {
  padding-right: 6rem !important;
}

.ml-128px {
  margin-left: 8rem !important;
}

.mr-128px {
  margin-right: 8rem !important;
}

.ml-n128px {
  margin-left: -8rem !important;
}

.mr-n128px {
  margin-right: -8rem !important;
}

.pl-128px {
  padding-left: 8rem !important;
}

.pr-128px {
  padding-right: 8rem !important;
}

@media (min-width: 480px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .ml-sm-n0 {
    margin-left: -0 !important;
  }
  .mr-sm-n0 {
    margin-right: -0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  .ml-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .mr-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  .ml-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .mr-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
  .ml-sm-n3 {
    margin-left: -1rem !important;
  }
  .mr-sm-n3 {
    margin-right: -1rem !important;
  }
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  .ml-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .mr-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  .ml-sm-5 {
    margin-left: 3rem !important;
  }
  .mr-sm-5 {
    margin-right: 3rem !important;
  }
  .ml-sm-n5 {
    margin-left: -3rem !important;
  }
  .mr-sm-n5 {
    margin-right: -3rem !important;
  }
  .pl-sm-5 {
    padding-left: 3rem !important;
  }
  .pr-sm-5 {
    padding-right: 3rem !important;
  }
  .ml-sm-4px {
    margin-left: 0.25rem !important;
  }
  .mr-sm-4px {
    margin-right: 0.25rem !important;
  }
  .ml-sm-n4px {
    margin-left: -0.25rem !important;
  }
  .mr-sm-n4px {
    margin-right: -0.25rem !important;
  }
  .pl-sm-4px {
    padding-left: 0.25rem !important;
  }
  .pr-sm-4px {
    padding-right: 0.25rem !important;
  }
  .ml-sm-8px {
    margin-left: 0.5rem !important;
  }
  .mr-sm-8px {
    margin-right: 0.5rem !important;
  }
  .ml-sm-n8px {
    margin-left: -0.5rem !important;
  }
  .mr-sm-n8px {
    margin-right: -0.5rem !important;
  }
  .pl-sm-8px {
    padding-left: 0.5rem !important;
  }
  .pr-sm-8px {
    padding-right: 0.5rem !important;
  }
  .ml-sm-12px {
    margin-left: 0.75rem !important;
  }
  .mr-sm-12px {
    margin-right: 0.75rem !important;
  }
  .ml-sm-n12px {
    margin-left: -0.75rem !important;
  }
  .mr-sm-n12px {
    margin-right: -0.75rem !important;
  }
  .pl-sm-12px {
    padding-left: 0.75rem !important;
  }
  .pr-sm-12px {
    padding-right: 0.75rem !important;
  }
  .ml-sm-16px {
    margin-left: 1rem !important;
  }
  .mr-sm-16px {
    margin-right: 1rem !important;
  }
  .ml-sm-n16px {
    margin-left: -1rem !important;
  }
  .mr-sm-n16px {
    margin-right: -1rem !important;
  }
  .pl-sm-16px {
    padding-left: 1rem !important;
  }
  .pr-sm-16px {
    padding-right: 1rem !important;
  }
  .ml-sm-24px {
    margin-left: 1.5rem !important;
  }
  .mr-sm-24px {
    margin-right: 1.5rem !important;
  }
  .ml-sm-n24px {
    margin-left: -1.5rem !important;
  }
  .mr-sm-n24px {
    margin-right: -1.5rem !important;
  }
  .pl-sm-24px {
    padding-left: 1.5rem !important;
  }
  .pr-sm-24px {
    padding-right: 1.5rem !important;
  }
  .ml-sm-32px {
    margin-left: 2rem !important;
  }
  .mr-sm-32px {
    margin-right: 2rem !important;
  }
  .ml-sm-n32px {
    margin-left: -2rem !important;
  }
  .mr-sm-n32px {
    margin-right: -2rem !important;
  }
  .pl-sm-32px {
    padding-left: 2rem !important;
  }
  .pr-sm-32px {
    padding-right: 2rem !important;
  }
  .ml-sm-48px {
    margin-left: 3rem !important;
  }
  .mr-sm-48px {
    margin-right: 3rem !important;
  }
  .ml-sm-n48px {
    margin-left: -3rem !important;
  }
  .mr-sm-n48px {
    margin-right: -3rem !important;
  }
  .pl-sm-48px {
    padding-left: 3rem !important;
  }
  .pr-sm-48px {
    padding-right: 3rem !important;
  }
  .ml-sm-64px {
    margin-left: 4rem !important;
  }
  .mr-sm-64px {
    margin-right: 4rem !important;
  }
  .ml-sm-n64px {
    margin-left: -4rem !important;
  }
  .mr-sm-n64px {
    margin-right: -4rem !important;
  }
  .pl-sm-64px {
    padding-left: 4rem !important;
  }
  .pr-sm-64px {
    padding-right: 4rem !important;
  }
  .ml-sm-96px {
    margin-left: 6rem !important;
  }
  .mr-sm-96px {
    margin-right: 6rem !important;
  }
  .ml-sm-n96px {
    margin-left: -6rem !important;
  }
  .mr-sm-n96px {
    margin-right: -6rem !important;
  }
  .pl-sm-96px {
    padding-left: 6rem !important;
  }
  .pr-sm-96px {
    padding-right: 6rem !important;
  }
  .ml-sm-128px {
    margin-left: 8rem !important;
  }
  .mr-sm-128px {
    margin-right: 8rem !important;
  }
  .ml-sm-n128px {
    margin-left: -8rem !important;
  }
  .mr-sm-n128px {
    margin-right: -8rem !important;
  }
  .pl-sm-128px {
    padding-left: 8rem !important;
  }
  .pr-sm-128px {
    padding-right: 8rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .ml-md-n0 {
    margin-left: -0 !important;
  }
  .mr-md-n0 {
    margin-right: -0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
  .ml-md-n1 {
    margin-left: -0.25rem !important;
  }
  .mr-md-n1 {
    margin-right: -0.25rem !important;
  }
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  .ml-md-n2 {
    margin-left: -0.5rem !important;
  }
  .mr-md-n2 {
    margin-right: -0.5rem !important;
  }
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  .ml-md-3 {
    margin-left: 1rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .ml-md-n3 {
    margin-left: -1rem !important;
  }
  .mr-md-n3 {
    margin-right: -1rem !important;
  }
  .pl-md-3 {
    padding-left: 1rem !important;
  }
  .pr-md-3 {
    padding-right: 1rem !important;
  }
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
  .ml-md-n4 {
    margin-left: -1.5rem !important;
  }
  .mr-md-n4 {
    margin-right: -1.5rem !important;
  }
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
  .ml-md-5 {
    margin-left: 3rem !important;
  }
  .mr-md-5 {
    margin-right: 3rem !important;
  }
  .ml-md-n5 {
    margin-left: -3rem !important;
  }
  .mr-md-n5 {
    margin-right: -3rem !important;
  }
  .pl-md-5 {
    padding-left: 3rem !important;
  }
  .pr-md-5 {
    padding-right: 3rem !important;
  }
  .ml-md-4px {
    margin-left: 0.25rem !important;
  }
  .mr-md-4px {
    margin-right: 0.25rem !important;
  }
  .ml-md-n4px {
    margin-left: -0.25rem !important;
  }
  .mr-md-n4px {
    margin-right: -0.25rem !important;
  }
  .pl-md-4px {
    padding-left: 0.25rem !important;
  }
  .pr-md-4px {
    padding-right: 0.25rem !important;
  }
  .ml-md-8px {
    margin-left: 0.5rem !important;
  }
  .mr-md-8px {
    margin-right: 0.5rem !important;
  }
  .ml-md-n8px {
    margin-left: -0.5rem !important;
  }
  .mr-md-n8px {
    margin-right: -0.5rem !important;
  }
  .pl-md-8px {
    padding-left: 0.5rem !important;
  }
  .pr-md-8px {
    padding-right: 0.5rem !important;
  }
  .ml-md-12px {
    margin-left: 0.75rem !important;
  }
  .mr-md-12px {
    margin-right: 0.75rem !important;
  }
  .ml-md-n12px {
    margin-left: -0.75rem !important;
  }
  .mr-md-n12px {
    margin-right: -0.75rem !important;
  }
  .pl-md-12px {
    padding-left: 0.75rem !important;
  }
  .pr-md-12px {
    padding-right: 0.75rem !important;
  }
  .ml-md-16px {
    margin-left: 1rem !important;
  }
  .mr-md-16px {
    margin-right: 1rem !important;
  }
  .ml-md-n16px {
    margin-left: -1rem !important;
  }
  .mr-md-n16px {
    margin-right: -1rem !important;
  }
  .pl-md-16px {
    padding-left: 1rem !important;
  }
  .pr-md-16px {
    padding-right: 1rem !important;
  }
  .ml-md-24px {
    margin-left: 1.5rem !important;
  }
  .mr-md-24px {
    margin-right: 1.5rem !important;
  }
  .ml-md-n24px {
    margin-left: -1.5rem !important;
  }
  .mr-md-n24px {
    margin-right: -1.5rem !important;
  }
  .pl-md-24px {
    padding-left: 1.5rem !important;
  }
  .pr-md-24px {
    padding-right: 1.5rem !important;
  }
  .ml-md-32px {
    margin-left: 2rem !important;
  }
  .mr-md-32px {
    margin-right: 2rem !important;
  }
  .ml-md-n32px {
    margin-left: -2rem !important;
  }
  .mr-md-n32px {
    margin-right: -2rem !important;
  }
  .pl-md-32px {
    padding-left: 2rem !important;
  }
  .pr-md-32px {
    padding-right: 2rem !important;
  }
  .ml-md-48px {
    margin-left: 3rem !important;
  }
  .mr-md-48px {
    margin-right: 3rem !important;
  }
  .ml-md-n48px {
    margin-left: -3rem !important;
  }
  .mr-md-n48px {
    margin-right: -3rem !important;
  }
  .pl-md-48px {
    padding-left: 3rem !important;
  }
  .pr-md-48px {
    padding-right: 3rem !important;
  }
  .ml-md-64px {
    margin-left: 4rem !important;
  }
  .mr-md-64px {
    margin-right: 4rem !important;
  }
  .ml-md-n64px {
    margin-left: -4rem !important;
  }
  .mr-md-n64px {
    margin-right: -4rem !important;
  }
  .pl-md-64px {
    padding-left: 4rem !important;
  }
  .pr-md-64px {
    padding-right: 4rem !important;
  }
  .ml-md-96px {
    margin-left: 6rem !important;
  }
  .mr-md-96px {
    margin-right: 6rem !important;
  }
  .ml-md-n96px {
    margin-left: -6rem !important;
  }
  .mr-md-n96px {
    margin-right: -6rem !important;
  }
  .pl-md-96px {
    padding-left: 6rem !important;
  }
  .pr-md-96px {
    padding-right: 6rem !important;
  }
  .ml-md-128px {
    margin-left: 8rem !important;
  }
  .mr-md-128px {
    margin-right: 8rem !important;
  }
  .ml-md-n128px {
    margin-left: -8rem !important;
  }
  .mr-md-n128px {
    margin-right: -8rem !important;
  }
  .pl-md-128px {
    padding-left: 8rem !important;
  }
  .pr-md-128px {
    padding-right: 8rem !important;
  }
}
@media (min-width: 1024px) {
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .ml-lg-n0 {
    margin-left: -0 !important;
  }
  .mr-lg-n0 {
    margin-right: -0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  .ml-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .mr-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  .ml-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .mr-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  .ml-lg-3 {
    margin-left: 1rem !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .ml-lg-n3 {
    margin-left: -1rem !important;
  }
  .mr-lg-n3 {
    margin-right: -1rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  .pr-lg-3 {
    padding-right: 1rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }
  .ml-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .mr-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  .ml-lg-5 {
    margin-left: 3rem !important;
  }
  .mr-lg-5 {
    margin-right: 3rem !important;
  }
  .ml-lg-n5 {
    margin-left: -3rem !important;
  }
  .mr-lg-n5 {
    margin-right: -3rem !important;
  }
  .pl-lg-5 {
    padding-left: 3rem !important;
  }
  .pr-lg-5 {
    padding-right: 3rem !important;
  }
  .ml-lg-4px {
    margin-left: 0.25rem !important;
  }
  .mr-lg-4px {
    margin-right: 0.25rem !important;
  }
  .ml-lg-n4px {
    margin-left: -0.25rem !important;
  }
  .mr-lg-n4px {
    margin-right: -0.25rem !important;
  }
  .pl-lg-4px {
    padding-left: 0.25rem !important;
  }
  .pr-lg-4px {
    padding-right: 0.25rem !important;
  }
  .ml-lg-8px {
    margin-left: 0.5rem !important;
  }
  .mr-lg-8px {
    margin-right: 0.5rem !important;
  }
  .ml-lg-n8px {
    margin-left: -0.5rem !important;
  }
  .mr-lg-n8px {
    margin-right: -0.5rem !important;
  }
  .pl-lg-8px {
    padding-left: 0.5rem !important;
  }
  .pr-lg-8px {
    padding-right: 0.5rem !important;
  }
  .ml-lg-12px {
    margin-left: 0.75rem !important;
  }
  .mr-lg-12px {
    margin-right: 0.75rem !important;
  }
  .ml-lg-n12px {
    margin-left: -0.75rem !important;
  }
  .mr-lg-n12px {
    margin-right: -0.75rem !important;
  }
  .pl-lg-12px {
    padding-left: 0.75rem !important;
  }
  .pr-lg-12px {
    padding-right: 0.75rem !important;
  }
  .ml-lg-16px {
    margin-left: 1rem !important;
  }
  .mr-lg-16px {
    margin-right: 1rem !important;
  }
  .ml-lg-n16px {
    margin-left: -1rem !important;
  }
  .mr-lg-n16px {
    margin-right: -1rem !important;
  }
  .pl-lg-16px {
    padding-left: 1rem !important;
  }
  .pr-lg-16px {
    padding-right: 1rem !important;
  }
  .ml-lg-24px {
    margin-left: 1.5rem !important;
  }
  .mr-lg-24px {
    margin-right: 1.5rem !important;
  }
  .ml-lg-n24px {
    margin-left: -1.5rem !important;
  }
  .mr-lg-n24px {
    margin-right: -1.5rem !important;
  }
  .pl-lg-24px {
    padding-left: 1.5rem !important;
  }
  .pr-lg-24px {
    padding-right: 1.5rem !important;
  }
  .ml-lg-32px {
    margin-left: 2rem !important;
  }
  .mr-lg-32px {
    margin-right: 2rem !important;
  }
  .ml-lg-n32px {
    margin-left: -2rem !important;
  }
  .mr-lg-n32px {
    margin-right: -2rem !important;
  }
  .pl-lg-32px {
    padding-left: 2rem !important;
  }
  .pr-lg-32px {
    padding-right: 2rem !important;
  }
  .ml-lg-48px {
    margin-left: 3rem !important;
  }
  .mr-lg-48px {
    margin-right: 3rem !important;
  }
  .ml-lg-n48px {
    margin-left: -3rem !important;
  }
  .mr-lg-n48px {
    margin-right: -3rem !important;
  }
  .pl-lg-48px {
    padding-left: 3rem !important;
  }
  .pr-lg-48px {
    padding-right: 3rem !important;
  }
  .ml-lg-64px {
    margin-left: 4rem !important;
  }
  .mr-lg-64px {
    margin-right: 4rem !important;
  }
  .ml-lg-n64px {
    margin-left: -4rem !important;
  }
  .mr-lg-n64px {
    margin-right: -4rem !important;
  }
  .pl-lg-64px {
    padding-left: 4rem !important;
  }
  .pr-lg-64px {
    padding-right: 4rem !important;
  }
  .ml-lg-96px {
    margin-left: 6rem !important;
  }
  .mr-lg-96px {
    margin-right: 6rem !important;
  }
  .ml-lg-n96px {
    margin-left: -6rem !important;
  }
  .mr-lg-n96px {
    margin-right: -6rem !important;
  }
  .pl-lg-96px {
    padding-left: 6rem !important;
  }
  .pr-lg-96px {
    padding-right: 6rem !important;
  }
  .ml-lg-128px {
    margin-left: 8rem !important;
  }
  .mr-lg-128px {
    margin-right: 8rem !important;
  }
  .ml-lg-n128px {
    margin-left: -8rem !important;
  }
  .mr-lg-n128px {
    margin-right: -8rem !important;
  }
  .pl-lg-128px {
    padding-left: 8rem !important;
  }
  .pr-lg-128px {
    padding-right: 8rem !important;
  }
}
@media (min-width: 1440px) {
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .ml-xl-n0 {
    margin-left: -0 !important;
  }
  .mr-xl-n0 {
    margin-right: -0 !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }
  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }
  .ml-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .mr-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }
  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }
  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }
  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  .ml-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .mr-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }
  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }
  .ml-xl-3 {
    margin-left: 1rem !important;
  }
  .mr-xl-3 {
    margin-right: 1rem !important;
  }
  .ml-xl-n3 {
    margin-left: -1rem !important;
  }
  .mr-xl-n3 {
    margin-right: -1rem !important;
  }
  .pl-xl-3 {
    padding-left: 1rem !important;
  }
  .pr-xl-3 {
    padding-right: 1rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }
  .mr-xl-4 {
    margin-right: 1.5rem !important;
  }
  .ml-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .mr-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .pl-xl-4 {
    padding-left: 1.5rem !important;
  }
  .pr-xl-4 {
    padding-right: 1.5rem !important;
  }
  .ml-xl-5 {
    margin-left: 3rem !important;
  }
  .mr-xl-5 {
    margin-right: 3rem !important;
  }
  .ml-xl-n5 {
    margin-left: -3rem !important;
  }
  .mr-xl-n5 {
    margin-right: -3rem !important;
  }
  .pl-xl-5 {
    padding-left: 3rem !important;
  }
  .pr-xl-5 {
    padding-right: 3rem !important;
  }
  .ml-xl-4px {
    margin-left: 0.25rem !important;
  }
  .mr-xl-4px {
    margin-right: 0.25rem !important;
  }
  .ml-xl-n4px {
    margin-left: -0.25rem !important;
  }
  .mr-xl-n4px {
    margin-right: -0.25rem !important;
  }
  .pl-xl-4px {
    padding-left: 0.25rem !important;
  }
  .pr-xl-4px {
    padding-right: 0.25rem !important;
  }
  .ml-xl-8px {
    margin-left: 0.5rem !important;
  }
  .mr-xl-8px {
    margin-right: 0.5rem !important;
  }
  .ml-xl-n8px {
    margin-left: -0.5rem !important;
  }
  .mr-xl-n8px {
    margin-right: -0.5rem !important;
  }
  .pl-xl-8px {
    padding-left: 0.5rem !important;
  }
  .pr-xl-8px {
    padding-right: 0.5rem !important;
  }
  .ml-xl-12px {
    margin-left: 0.75rem !important;
  }
  .mr-xl-12px {
    margin-right: 0.75rem !important;
  }
  .ml-xl-n12px {
    margin-left: -0.75rem !important;
  }
  .mr-xl-n12px {
    margin-right: -0.75rem !important;
  }
  .pl-xl-12px {
    padding-left: 0.75rem !important;
  }
  .pr-xl-12px {
    padding-right: 0.75rem !important;
  }
  .ml-xl-16px {
    margin-left: 1rem !important;
  }
  .mr-xl-16px {
    margin-right: 1rem !important;
  }
  .ml-xl-n16px {
    margin-left: -1rem !important;
  }
  .mr-xl-n16px {
    margin-right: -1rem !important;
  }
  .pl-xl-16px {
    padding-left: 1rem !important;
  }
  .pr-xl-16px {
    padding-right: 1rem !important;
  }
  .ml-xl-24px {
    margin-left: 1.5rem !important;
  }
  .mr-xl-24px {
    margin-right: 1.5rem !important;
  }
  .ml-xl-n24px {
    margin-left: -1.5rem !important;
  }
  .mr-xl-n24px {
    margin-right: -1.5rem !important;
  }
  .pl-xl-24px {
    padding-left: 1.5rem !important;
  }
  .pr-xl-24px {
    padding-right: 1.5rem !important;
  }
  .ml-xl-32px {
    margin-left: 2rem !important;
  }
  .mr-xl-32px {
    margin-right: 2rem !important;
  }
  .ml-xl-n32px {
    margin-left: -2rem !important;
  }
  .mr-xl-n32px {
    margin-right: -2rem !important;
  }
  .pl-xl-32px {
    padding-left: 2rem !important;
  }
  .pr-xl-32px {
    padding-right: 2rem !important;
  }
  .ml-xl-48px {
    margin-left: 3rem !important;
  }
  .mr-xl-48px {
    margin-right: 3rem !important;
  }
  .ml-xl-n48px {
    margin-left: -3rem !important;
  }
  .mr-xl-n48px {
    margin-right: -3rem !important;
  }
  .pl-xl-48px {
    padding-left: 3rem !important;
  }
  .pr-xl-48px {
    padding-right: 3rem !important;
  }
  .ml-xl-64px {
    margin-left: 4rem !important;
  }
  .mr-xl-64px {
    margin-right: 4rem !important;
  }
  .ml-xl-n64px {
    margin-left: -4rem !important;
  }
  .mr-xl-n64px {
    margin-right: -4rem !important;
  }
  .pl-xl-64px {
    padding-left: 4rem !important;
  }
  .pr-xl-64px {
    padding-right: 4rem !important;
  }
  .ml-xl-96px {
    margin-left: 6rem !important;
  }
  .mr-xl-96px {
    margin-right: 6rem !important;
  }
  .ml-xl-n96px {
    margin-left: -6rem !important;
  }
  .mr-xl-n96px {
    margin-right: -6rem !important;
  }
  .pl-xl-96px {
    padding-left: 6rem !important;
  }
  .pr-xl-96px {
    padding-right: 6rem !important;
  }
  .ml-xl-128px {
    margin-left: 8rem !important;
  }
  .mr-xl-128px {
    margin-right: 8rem !important;
  }
  .ml-xl-n128px {
    margin-left: -8rem !important;
  }
  .mr-xl-n128px {
    margin-right: -8rem !important;
  }
  .pl-xl-128px {
    padding-left: 8rem !important;
  }
  .pr-xl-128px {
    padding-right: 8rem !important;
  }
}
@media (min-width: 1920px) {
  .ml-xxl-0 {
    margin-left: 0 !important;
  }
  .mr-xxl-0 {
    margin-right: 0 !important;
  }
  .ml-xxl-n0 {
    margin-left: -0 !important;
  }
  .mr-xxl-n0 {
    margin-right: -0 !important;
  }
  .pl-xxl-0 {
    padding-left: 0 !important;
  }
  .pr-xxl-0 {
    padding-right: 0 !important;
  }
  .ml-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .mr-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .ml-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .mr-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .pl-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .pr-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .ml-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .mr-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .ml-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .mr-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .pl-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .pr-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .ml-xxl-3 {
    margin-left: 1rem !important;
  }
  .mr-xxl-3 {
    margin-right: 1rem !important;
  }
  .ml-xxl-n3 {
    margin-left: -1rem !important;
  }
  .mr-xxl-n3 {
    margin-right: -1rem !important;
  }
  .pl-xxl-3 {
    padding-left: 1rem !important;
  }
  .pr-xxl-3 {
    padding-right: 1rem !important;
  }
  .ml-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .mr-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .ml-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .mr-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .pl-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .pr-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .ml-xxl-5 {
    margin-left: 3rem !important;
  }
  .mr-xxl-5 {
    margin-right: 3rem !important;
  }
  .ml-xxl-n5 {
    margin-left: -3rem !important;
  }
  .mr-xxl-n5 {
    margin-right: -3rem !important;
  }
  .pl-xxl-5 {
    padding-left: 3rem !important;
  }
  .pr-xxl-5 {
    padding-right: 3rem !important;
  }
  .ml-xxl-4px {
    margin-left: 0.25rem !important;
  }
  .mr-xxl-4px {
    margin-right: 0.25rem !important;
  }
  .ml-xxl-n4px {
    margin-left: -0.25rem !important;
  }
  .mr-xxl-n4px {
    margin-right: -0.25rem !important;
  }
  .pl-xxl-4px {
    padding-left: 0.25rem !important;
  }
  .pr-xxl-4px {
    padding-right: 0.25rem !important;
  }
  .ml-xxl-8px {
    margin-left: 0.5rem !important;
  }
  .mr-xxl-8px {
    margin-right: 0.5rem !important;
  }
  .ml-xxl-n8px {
    margin-left: -0.5rem !important;
  }
  .mr-xxl-n8px {
    margin-right: -0.5rem !important;
  }
  .pl-xxl-8px {
    padding-left: 0.5rem !important;
  }
  .pr-xxl-8px {
    padding-right: 0.5rem !important;
  }
  .ml-xxl-12px {
    margin-left: 0.75rem !important;
  }
  .mr-xxl-12px {
    margin-right: 0.75rem !important;
  }
  .ml-xxl-n12px {
    margin-left: -0.75rem !important;
  }
  .mr-xxl-n12px {
    margin-right: -0.75rem !important;
  }
  .pl-xxl-12px {
    padding-left: 0.75rem !important;
  }
  .pr-xxl-12px {
    padding-right: 0.75rem !important;
  }
  .ml-xxl-16px {
    margin-left: 1rem !important;
  }
  .mr-xxl-16px {
    margin-right: 1rem !important;
  }
  .ml-xxl-n16px {
    margin-left: -1rem !important;
  }
  .mr-xxl-n16px {
    margin-right: -1rem !important;
  }
  .pl-xxl-16px {
    padding-left: 1rem !important;
  }
  .pr-xxl-16px {
    padding-right: 1rem !important;
  }
  .ml-xxl-24px {
    margin-left: 1.5rem !important;
  }
  .mr-xxl-24px {
    margin-right: 1.5rem !important;
  }
  .ml-xxl-n24px {
    margin-left: -1.5rem !important;
  }
  .mr-xxl-n24px {
    margin-right: -1.5rem !important;
  }
  .pl-xxl-24px {
    padding-left: 1.5rem !important;
  }
  .pr-xxl-24px {
    padding-right: 1.5rem !important;
  }
  .ml-xxl-32px {
    margin-left: 2rem !important;
  }
  .mr-xxl-32px {
    margin-right: 2rem !important;
  }
  .ml-xxl-n32px {
    margin-left: -2rem !important;
  }
  .mr-xxl-n32px {
    margin-right: -2rem !important;
  }
  .pl-xxl-32px {
    padding-left: 2rem !important;
  }
  .pr-xxl-32px {
    padding-right: 2rem !important;
  }
  .ml-xxl-48px {
    margin-left: 3rem !important;
  }
  .mr-xxl-48px {
    margin-right: 3rem !important;
  }
  .ml-xxl-n48px {
    margin-left: -3rem !important;
  }
  .mr-xxl-n48px {
    margin-right: -3rem !important;
  }
  .pl-xxl-48px {
    padding-left: 3rem !important;
  }
  .pr-xxl-48px {
    padding-right: 3rem !important;
  }
  .ml-xxl-64px {
    margin-left: 4rem !important;
  }
  .mr-xxl-64px {
    margin-right: 4rem !important;
  }
  .ml-xxl-n64px {
    margin-left: -4rem !important;
  }
  .mr-xxl-n64px {
    margin-right: -4rem !important;
  }
  .pl-xxl-64px {
    padding-left: 4rem !important;
  }
  .pr-xxl-64px {
    padding-right: 4rem !important;
  }
  .ml-xxl-96px {
    margin-left: 6rem !important;
  }
  .mr-xxl-96px {
    margin-right: 6rem !important;
  }
  .ml-xxl-n96px {
    margin-left: -6rem !important;
  }
  .mr-xxl-n96px {
    margin-right: -6rem !important;
  }
  .pl-xxl-96px {
    padding-left: 6rem !important;
  }
  .pr-xxl-96px {
    padding-right: 6rem !important;
  }
  .ml-xxl-128px {
    margin-left: 8rem !important;
  }
  .mr-xxl-128px {
    margin-right: 8rem !important;
  }
  .ml-xxl-n128px {
    margin-left: -8rem !important;
  }
  .mr-xxl-n128px {
    margin-right: -8rem !important;
  }
  .pl-xxl-128px {
    padding-left: 8rem !important;
  }
  .pr-xxl-128px {
    padding-right: 8rem !important;
  }
}
.text-small {
  font-size: 14px !important;
}

.text-large {
  font-size: 16px !important;
}

.text-xlarge {
  font-size: 18px !important;
}

.text-xxlarge {
  font-size: 21px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 8px !important;
}

p {
  margin-bottom: 1rem !important;
}

label {
  color: #6e777a;
  font-weight: 500;
  margin-bottom: 8px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

select.form-control {
  background-repeat: no-repeat;
  background-position: right 0.625rem center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%236e777a'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
  background-size: 1.125rem;
}
select.form-control:hover:not([disabled]):not(.disabled), select.form-control:focus:not([disabled]):not(.disabled) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='primary'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
}
select.form-control-sm {
  min-height: 1.25rem;
  background-size: 1rem;
}
select.form-control-lg {
  min-height: 2.5rem;
  background-size: 1.375rem;
}
select.form-control-xl {
  min-height: 3rem;
  background-size: 1.5rem;
}

.badge {
  padding-left: 0.375rem !important;
}
.badge::before {
  display: none;
}
.badge.badge-sm {
  padding-left: 0.375rem !important;
}
.badge.badge-lg {
  padding-left: 0.625rem !important;
}
.badge.badge-xl {
  padding-left: 0.8125rem !important;
}

.badge-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: 800 !important;
}

.font-weight-extrabold {
  font-weight: 800 !important;
}

.font-weight-extrabolder {
  font-weight: 800 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.bg-primary-alpha-xxl {
  background: rgba(0, 0, 0, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-primary-alpha-xxl {
  border-color: rgba(0, 0, 0, 0.7) !important;
}

.text-primary-alpha-xxl {
  color: rgba(0, 0, 0, 0.7) !important;
}

.bg-secondary-alpha-xxl {
  background: rgba(110, 119, 122, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-secondary-alpha-xxl {
  border-color: rgba(110, 119, 122, 0.7) !important;
}

.text-secondary-alpha-xxl {
  color: rgba(110, 119, 122, 0.7) !important;
}

.bg-success-alpha-xxl {
  background: rgba(0, 170, 12, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-success-alpha-xxl {
  border-color: rgba(0, 170, 12, 0.7) !important;
}

.text-success-alpha-xxl {
  color: rgba(0, 170, 12, 0.7) !important;
}

.bg-info-alpha-xxl {
  background: rgba(36, 105, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-info-alpha-xxl {
  border-color: rgba(36, 105, 255, 0.7) !important;
}

.text-info-alpha-xxl {
  color: rgba(36, 105, 255, 0.7) !important;
}

.bg-warning-alpha-xxl {
  background: rgba(218, 116, 0, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-warning-alpha-xxl {
  border-color: rgba(218, 116, 0, 0.7) !important;
}

.text-warning-alpha-xxl {
  color: rgba(218, 116, 0, 0.7) !important;
}

.bg-danger-alpha-xxl {
  background: rgba(221, 55, 36, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-danger-alpha-xxl {
  border-color: rgba(221, 55, 36, 0.7) !important;
}

.text-danger-alpha-xxl {
  color: rgba(221, 55, 36, 0.7) !important;
}

.bg-light-alpha-xxl {
  background: rgba(196, 200, 202, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-light-alpha-xxl {
  border-color: rgba(196, 200, 202, 0.7) !important;
}

.text-light-alpha-xxl {
  color: rgba(196, 200, 202, 0.7) !important;
}

.bg-dark-alpha-xxl {
  background: rgba(65, 73, 73, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-dark-alpha-xxl {
  border-color: rgba(65, 73, 73, 0.7) !important;
}

.text-dark-alpha-xxl {
  color: rgba(65, 73, 73, 0.7) !important;
}

.bg-white-alpha-xxl {
  background: rgba(255, 255, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-white-alpha-xxl {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

.text-white-alpha-xxl {
  color: rgba(255, 255, 255, 0.7) !important;
}

.bg-black-alpha-xxl {
  background: rgba(35, 31, 32, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-black-alpha-xxl {
  border-color: rgba(35, 31, 32, 0.7) !important;
}

.text-black-alpha-xxl {
  color: rgba(35, 31, 32, 0.7) !important;
}

.bg-socgen-alpha-xxl {
  background: rgba(230, 0, 40, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-socgen-alpha-xxl {
  border-color: rgba(230, 0, 40, 0.7) !important;
}

.text-socgen-alpha-xxl {
  color: rgba(230, 0, 40, 0.7) !important;
}

.bg-primary-alt-alpha-xxl {
  background: rgba(255, 255, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-primary-alt-alpha-xxl {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

.text-primary-alt-alpha-xxl {
  color: rgba(255, 255, 255, 0.7) !important;
}

.bg-primary-alpha-xl {
  background: rgba(0, 0, 0, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-primary-alpha-xl {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.text-primary-alpha-xl {
  color: rgba(0, 0, 0, 0.4) !important;
}

.bg-secondary-alpha-xl {
  background: rgba(110, 119, 122, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-secondary-alpha-xl {
  border-color: rgba(110, 119, 122, 0.4) !important;
}

.text-secondary-alpha-xl {
  color: rgba(110, 119, 122, 0.4) !important;
}

.bg-success-alpha-xl {
  background: rgba(0, 170, 12, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-success-alpha-xl {
  border-color: rgba(0, 170, 12, 0.4) !important;
}

.text-success-alpha-xl {
  color: rgba(0, 170, 12, 0.4) !important;
}

.bg-info-alpha-xl {
  background: rgba(36, 105, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-info-alpha-xl {
  border-color: rgba(36, 105, 255, 0.4) !important;
}

.text-info-alpha-xl {
  color: rgba(36, 105, 255, 0.4) !important;
}

.bg-warning-alpha-xl {
  background: rgba(218, 116, 0, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-warning-alpha-xl {
  border-color: rgba(218, 116, 0, 0.4) !important;
}

.text-warning-alpha-xl {
  color: rgba(218, 116, 0, 0.4) !important;
}

.bg-danger-alpha-xl {
  background: rgba(221, 55, 36, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-danger-alpha-xl {
  border-color: rgba(221, 55, 36, 0.4) !important;
}

.text-danger-alpha-xl {
  color: rgba(221, 55, 36, 0.4) !important;
}

.bg-light-alpha-xl {
  background: rgba(196, 200, 202, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-light-alpha-xl {
  border-color: rgba(196, 200, 202, 0.4) !important;
}

.text-light-alpha-xl {
  color: rgba(196, 200, 202, 0.4) !important;
}

.bg-dark-alpha-xl {
  background: rgba(65, 73, 73, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-dark-alpha-xl {
  border-color: rgba(65, 73, 73, 0.4) !important;
}

.text-dark-alpha-xl {
  color: rgba(65, 73, 73, 0.4) !important;
}

.bg-white-alpha-xl {
  background: rgba(255, 255, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-white-alpha-xl {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.text-white-alpha-xl {
  color: rgba(255, 255, 255, 0.4) !important;
}

.bg-black-alpha-xl {
  background: rgba(35, 31, 32, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-black-alpha-xl {
  border-color: rgba(35, 31, 32, 0.4) !important;
}

.text-black-alpha-xl {
  color: rgba(35, 31, 32, 0.4) !important;
}

.bg-socgen-alpha-xl {
  background: rgba(230, 0, 40, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-socgen-alpha-xl {
  border-color: rgba(230, 0, 40, 0.4) !important;
}

.text-socgen-alpha-xl {
  color: rgba(230, 0, 40, 0.4) !important;
}

.bg-primary-alt-alpha-xl {
  background: rgba(255, 255, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-primary-alt-alpha-xl {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.text-primary-alt-alpha-xl {
  color: rgba(255, 255, 255, 0.4) !important;
}

.bg-primary-alpha-lg {
  background: rgba(0, 0, 0, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-primary-alpha-lg {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.text-primary-alpha-lg {
  color: rgba(0, 0, 0, 0.2) !important;
}

.bg-secondary-alpha-lg {
  background: rgba(110, 119, 122, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-secondary-alpha-lg {
  border-color: rgba(110, 119, 122, 0.2) !important;
}

.text-secondary-alpha-lg {
  color: rgba(110, 119, 122, 0.2) !important;
}

.bg-success-alpha-lg {
  background: rgba(0, 170, 12, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-success-alpha-lg {
  border-color: rgba(0, 170, 12, 0.2) !important;
}

.text-success-alpha-lg {
  color: rgba(0, 170, 12, 0.2) !important;
}

.bg-info-alpha-lg {
  background: rgba(36, 105, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-info-alpha-lg {
  border-color: rgba(36, 105, 255, 0.2) !important;
}

.text-info-alpha-lg {
  color: rgba(36, 105, 255, 0.2) !important;
}

.bg-warning-alpha-lg {
  background: rgba(218, 116, 0, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-warning-alpha-lg {
  border-color: rgba(218, 116, 0, 0.2) !important;
}

.text-warning-alpha-lg {
  color: rgba(218, 116, 0, 0.2) !important;
}

.bg-danger-alpha-lg {
  background: rgba(221, 55, 36, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-danger-alpha-lg {
  border-color: rgba(221, 55, 36, 0.2) !important;
}

.text-danger-alpha-lg {
  color: rgba(221, 55, 36, 0.2) !important;
}

.bg-light-alpha-lg {
  background: rgba(196, 200, 202, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-light-alpha-lg {
  border-color: rgba(196, 200, 202, 0.2) !important;
}

.text-light-alpha-lg {
  color: rgba(196, 200, 202, 0.2) !important;
}

.bg-dark-alpha-lg {
  background: rgba(65, 73, 73, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-dark-alpha-lg {
  border-color: rgba(65, 73, 73, 0.2) !important;
}

.text-dark-alpha-lg {
  color: rgba(65, 73, 73, 0.2) !important;
}

.bg-white-alpha-lg {
  background: rgba(255, 255, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-white-alpha-lg {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.text-white-alpha-lg {
  color: rgba(255, 255, 255, 0.2) !important;
}

.bg-black-alpha-lg {
  background: rgba(35, 31, 32, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-black-alpha-lg {
  border-color: rgba(35, 31, 32, 0.2) !important;
}

.text-black-alpha-lg {
  color: rgba(35, 31, 32, 0.2) !important;
}

.bg-socgen-alpha-lg {
  background: rgba(230, 0, 40, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-socgen-alpha-lg {
  border-color: rgba(230, 0, 40, 0.2) !important;
}

.text-socgen-alpha-lg {
  color: rgba(230, 0, 40, 0.2) !important;
}

.bg-primary-alt-alpha-lg {
  background: rgba(255, 255, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-primary-alt-alpha-lg {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.text-primary-alt-alpha-lg {
  color: rgba(255, 255, 255, 0.2) !important;
}

.bg-primary-alpha-md {
  background: rgba(0, 0, 0, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-primary-alpha-md {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.text-primary-alpha-md {
  color: rgba(0, 0, 0, 0.1) !important;
}

.bg-secondary-alpha-md {
  background: rgba(110, 119, 122, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-secondary-alpha-md {
  border-color: rgba(110, 119, 122, 0.1) !important;
}

.text-secondary-alpha-md {
  color: rgba(110, 119, 122, 0.1) !important;
}

.bg-success-alpha-md {
  background: rgba(0, 170, 12, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-success-alpha-md {
  border-color: rgba(0, 170, 12, 0.1) !important;
}

.text-success-alpha-md {
  color: rgba(0, 170, 12, 0.1) !important;
}

.bg-info-alpha-md {
  background: rgba(36, 105, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-info-alpha-md {
  border-color: rgba(36, 105, 255, 0.1) !important;
}

.text-info-alpha-md {
  color: rgba(36, 105, 255, 0.1) !important;
}

.bg-warning-alpha-md {
  background: rgba(218, 116, 0, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-warning-alpha-md {
  border-color: rgba(218, 116, 0, 0.1) !important;
}

.text-warning-alpha-md {
  color: rgba(218, 116, 0, 0.1) !important;
}

.bg-danger-alpha-md {
  background: rgba(221, 55, 36, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-danger-alpha-md {
  border-color: rgba(221, 55, 36, 0.1) !important;
}

.text-danger-alpha-md {
  color: rgba(221, 55, 36, 0.1) !important;
}

.bg-light-alpha-md {
  background: rgba(196, 200, 202, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-light-alpha-md {
  border-color: rgba(196, 200, 202, 0.1) !important;
}

.text-light-alpha-md {
  color: rgba(196, 200, 202, 0.1) !important;
}

.bg-dark-alpha-md {
  background: rgba(65, 73, 73, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-dark-alpha-md {
  border-color: rgba(65, 73, 73, 0.1) !important;
}

.text-dark-alpha-md {
  color: rgba(65, 73, 73, 0.1) !important;
}

.bg-white-alpha-md {
  background: rgba(255, 255, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-white-alpha-md {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.text-white-alpha-md {
  color: rgba(255, 255, 255, 0.1) !important;
}

.bg-black-alpha-md {
  background: rgba(35, 31, 32, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-black-alpha-md {
  border-color: rgba(35, 31, 32, 0.1) !important;
}

.text-black-alpha-md {
  color: rgba(35, 31, 32, 0.1) !important;
}

.bg-socgen-alpha-md {
  background: rgba(230, 0, 40, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-socgen-alpha-md {
  border-color: rgba(230, 0, 40, 0.1) !important;
}

.text-socgen-alpha-md {
  color: rgba(230, 0, 40, 0.1) !important;
}

.bg-primary-alt-alpha-md {
  background: rgba(255, 255, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-primary-alt-alpha-md {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.text-primary-alt-alpha-md {
  color: rgba(255, 255, 255, 0.1) !important;
}

.bg-primary-alpha-sm {
  background: rgba(0, 0, 0, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-primary-alpha-sm {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.text-primary-alpha-sm {
  color: rgba(0, 0, 0, 0.05) !important;
}

.bg-secondary-alpha-sm {
  background: rgba(110, 119, 122, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-secondary-alpha-sm {
  border-color: rgba(110, 119, 122, 0.05) !important;
}

.text-secondary-alpha-sm {
  color: rgba(110, 119, 122, 0.05) !important;
}

.bg-success-alpha-sm {
  background: rgba(0, 170, 12, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-success-alpha-sm {
  border-color: rgba(0, 170, 12, 0.05) !important;
}

.text-success-alpha-sm {
  color: rgba(0, 170, 12, 0.05) !important;
}

.bg-info-alpha-sm {
  background: rgba(36, 105, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-info-alpha-sm {
  border-color: rgba(36, 105, 255, 0.05) !important;
}

.text-info-alpha-sm {
  color: rgba(36, 105, 255, 0.05) !important;
}

.bg-warning-alpha-sm {
  background: rgba(218, 116, 0, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-warning-alpha-sm {
  border-color: rgba(218, 116, 0, 0.05) !important;
}

.text-warning-alpha-sm {
  color: rgba(218, 116, 0, 0.05) !important;
}

.bg-danger-alpha-sm {
  background: rgba(221, 55, 36, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-danger-alpha-sm {
  border-color: rgba(221, 55, 36, 0.05) !important;
}

.text-danger-alpha-sm {
  color: rgba(221, 55, 36, 0.05) !important;
}

.bg-light-alpha-sm {
  background: rgba(196, 200, 202, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-light-alpha-sm {
  border-color: rgba(196, 200, 202, 0.05) !important;
}

.text-light-alpha-sm {
  color: rgba(196, 200, 202, 0.05) !important;
}

.bg-dark-alpha-sm {
  background: rgba(65, 73, 73, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-dark-alpha-sm {
  border-color: rgba(65, 73, 73, 0.05) !important;
}

.text-dark-alpha-sm {
  color: rgba(65, 73, 73, 0.05) !important;
}

.bg-white-alpha-sm {
  background: rgba(255, 255, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-white-alpha-sm {
  border-color: rgba(255, 255, 255, 0.05) !important;
}

.text-white-alpha-sm {
  color: rgba(255, 255, 255, 0.05) !important;
}

.bg-black-alpha-sm {
  background: rgba(35, 31, 32, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-black-alpha-sm {
  border-color: rgba(35, 31, 32, 0.05) !important;
}

.text-black-alpha-sm {
  color: rgba(35, 31, 32, 0.05) !important;
}

.bg-socgen-alpha-sm {
  background: rgba(230, 0, 40, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-socgen-alpha-sm {
  border-color: rgba(230, 0, 40, 0.05) !important;
}

.text-socgen-alpha-sm {
  color: rgba(230, 0, 40, 0.05) !important;
}

.bg-primary-alt-alpha-sm {
  background: rgba(255, 255, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-primary-alt-alpha-sm {
  border-color: rgba(255, 255, 255, 0.05) !important;
}

.text-primary-alt-alpha-sm {
  color: rgba(255, 255, 255, 0.05) !important;
}

.bg-lvl1-alpha-xxl {
  background: rgba(255, 255, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-lvl1-alpha-xxl {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

.text-lvl1-alpha-xxl {
  color: rgba(255, 255, 255, 0.7) !important;
}

.bg-lvl2-alpha-xxl {
  background: rgba(245, 246, 247, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-lvl2-alpha-xxl {
  border-color: rgba(245, 246, 247, 0.7) !important;
}

.text-lvl2-alpha-xxl {
  color: rgba(245, 246, 247, 0.7) !important;
}

.bg-lvl3-alpha-xxl {
  background: rgba(236, 238, 239, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-lvl3-alpha-xxl {
  border-color: rgba(236, 238, 239, 0.7) !important;
}

.text-lvl3-alpha-xxl {
  color: rgba(236, 238, 239, 0.7) !important;
}

.bg-lvl4-alpha-xxl {
  background: rgba(227, 230, 231, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-lvl4-alpha-xxl {
  border-color: rgba(227, 230, 231, 0.7) !important;
}

.text-lvl4-alpha-xxl {
  color: rgba(227, 230, 231, 0.7) !important;
}

.bg-lvl1-alpha-xl {
  background: rgba(255, 255, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-lvl1-alpha-xl {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.text-lvl1-alpha-xl {
  color: rgba(255, 255, 255, 0.4) !important;
}

.bg-lvl2-alpha-xl {
  background: rgba(245, 246, 247, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-lvl2-alpha-xl {
  border-color: rgba(245, 246, 247, 0.4) !important;
}

.text-lvl2-alpha-xl {
  color: rgba(245, 246, 247, 0.4) !important;
}

.bg-lvl3-alpha-xl {
  background: rgba(236, 238, 239, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-lvl3-alpha-xl {
  border-color: rgba(236, 238, 239, 0.4) !important;
}

.text-lvl3-alpha-xl {
  color: rgba(236, 238, 239, 0.4) !important;
}

.bg-lvl4-alpha-xl {
  background: rgba(227, 230, 231, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-lvl4-alpha-xl {
  border-color: rgba(227, 230, 231, 0.4) !important;
}

.text-lvl4-alpha-xl {
  color: rgba(227, 230, 231, 0.4) !important;
}

.bg-lvl1-alpha-lg {
  background: rgba(255, 255, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-lvl1-alpha-lg {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.text-lvl1-alpha-lg {
  color: rgba(255, 255, 255, 0.2) !important;
}

.bg-lvl2-alpha-lg {
  background: rgba(245, 246, 247, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-lvl2-alpha-lg {
  border-color: rgba(245, 246, 247, 0.2) !important;
}

.text-lvl2-alpha-lg {
  color: rgba(245, 246, 247, 0.2) !important;
}

.bg-lvl3-alpha-lg {
  background: rgba(236, 238, 239, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-lvl3-alpha-lg {
  border-color: rgba(236, 238, 239, 0.2) !important;
}

.text-lvl3-alpha-lg {
  color: rgba(236, 238, 239, 0.2) !important;
}

.bg-lvl4-alpha-lg {
  background: rgba(227, 230, 231, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-lvl4-alpha-lg {
  border-color: rgba(227, 230, 231, 0.2) !important;
}

.text-lvl4-alpha-lg {
  color: rgba(227, 230, 231, 0.2) !important;
}

.bg-lvl1-alpha-md {
  background: rgba(255, 255, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-lvl1-alpha-md {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.text-lvl1-alpha-md {
  color: rgba(255, 255, 255, 0.1) !important;
}

.bg-lvl2-alpha-md {
  background: rgba(245, 246, 247, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-lvl2-alpha-md {
  border-color: rgba(245, 246, 247, 0.1) !important;
}

.text-lvl2-alpha-md {
  color: rgba(245, 246, 247, 0.1) !important;
}

.bg-lvl3-alpha-md {
  background: rgba(236, 238, 239, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-lvl3-alpha-md {
  border-color: rgba(236, 238, 239, 0.1) !important;
}

.text-lvl3-alpha-md {
  color: rgba(236, 238, 239, 0.1) !important;
}

.bg-lvl4-alpha-md {
  background: rgba(227, 230, 231, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-lvl4-alpha-md {
  border-color: rgba(227, 230, 231, 0.1) !important;
}

.text-lvl4-alpha-md {
  color: rgba(227, 230, 231, 0.1) !important;
}

.bg-lvl1-alpha-sm {
  background: rgba(255, 255, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-lvl1-alpha-sm {
  border-color: rgba(255, 255, 255, 0.05) !important;
}

.text-lvl1-alpha-sm {
  color: rgba(255, 255, 255, 0.05) !important;
}

.bg-lvl2-alpha-sm {
  background: rgba(245, 246, 247, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-lvl2-alpha-sm {
  border-color: rgba(245, 246, 247, 0.05) !important;
}

.text-lvl2-alpha-sm {
  color: rgba(245, 246, 247, 0.05) !important;
}

.bg-lvl3-alpha-sm {
  background: rgba(236, 238, 239, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-lvl3-alpha-sm {
  border-color: rgba(236, 238, 239, 0.05) !important;
}

.text-lvl3-alpha-sm {
  color: rgba(236, 238, 239, 0.05) !important;
}

.bg-lvl4-alpha-sm {
  background: rgba(227, 230, 231, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-lvl4-alpha-sm {
  border-color: rgba(227, 230, 231, 0.05) !important;
}

.text-lvl4-alpha-sm {
  color: rgba(227, 230, 231, 0.05) !important;
}

.bg-lvl1-alpha-xxl {
  background: rgba(48, 51, 51, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-lvl1-alpha-xxl {
  border-color: rgba(48, 51, 51, 0.7) !important;
}

.text-lvl1-alpha-xxl {
  color: rgba(48, 51, 51, 0.7) !important;
}

.bg-lvl2-alpha-xxl {
  background: rgba(34, 36, 36, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-lvl2-alpha-xxl {
  border-color: rgba(34, 36, 36, 0.7) !important;
}

.text-lvl2-alpha-xxl {
  color: rgba(34, 36, 36, 0.7) !important;
}

.bg-lvl3-alpha-xxl {
  background: rgba(21, 22, 22, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-lvl3-alpha-xxl {
  border-color: rgba(21, 22, 22, 0.7) !important;
}

.text-lvl3-alpha-xxl {
  color: rgba(21, 22, 22, 0.7) !important;
}

.bg-lvl4-alpha-xxl {
  background: rgba(0, 0, 0, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-lvl4-alpha-xxl {
  border-color: rgba(0, 0, 0, 0.7) !important;
}

.text-lvl4-alpha-xxl {
  color: rgba(0, 0, 0, 0.7) !important;
}

.bg-lvl1-alpha-xl {
  background: rgba(48, 51, 51, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-lvl1-alpha-xl {
  border-color: rgba(48, 51, 51, 0.4) !important;
}

.text-lvl1-alpha-xl {
  color: rgba(48, 51, 51, 0.4) !important;
}

.bg-lvl2-alpha-xl {
  background: rgba(34, 36, 36, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-lvl2-alpha-xl {
  border-color: rgba(34, 36, 36, 0.4) !important;
}

.text-lvl2-alpha-xl {
  color: rgba(34, 36, 36, 0.4) !important;
}

.bg-lvl3-alpha-xl {
  background: rgba(21, 22, 22, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-lvl3-alpha-xl {
  border-color: rgba(21, 22, 22, 0.4) !important;
}

.text-lvl3-alpha-xl {
  color: rgba(21, 22, 22, 0.4) !important;
}

.bg-lvl4-alpha-xl {
  background: rgba(0, 0, 0, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-lvl4-alpha-xl {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.text-lvl4-alpha-xl {
  color: rgba(0, 0, 0, 0.4) !important;
}

.bg-lvl1-alpha-lg {
  background: rgba(48, 51, 51, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-lvl1-alpha-lg {
  border-color: rgba(48, 51, 51, 0.2) !important;
}

.text-lvl1-alpha-lg {
  color: rgba(48, 51, 51, 0.2) !important;
}

.bg-lvl2-alpha-lg {
  background: rgba(34, 36, 36, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-lvl2-alpha-lg {
  border-color: rgba(34, 36, 36, 0.2) !important;
}

.text-lvl2-alpha-lg {
  color: rgba(34, 36, 36, 0.2) !important;
}

.bg-lvl3-alpha-lg {
  background: rgba(21, 22, 22, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-lvl3-alpha-lg {
  border-color: rgba(21, 22, 22, 0.2) !important;
}

.text-lvl3-alpha-lg {
  color: rgba(21, 22, 22, 0.2) !important;
}

.bg-lvl4-alpha-lg {
  background: rgba(0, 0, 0, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-lvl4-alpha-lg {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.text-lvl4-alpha-lg {
  color: rgba(0, 0, 0, 0.2) !important;
}

.bg-lvl1-alpha-md {
  background: rgba(48, 51, 51, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-lvl1-alpha-md {
  border-color: rgba(48, 51, 51, 0.1) !important;
}

.text-lvl1-alpha-md {
  color: rgba(48, 51, 51, 0.1) !important;
}

.bg-lvl2-alpha-md {
  background: rgba(34, 36, 36, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-lvl2-alpha-md {
  border-color: rgba(34, 36, 36, 0.1) !important;
}

.text-lvl2-alpha-md {
  color: rgba(34, 36, 36, 0.1) !important;
}

.bg-lvl3-alpha-md {
  background: rgba(21, 22, 22, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-lvl3-alpha-md {
  border-color: rgba(21, 22, 22, 0.1) !important;
}

.text-lvl3-alpha-md {
  color: rgba(21, 22, 22, 0.1) !important;
}

.bg-lvl4-alpha-md {
  background: rgba(0, 0, 0, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-lvl4-alpha-md {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.text-lvl4-alpha-md {
  color: rgba(0, 0, 0, 0.1) !important;
}

.bg-lvl1-alpha-sm {
  background: rgba(48, 51, 51, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-lvl1-alpha-sm {
  border-color: rgba(48, 51, 51, 0.05) !important;
}

.text-lvl1-alpha-sm {
  color: rgba(48, 51, 51, 0.05) !important;
}

.bg-lvl2-alpha-sm {
  background: rgba(34, 36, 36, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-lvl2-alpha-sm {
  border-color: rgba(34, 36, 36, 0.05) !important;
}

.text-lvl2-alpha-sm {
  color: rgba(34, 36, 36, 0.05) !important;
}

.bg-lvl3-alpha-sm {
  background: rgba(21, 22, 22, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-lvl3-alpha-sm {
  border-color: rgba(21, 22, 22, 0.05) !important;
}

.text-lvl3-alpha-sm {
  color: rgba(21, 22, 22, 0.05) !important;
}

.bg-lvl4-alpha-sm {
  background: rgba(0, 0, 0, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-lvl4-alpha-sm {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.text-lvl4-alpha-sm {
  color: rgba(0, 0, 0, 0.05) !important;
}

.bg-apricot-alpha-xxl {
  background: rgba(232, 142, 94, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-apricot-alpha-xxl {
  border-color: rgba(232, 142, 94, 0.7) !important;
}

.text-apricot-alpha-xxl {
  color: rgba(232, 142, 94, 0.7) !important;
}

.bg-azure-alpha-xxl {
  background: rgba(56, 105, 159, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-azure-alpha-xxl {
  border-color: rgba(56, 105, 159, 0.7) !important;
}

.text-azure-alpha-xxl {
  color: rgba(56, 105, 159, 0.7) !important;
}

.bg-buttercup-alpha-xxl {
  background: rgba(246, 164, 22, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-buttercup-alpha-xxl {
  border-color: rgba(246, 164, 22, 0.7) !important;
}

.text-buttercup-alpha-xxl {
  color: rgba(246, 164, 22, 0.7) !important;
}

.bg-copper-rust-alpha-xxl {
  background: rgba(151, 74, 90, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-copper-rust-alpha-xxl {
  border-color: rgba(151, 74, 90, 0.7) !important;
}

.text-copper-rust-alpha-xxl {
  color: rgba(151, 74, 90, 0.7) !important;
}

.bg-cornflower-blue-alpha-xxl {
  background: rgba(100, 136, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-cornflower-blue-alpha-xxl {
  border-color: rgba(100, 136, 255, 0.7) !important;
}

.text-cornflower-blue-alpha-xxl {
  color: rgba(100, 136, 255, 0.7) !important;
}

.bg-dodger-blue-alpha-xxl {
  background: rgba(93, 85, 255, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-dodger-blue-alpha-xxl {
  border-color: rgba(93, 85, 255, 0.7) !important;
}

.text-dodger-blue-alpha-xxl {
  color: rgba(93, 85, 255, 0.7) !important;
}

.bg-east-bay-alpha-xxl {
  background: rgba(74, 88, 133, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-east-bay-alpha-xxl {
  border-color: rgba(74, 88, 133, 0.7) !important;
}

.text-east-bay-alpha-xxl {
  color: rgba(74, 88, 133, 0.7) !important;
}

.bg-faded-jade-alpha-xxl {
  background: rgba(64, 129, 130, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-faded-jade-alpha-xxl {
  border-color: rgba(64, 129, 130, 0.7) !important;
}

.text-faded-jade-alpha-xxl {
  color: rgba(64, 129, 130, 0.7) !important;
}

.bg-heather-alpha-xxl {
  background: rgba(180, 187, 203, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-heather-alpha-xxl {
  border-color: rgba(180, 187, 203, 0.7) !important;
}

.text-heather-alpha-xxl {
  color: rgba(180, 187, 203, 0.7) !important;
}

.bg-mako-alpha-xxl {
  background: rgba(68, 72, 82, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-mako-alpha-xxl {
  border-color: rgba(68, 72, 82, 0.7) !important;
}

.text-mako-alpha-xxl {
  color: rgba(68, 72, 82, 0.7) !important;
}

.bg-manatee-alpha-xxl {
  background: rgba(139, 147, 168, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-manatee-alpha-xxl {
  border-color: rgba(139, 147, 168, 0.7) !important;
}

.text-manatee-alpha-xxl {
  color: rgba(139, 147, 168, 0.7) !important;
}

.bg-mars-alpha-xxl {
  background: rgba(213, 50, 41, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-mars-alpha-xxl {
  border-color: rgba(213, 50, 41, 0.7) !important;
}

.text-mars-alpha-xxl {
  color: rgba(213, 50, 41, 0.7) !important;
}

.bg-mercury-alpha-xxl {
  background: rgba(232, 232, 232, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-mercury-alpha-xxl {
  border-color: rgba(232, 232, 232, 0.7) !important;
}

.text-mercury-alpha-xxl {
  color: rgba(232, 232, 232, 0.7) !important;
}

.bg-pacific-blue-alpha-xxl {
  background: rgba(0, 180, 187, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-pacific-blue-alpha-xxl {
  border-color: rgba(0, 180, 187, 0.7) !important;
}

.text-pacific-blue-alpha-xxl {
  color: rgba(0, 180, 187, 0.7) !important;
}

.bg-plantation-alpha-xxl {
  background: rgba(39, 88, 85, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-plantation-alpha-xxl {
  border-color: rgba(39, 88, 85, 0.7) !important;
}

.text-plantation-alpha-xxl {
  color: rgba(39, 88, 85, 0.7) !important;
}

.bg-puerto-rico-alpha-xxl {
  background: rgba(65, 196, 157, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-puerto-rico-alpha-xxl {
  border-color: rgba(65, 196, 157, 0.7) !important;
}

.text-puerto-rico-alpha-xxl {
  color: rgba(65, 196, 157, 0.7) !important;
}

.bg-royal-blue-alpha-xxl {
  background: rgba(92, 86, 232, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-royal-blue-alpha-xxl {
  border-color: rgba(92, 86, 232, 0.7) !important;
}

.text-royal-blue-alpha-xxl {
  color: rgba(92, 86, 232, 0.7) !important;
}

.bg-spicy-mix-alpha-xxl {
  background: rgba(133, 75, 75, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-spicy-mix-alpha-xxl {
  border-color: rgba(133, 75, 75, 0.7) !important;
}

.text-spicy-mix-alpha-xxl {
  color: rgba(133, 75, 75, 0.7) !important;
}

.bg-turquoise-blue-alpha-xxl {
  background: rgba(104, 239, 173, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-turquoise-blue-alpha-xxl {
  border-color: rgba(104, 239, 173, 0.7) !important;
}

.text-turquoise-blue-alpha-xxl {
  color: rgba(104, 239, 173, 0.7) !important;
}

.bg-valencia-alpha-xxl {
  background: rgba(220, 73, 65, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-valencia-alpha-xxl {
  border-color: rgba(220, 73, 65, 0.7) !important;
}

.text-valencia-alpha-xxl {
  color: rgba(220, 73, 65, 0.7) !important;
}

.bg-victoria-alpha-xxl {
  background: rgba(81, 75, 144, 0.7) !important;
}

.alpha-xxl {
  opacity: 0.7 !important;
}

.border-victoria-alpha-xxl {
  border-color: rgba(81, 75, 144, 0.7) !important;
}

.text-victoria-alpha-xxl {
  color: rgba(81, 75, 144, 0.7) !important;
}

.bg-apricot-alpha-xl {
  background: rgba(232, 142, 94, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-apricot-alpha-xl {
  border-color: rgba(232, 142, 94, 0.4) !important;
}

.text-apricot-alpha-xl {
  color: rgba(232, 142, 94, 0.4) !important;
}

.bg-azure-alpha-xl {
  background: rgba(56, 105, 159, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-azure-alpha-xl {
  border-color: rgba(56, 105, 159, 0.4) !important;
}

.text-azure-alpha-xl {
  color: rgba(56, 105, 159, 0.4) !important;
}

.bg-buttercup-alpha-xl {
  background: rgba(246, 164, 22, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-buttercup-alpha-xl {
  border-color: rgba(246, 164, 22, 0.4) !important;
}

.text-buttercup-alpha-xl {
  color: rgba(246, 164, 22, 0.4) !important;
}

.bg-copper-rust-alpha-xl {
  background: rgba(151, 74, 90, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-copper-rust-alpha-xl {
  border-color: rgba(151, 74, 90, 0.4) !important;
}

.text-copper-rust-alpha-xl {
  color: rgba(151, 74, 90, 0.4) !important;
}

.bg-cornflower-blue-alpha-xl {
  background: rgba(100, 136, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-cornflower-blue-alpha-xl {
  border-color: rgba(100, 136, 255, 0.4) !important;
}

.text-cornflower-blue-alpha-xl {
  color: rgba(100, 136, 255, 0.4) !important;
}

.bg-dodger-blue-alpha-xl {
  background: rgba(93, 85, 255, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-dodger-blue-alpha-xl {
  border-color: rgba(93, 85, 255, 0.4) !important;
}

.text-dodger-blue-alpha-xl {
  color: rgba(93, 85, 255, 0.4) !important;
}

.bg-east-bay-alpha-xl {
  background: rgba(74, 88, 133, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-east-bay-alpha-xl {
  border-color: rgba(74, 88, 133, 0.4) !important;
}

.text-east-bay-alpha-xl {
  color: rgba(74, 88, 133, 0.4) !important;
}

.bg-faded-jade-alpha-xl {
  background: rgba(64, 129, 130, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-faded-jade-alpha-xl {
  border-color: rgba(64, 129, 130, 0.4) !important;
}

.text-faded-jade-alpha-xl {
  color: rgba(64, 129, 130, 0.4) !important;
}

.bg-heather-alpha-xl {
  background: rgba(180, 187, 203, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-heather-alpha-xl {
  border-color: rgba(180, 187, 203, 0.4) !important;
}

.text-heather-alpha-xl {
  color: rgba(180, 187, 203, 0.4) !important;
}

.bg-mako-alpha-xl {
  background: rgba(68, 72, 82, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-mako-alpha-xl {
  border-color: rgba(68, 72, 82, 0.4) !important;
}

.text-mako-alpha-xl {
  color: rgba(68, 72, 82, 0.4) !important;
}

.bg-manatee-alpha-xl {
  background: rgba(139, 147, 168, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-manatee-alpha-xl {
  border-color: rgba(139, 147, 168, 0.4) !important;
}

.text-manatee-alpha-xl {
  color: rgba(139, 147, 168, 0.4) !important;
}

.bg-mars-alpha-xl {
  background: rgba(213, 50, 41, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-mars-alpha-xl {
  border-color: rgba(213, 50, 41, 0.4) !important;
}

.text-mars-alpha-xl {
  color: rgba(213, 50, 41, 0.4) !important;
}

.bg-mercury-alpha-xl {
  background: rgba(232, 232, 232, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-mercury-alpha-xl {
  border-color: rgba(232, 232, 232, 0.4) !important;
}

.text-mercury-alpha-xl {
  color: rgba(232, 232, 232, 0.4) !important;
}

.bg-pacific-blue-alpha-xl {
  background: rgba(0, 180, 187, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-pacific-blue-alpha-xl {
  border-color: rgba(0, 180, 187, 0.4) !important;
}

.text-pacific-blue-alpha-xl {
  color: rgba(0, 180, 187, 0.4) !important;
}

.bg-plantation-alpha-xl {
  background: rgba(39, 88, 85, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-plantation-alpha-xl {
  border-color: rgba(39, 88, 85, 0.4) !important;
}

.text-plantation-alpha-xl {
  color: rgba(39, 88, 85, 0.4) !important;
}

.bg-puerto-rico-alpha-xl {
  background: rgba(65, 196, 157, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-puerto-rico-alpha-xl {
  border-color: rgba(65, 196, 157, 0.4) !important;
}

.text-puerto-rico-alpha-xl {
  color: rgba(65, 196, 157, 0.4) !important;
}

.bg-royal-blue-alpha-xl {
  background: rgba(92, 86, 232, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-royal-blue-alpha-xl {
  border-color: rgba(92, 86, 232, 0.4) !important;
}

.text-royal-blue-alpha-xl {
  color: rgba(92, 86, 232, 0.4) !important;
}

.bg-spicy-mix-alpha-xl {
  background: rgba(133, 75, 75, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-spicy-mix-alpha-xl {
  border-color: rgba(133, 75, 75, 0.4) !important;
}

.text-spicy-mix-alpha-xl {
  color: rgba(133, 75, 75, 0.4) !important;
}

.bg-turquoise-blue-alpha-xl {
  background: rgba(104, 239, 173, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-turquoise-blue-alpha-xl {
  border-color: rgba(104, 239, 173, 0.4) !important;
}

.text-turquoise-blue-alpha-xl {
  color: rgba(104, 239, 173, 0.4) !important;
}

.bg-valencia-alpha-xl {
  background: rgba(220, 73, 65, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-valencia-alpha-xl {
  border-color: rgba(220, 73, 65, 0.4) !important;
}

.text-valencia-alpha-xl {
  color: rgba(220, 73, 65, 0.4) !important;
}

.bg-victoria-alpha-xl {
  background: rgba(81, 75, 144, 0.4) !important;
}

.alpha-xl {
  opacity: 0.4 !important;
}

.border-victoria-alpha-xl {
  border-color: rgba(81, 75, 144, 0.4) !important;
}

.text-victoria-alpha-xl {
  color: rgba(81, 75, 144, 0.4) !important;
}

.bg-apricot-alpha-lg {
  background: rgba(232, 142, 94, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-apricot-alpha-lg {
  border-color: rgba(232, 142, 94, 0.2) !important;
}

.text-apricot-alpha-lg {
  color: rgba(232, 142, 94, 0.2) !important;
}

.bg-azure-alpha-lg {
  background: rgba(56, 105, 159, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-azure-alpha-lg {
  border-color: rgba(56, 105, 159, 0.2) !important;
}

.text-azure-alpha-lg {
  color: rgba(56, 105, 159, 0.2) !important;
}

.bg-buttercup-alpha-lg {
  background: rgba(246, 164, 22, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-buttercup-alpha-lg {
  border-color: rgba(246, 164, 22, 0.2) !important;
}

.text-buttercup-alpha-lg {
  color: rgba(246, 164, 22, 0.2) !important;
}

.bg-copper-rust-alpha-lg {
  background: rgba(151, 74, 90, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-copper-rust-alpha-lg {
  border-color: rgba(151, 74, 90, 0.2) !important;
}

.text-copper-rust-alpha-lg {
  color: rgba(151, 74, 90, 0.2) !important;
}

.bg-cornflower-blue-alpha-lg {
  background: rgba(100, 136, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-cornflower-blue-alpha-lg {
  border-color: rgba(100, 136, 255, 0.2) !important;
}

.text-cornflower-blue-alpha-lg {
  color: rgba(100, 136, 255, 0.2) !important;
}

.bg-dodger-blue-alpha-lg {
  background: rgba(93, 85, 255, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-dodger-blue-alpha-lg {
  border-color: rgba(93, 85, 255, 0.2) !important;
}

.text-dodger-blue-alpha-lg {
  color: rgba(93, 85, 255, 0.2) !important;
}

.bg-east-bay-alpha-lg {
  background: rgba(74, 88, 133, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-east-bay-alpha-lg {
  border-color: rgba(74, 88, 133, 0.2) !important;
}

.text-east-bay-alpha-lg {
  color: rgba(74, 88, 133, 0.2) !important;
}

.bg-faded-jade-alpha-lg {
  background: rgba(64, 129, 130, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-faded-jade-alpha-lg {
  border-color: rgba(64, 129, 130, 0.2) !important;
}

.text-faded-jade-alpha-lg {
  color: rgba(64, 129, 130, 0.2) !important;
}

.bg-heather-alpha-lg {
  background: rgba(180, 187, 203, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-heather-alpha-lg {
  border-color: rgba(180, 187, 203, 0.2) !important;
}

.text-heather-alpha-lg {
  color: rgba(180, 187, 203, 0.2) !important;
}

.bg-mako-alpha-lg {
  background: rgba(68, 72, 82, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-mako-alpha-lg {
  border-color: rgba(68, 72, 82, 0.2) !important;
}

.text-mako-alpha-lg {
  color: rgba(68, 72, 82, 0.2) !important;
}

.bg-manatee-alpha-lg {
  background: rgba(139, 147, 168, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-manatee-alpha-lg {
  border-color: rgba(139, 147, 168, 0.2) !important;
}

.text-manatee-alpha-lg {
  color: rgba(139, 147, 168, 0.2) !important;
}

.bg-mars-alpha-lg {
  background: rgba(213, 50, 41, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-mars-alpha-lg {
  border-color: rgba(213, 50, 41, 0.2) !important;
}

.text-mars-alpha-lg {
  color: rgba(213, 50, 41, 0.2) !important;
}

.bg-mercury-alpha-lg {
  background: rgba(232, 232, 232, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-mercury-alpha-lg {
  border-color: rgba(232, 232, 232, 0.2) !important;
}

.text-mercury-alpha-lg {
  color: rgba(232, 232, 232, 0.2) !important;
}

.bg-pacific-blue-alpha-lg {
  background: rgba(0, 180, 187, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-pacific-blue-alpha-lg {
  border-color: rgba(0, 180, 187, 0.2) !important;
}

.text-pacific-blue-alpha-lg {
  color: rgba(0, 180, 187, 0.2) !important;
}

.bg-plantation-alpha-lg {
  background: rgba(39, 88, 85, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-plantation-alpha-lg {
  border-color: rgba(39, 88, 85, 0.2) !important;
}

.text-plantation-alpha-lg {
  color: rgba(39, 88, 85, 0.2) !important;
}

.bg-puerto-rico-alpha-lg {
  background: rgba(65, 196, 157, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-puerto-rico-alpha-lg {
  border-color: rgba(65, 196, 157, 0.2) !important;
}

.text-puerto-rico-alpha-lg {
  color: rgba(65, 196, 157, 0.2) !important;
}

.bg-royal-blue-alpha-lg {
  background: rgba(92, 86, 232, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-royal-blue-alpha-lg {
  border-color: rgba(92, 86, 232, 0.2) !important;
}

.text-royal-blue-alpha-lg {
  color: rgba(92, 86, 232, 0.2) !important;
}

.bg-spicy-mix-alpha-lg {
  background: rgba(133, 75, 75, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-spicy-mix-alpha-lg {
  border-color: rgba(133, 75, 75, 0.2) !important;
}

.text-spicy-mix-alpha-lg {
  color: rgba(133, 75, 75, 0.2) !important;
}

.bg-turquoise-blue-alpha-lg {
  background: rgba(104, 239, 173, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-turquoise-blue-alpha-lg {
  border-color: rgba(104, 239, 173, 0.2) !important;
}

.text-turquoise-blue-alpha-lg {
  color: rgba(104, 239, 173, 0.2) !important;
}

.bg-valencia-alpha-lg {
  background: rgba(220, 73, 65, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-valencia-alpha-lg {
  border-color: rgba(220, 73, 65, 0.2) !important;
}

.text-valencia-alpha-lg {
  color: rgba(220, 73, 65, 0.2) !important;
}

.bg-victoria-alpha-lg {
  background: rgba(81, 75, 144, 0.2) !important;
}

.alpha-lg {
  opacity: 0.2 !important;
}

.border-victoria-alpha-lg {
  border-color: rgba(81, 75, 144, 0.2) !important;
}

.text-victoria-alpha-lg {
  color: rgba(81, 75, 144, 0.2) !important;
}

.bg-apricot-alpha-md {
  background: rgba(232, 142, 94, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-apricot-alpha-md {
  border-color: rgba(232, 142, 94, 0.1) !important;
}

.text-apricot-alpha-md {
  color: rgba(232, 142, 94, 0.1) !important;
}

.bg-azure-alpha-md {
  background: rgba(56, 105, 159, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-azure-alpha-md {
  border-color: rgba(56, 105, 159, 0.1) !important;
}

.text-azure-alpha-md {
  color: rgba(56, 105, 159, 0.1) !important;
}

.bg-buttercup-alpha-md {
  background: rgba(246, 164, 22, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-buttercup-alpha-md {
  border-color: rgba(246, 164, 22, 0.1) !important;
}

.text-buttercup-alpha-md {
  color: rgba(246, 164, 22, 0.1) !important;
}

.bg-copper-rust-alpha-md {
  background: rgba(151, 74, 90, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-copper-rust-alpha-md {
  border-color: rgba(151, 74, 90, 0.1) !important;
}

.text-copper-rust-alpha-md {
  color: rgba(151, 74, 90, 0.1) !important;
}

.bg-cornflower-blue-alpha-md {
  background: rgba(100, 136, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-cornflower-blue-alpha-md {
  border-color: rgba(100, 136, 255, 0.1) !important;
}

.text-cornflower-blue-alpha-md {
  color: rgba(100, 136, 255, 0.1) !important;
}

.bg-dodger-blue-alpha-md {
  background: rgba(93, 85, 255, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-dodger-blue-alpha-md {
  border-color: rgba(93, 85, 255, 0.1) !important;
}

.text-dodger-blue-alpha-md {
  color: rgba(93, 85, 255, 0.1) !important;
}

.bg-east-bay-alpha-md {
  background: rgba(74, 88, 133, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-east-bay-alpha-md {
  border-color: rgba(74, 88, 133, 0.1) !important;
}

.text-east-bay-alpha-md {
  color: rgba(74, 88, 133, 0.1) !important;
}

.bg-faded-jade-alpha-md {
  background: rgba(64, 129, 130, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-faded-jade-alpha-md {
  border-color: rgba(64, 129, 130, 0.1) !important;
}

.text-faded-jade-alpha-md {
  color: rgba(64, 129, 130, 0.1) !important;
}

.bg-heather-alpha-md {
  background: rgba(180, 187, 203, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-heather-alpha-md {
  border-color: rgba(180, 187, 203, 0.1) !important;
}

.text-heather-alpha-md {
  color: rgba(180, 187, 203, 0.1) !important;
}

.bg-mako-alpha-md {
  background: rgba(68, 72, 82, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-mako-alpha-md {
  border-color: rgba(68, 72, 82, 0.1) !important;
}

.text-mako-alpha-md {
  color: rgba(68, 72, 82, 0.1) !important;
}

.bg-manatee-alpha-md {
  background: rgba(139, 147, 168, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-manatee-alpha-md {
  border-color: rgba(139, 147, 168, 0.1) !important;
}

.text-manatee-alpha-md {
  color: rgba(139, 147, 168, 0.1) !important;
}

.bg-mars-alpha-md {
  background: rgba(213, 50, 41, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-mars-alpha-md {
  border-color: rgba(213, 50, 41, 0.1) !important;
}

.text-mars-alpha-md {
  color: rgba(213, 50, 41, 0.1) !important;
}

.bg-mercury-alpha-md {
  background: rgba(232, 232, 232, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-mercury-alpha-md {
  border-color: rgba(232, 232, 232, 0.1) !important;
}

.text-mercury-alpha-md {
  color: rgba(232, 232, 232, 0.1) !important;
}

.bg-pacific-blue-alpha-md {
  background: rgba(0, 180, 187, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-pacific-blue-alpha-md {
  border-color: rgba(0, 180, 187, 0.1) !important;
}

.text-pacific-blue-alpha-md {
  color: rgba(0, 180, 187, 0.1) !important;
}

.bg-plantation-alpha-md {
  background: rgba(39, 88, 85, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-plantation-alpha-md {
  border-color: rgba(39, 88, 85, 0.1) !important;
}

.text-plantation-alpha-md {
  color: rgba(39, 88, 85, 0.1) !important;
}

.bg-puerto-rico-alpha-md {
  background: rgba(65, 196, 157, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-puerto-rico-alpha-md {
  border-color: rgba(65, 196, 157, 0.1) !important;
}

.text-puerto-rico-alpha-md {
  color: rgba(65, 196, 157, 0.1) !important;
}

.bg-royal-blue-alpha-md {
  background: rgba(92, 86, 232, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-royal-blue-alpha-md {
  border-color: rgba(92, 86, 232, 0.1) !important;
}

.text-royal-blue-alpha-md {
  color: rgba(92, 86, 232, 0.1) !important;
}

.bg-spicy-mix-alpha-md {
  background: rgba(133, 75, 75, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-spicy-mix-alpha-md {
  border-color: rgba(133, 75, 75, 0.1) !important;
}

.text-spicy-mix-alpha-md {
  color: rgba(133, 75, 75, 0.1) !important;
}

.bg-turquoise-blue-alpha-md {
  background: rgba(104, 239, 173, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-turquoise-blue-alpha-md {
  border-color: rgba(104, 239, 173, 0.1) !important;
}

.text-turquoise-blue-alpha-md {
  color: rgba(104, 239, 173, 0.1) !important;
}

.bg-valencia-alpha-md {
  background: rgba(220, 73, 65, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-valencia-alpha-md {
  border-color: rgba(220, 73, 65, 0.1) !important;
}

.text-valencia-alpha-md {
  color: rgba(220, 73, 65, 0.1) !important;
}

.bg-victoria-alpha-md {
  background: rgba(81, 75, 144, 0.1) !important;
}

.alpha-md {
  opacity: 0.1 !important;
}

.border-victoria-alpha-md {
  border-color: rgba(81, 75, 144, 0.1) !important;
}

.text-victoria-alpha-md {
  color: rgba(81, 75, 144, 0.1) !important;
}

.bg-apricot-alpha-sm {
  background: rgba(232, 142, 94, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-apricot-alpha-sm {
  border-color: rgba(232, 142, 94, 0.05) !important;
}

.text-apricot-alpha-sm {
  color: rgba(232, 142, 94, 0.05) !important;
}

.bg-azure-alpha-sm {
  background: rgba(56, 105, 159, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-azure-alpha-sm {
  border-color: rgba(56, 105, 159, 0.05) !important;
}

.text-azure-alpha-sm {
  color: rgba(56, 105, 159, 0.05) !important;
}

.bg-buttercup-alpha-sm {
  background: rgba(246, 164, 22, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-buttercup-alpha-sm {
  border-color: rgba(246, 164, 22, 0.05) !important;
}

.text-buttercup-alpha-sm {
  color: rgba(246, 164, 22, 0.05) !important;
}

.bg-copper-rust-alpha-sm {
  background: rgba(151, 74, 90, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-copper-rust-alpha-sm {
  border-color: rgba(151, 74, 90, 0.05) !important;
}

.text-copper-rust-alpha-sm {
  color: rgba(151, 74, 90, 0.05) !important;
}

.bg-cornflower-blue-alpha-sm {
  background: rgba(100, 136, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-cornflower-blue-alpha-sm {
  border-color: rgba(100, 136, 255, 0.05) !important;
}

.text-cornflower-blue-alpha-sm {
  color: rgba(100, 136, 255, 0.05) !important;
}

.bg-dodger-blue-alpha-sm {
  background: rgba(93, 85, 255, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-dodger-blue-alpha-sm {
  border-color: rgba(93, 85, 255, 0.05) !important;
}

.text-dodger-blue-alpha-sm {
  color: rgba(93, 85, 255, 0.05) !important;
}

.bg-east-bay-alpha-sm {
  background: rgba(74, 88, 133, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-east-bay-alpha-sm {
  border-color: rgba(74, 88, 133, 0.05) !important;
}

.text-east-bay-alpha-sm {
  color: rgba(74, 88, 133, 0.05) !important;
}

.bg-faded-jade-alpha-sm {
  background: rgba(64, 129, 130, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-faded-jade-alpha-sm {
  border-color: rgba(64, 129, 130, 0.05) !important;
}

.text-faded-jade-alpha-sm {
  color: rgba(64, 129, 130, 0.05) !important;
}

.bg-heather-alpha-sm {
  background: rgba(180, 187, 203, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-heather-alpha-sm {
  border-color: rgba(180, 187, 203, 0.05) !important;
}

.text-heather-alpha-sm {
  color: rgba(180, 187, 203, 0.05) !important;
}

.bg-mako-alpha-sm {
  background: rgba(68, 72, 82, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-mako-alpha-sm {
  border-color: rgba(68, 72, 82, 0.05) !important;
}

.text-mako-alpha-sm {
  color: rgba(68, 72, 82, 0.05) !important;
}

.bg-manatee-alpha-sm {
  background: rgba(139, 147, 168, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-manatee-alpha-sm {
  border-color: rgba(139, 147, 168, 0.05) !important;
}

.text-manatee-alpha-sm {
  color: rgba(139, 147, 168, 0.05) !important;
}

.bg-mars-alpha-sm {
  background: rgba(213, 50, 41, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-mars-alpha-sm {
  border-color: rgba(213, 50, 41, 0.05) !important;
}

.text-mars-alpha-sm {
  color: rgba(213, 50, 41, 0.05) !important;
}

.bg-mercury-alpha-sm {
  background: rgba(232, 232, 232, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-mercury-alpha-sm {
  border-color: rgba(232, 232, 232, 0.05) !important;
}

.text-mercury-alpha-sm {
  color: rgba(232, 232, 232, 0.05) !important;
}

.bg-pacific-blue-alpha-sm {
  background: rgba(0, 180, 187, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-pacific-blue-alpha-sm {
  border-color: rgba(0, 180, 187, 0.05) !important;
}

.text-pacific-blue-alpha-sm {
  color: rgba(0, 180, 187, 0.05) !important;
}

.bg-plantation-alpha-sm {
  background: rgba(39, 88, 85, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-plantation-alpha-sm {
  border-color: rgba(39, 88, 85, 0.05) !important;
}

.text-plantation-alpha-sm {
  color: rgba(39, 88, 85, 0.05) !important;
}

.bg-puerto-rico-alpha-sm {
  background: rgba(65, 196, 157, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-puerto-rico-alpha-sm {
  border-color: rgba(65, 196, 157, 0.05) !important;
}

.text-puerto-rico-alpha-sm {
  color: rgba(65, 196, 157, 0.05) !important;
}

.bg-royal-blue-alpha-sm {
  background: rgba(92, 86, 232, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-royal-blue-alpha-sm {
  border-color: rgba(92, 86, 232, 0.05) !important;
}

.text-royal-blue-alpha-sm {
  color: rgba(92, 86, 232, 0.05) !important;
}

.bg-spicy-mix-alpha-sm {
  background: rgba(133, 75, 75, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-spicy-mix-alpha-sm {
  border-color: rgba(133, 75, 75, 0.05) !important;
}

.text-spicy-mix-alpha-sm {
  color: rgba(133, 75, 75, 0.05) !important;
}

.bg-turquoise-blue-alpha-sm {
  background: rgba(104, 239, 173, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-turquoise-blue-alpha-sm {
  border-color: rgba(104, 239, 173, 0.05) !important;
}

.text-turquoise-blue-alpha-sm {
  color: rgba(104, 239, 173, 0.05) !important;
}

.bg-valencia-alpha-sm {
  background: rgba(220, 73, 65, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-valencia-alpha-sm {
  border-color: rgba(220, 73, 65, 0.05) !important;
}

.text-valencia-alpha-sm {
  color: rgba(220, 73, 65, 0.05) !important;
}

.bg-victoria-alpha-sm {
  background: rgba(81, 75, 144, 0.05) !important;
}

.alpha-sm {
  opacity: 0.05 !important;
}

.border-victoria-alpha-sm {
  border-color: rgba(81, 75, 144, 0.05) !important;
}

.text-victoria-alpha-sm {
  color: rgba(81, 75, 144, 0.05) !important;
}

.border.border-alpha-xxl {
  --bs-border-color: rgba(196, 200, 202, 0.7) !important;
}

.border-primary-alpha-xxl {
  --bs-border-color: rgba(0, 0, 0, 0.7) !important;
}

.border-primary.border-alpha-xxl {
  --bs-border-color: rgba(0, 0, 0, 0.7) !important;
}

.border-secondary-alpha-xxl {
  --bs-border-color: rgba(110, 119, 122, 0.7) !important;
}

.border-secondary.border-alpha-xxl {
  --bs-border-color: rgba(110, 119, 122, 0.7) !important;
}

.border-success-alpha-xxl {
  --bs-border-color: rgba(0, 170, 12, 0.7) !important;
}

.border-success.border-alpha-xxl {
  --bs-border-color: rgba(0, 170, 12, 0.7) !important;
}

.border-info-alpha-xxl {
  --bs-border-color: rgba(36, 105, 255, 0.7) !important;
}

.border-info.border-alpha-xxl {
  --bs-border-color: rgba(36, 105, 255, 0.7) !important;
}

.border-warning-alpha-xxl {
  --bs-border-color: rgba(218, 116, 0, 0.7) !important;
}

.border-warning.border-alpha-xxl {
  --bs-border-color: rgba(218, 116, 0, 0.7) !important;
}

.border-danger-alpha-xxl {
  --bs-border-color: rgba(221, 55, 36, 0.7) !important;
}

.border-danger.border-alpha-xxl {
  --bs-border-color: rgba(221, 55, 36, 0.7) !important;
}

.border-light-alpha-xxl {
  --bs-border-color: rgba(196, 200, 202, 0.7) !important;
}

.border-light.border-alpha-xxl {
  --bs-border-color: rgba(196, 200, 202, 0.7) !important;
}

.border-dark-alpha-xxl {
  --bs-border-color: rgba(65, 73, 73, 0.7) !important;
}

.border-dark.border-alpha-xxl {
  --bs-border-color: rgba(65, 73, 73, 0.7) !important;
}

.border-white-alpha-xxl {
  --bs-border-color: rgba(255, 255, 255, 0.7) !important;
}

.border-white.border-alpha-xxl {
  --bs-border-color: rgba(255, 255, 255, 0.7) !important;
}

.border-black-alpha-xxl {
  --bs-border-color: rgba(35, 31, 32, 0.7) !important;
}

.border-black.border-alpha-xxl {
  --bs-border-color: rgba(35, 31, 32, 0.7) !important;
}

.border-socgen-alpha-xxl {
  --bs-border-color: rgba(230, 0, 40, 0.7) !important;
}

.border-socgen.border-alpha-xxl {
  --bs-border-color: rgba(230, 0, 40, 0.7) !important;
}

.border-primary-alt-alpha-xxl {
  --bs-border-color: rgba(255, 255, 255, 0.7) !important;
}

.border-primary-alt.border-alpha-xxl {
  --bs-border-color: rgba(255, 255, 255, 0.7) !important;
}

.border.border-alpha-xl {
  --bs-border-color: rgba(196, 200, 202, 0.4) !important;
}

.border-primary-alpha-xl {
  --bs-border-color: rgba(0, 0, 0, 0.4) !important;
}

.border-primary.border-alpha-xl {
  --bs-border-color: rgba(0, 0, 0, 0.4) !important;
}

.border-secondary-alpha-xl {
  --bs-border-color: rgba(110, 119, 122, 0.4) !important;
}

.border-secondary.border-alpha-xl {
  --bs-border-color: rgba(110, 119, 122, 0.4) !important;
}

.border-success-alpha-xl {
  --bs-border-color: rgba(0, 170, 12, 0.4) !important;
}

.border-success.border-alpha-xl {
  --bs-border-color: rgba(0, 170, 12, 0.4) !important;
}

.border-info-alpha-xl {
  --bs-border-color: rgba(36, 105, 255, 0.4) !important;
}

.border-info.border-alpha-xl {
  --bs-border-color: rgba(36, 105, 255, 0.4) !important;
}

.border-warning-alpha-xl {
  --bs-border-color: rgba(218, 116, 0, 0.4) !important;
}

.border-warning.border-alpha-xl {
  --bs-border-color: rgba(218, 116, 0, 0.4) !important;
}

.border-danger-alpha-xl {
  --bs-border-color: rgba(221, 55, 36, 0.4) !important;
}

.border-danger.border-alpha-xl {
  --bs-border-color: rgba(221, 55, 36, 0.4) !important;
}

.border-light-alpha-xl {
  --bs-border-color: rgba(196, 200, 202, 0.4) !important;
}

.border-light.border-alpha-xl {
  --bs-border-color: rgba(196, 200, 202, 0.4) !important;
}

.border-dark-alpha-xl {
  --bs-border-color: rgba(65, 73, 73, 0.4) !important;
}

.border-dark.border-alpha-xl {
  --bs-border-color: rgba(65, 73, 73, 0.4) !important;
}

.border-white-alpha-xl {
  --bs-border-color: rgba(255, 255, 255, 0.4) !important;
}

.border-white.border-alpha-xl {
  --bs-border-color: rgba(255, 255, 255, 0.4) !important;
}

.border-black-alpha-xl {
  --bs-border-color: rgba(35, 31, 32, 0.4) !important;
}

.border-black.border-alpha-xl {
  --bs-border-color: rgba(35, 31, 32, 0.4) !important;
}

.border-socgen-alpha-xl {
  --bs-border-color: rgba(230, 0, 40, 0.4) !important;
}

.border-socgen.border-alpha-xl {
  --bs-border-color: rgba(230, 0, 40, 0.4) !important;
}

.border-primary-alt-alpha-xl {
  --bs-border-color: rgba(255, 255, 255, 0.4) !important;
}

.border-primary-alt.border-alpha-xl {
  --bs-border-color: rgba(255, 255, 255, 0.4) !important;
}

.border.border-alpha-lg {
  --bs-border-color: rgba(196, 200, 202, 0.2) !important;
}

.border-primary-alpha-lg {
  --bs-border-color: rgba(0, 0, 0, 0.2) !important;
}

.border-primary.border-alpha-lg {
  --bs-border-color: rgba(0, 0, 0, 0.2) !important;
}

.border-secondary-alpha-lg {
  --bs-border-color: rgba(110, 119, 122, 0.2) !important;
}

.border-secondary.border-alpha-lg {
  --bs-border-color: rgba(110, 119, 122, 0.2) !important;
}

.border-success-alpha-lg {
  --bs-border-color: rgba(0, 170, 12, 0.2) !important;
}

.border-success.border-alpha-lg {
  --bs-border-color: rgba(0, 170, 12, 0.2) !important;
}

.border-info-alpha-lg {
  --bs-border-color: rgba(36, 105, 255, 0.2) !important;
}

.border-info.border-alpha-lg {
  --bs-border-color: rgba(36, 105, 255, 0.2) !important;
}

.border-warning-alpha-lg {
  --bs-border-color: rgba(218, 116, 0, 0.2) !important;
}

.border-warning.border-alpha-lg {
  --bs-border-color: rgba(218, 116, 0, 0.2) !important;
}

.border-danger-alpha-lg {
  --bs-border-color: rgba(221, 55, 36, 0.2) !important;
}

.border-danger.border-alpha-lg {
  --bs-border-color: rgba(221, 55, 36, 0.2) !important;
}

.border-light-alpha-lg {
  --bs-border-color: rgba(196, 200, 202, 0.2) !important;
}

.border-light.border-alpha-lg {
  --bs-border-color: rgba(196, 200, 202, 0.2) !important;
}

.border-dark-alpha-lg {
  --bs-border-color: rgba(65, 73, 73, 0.2) !important;
}

.border-dark.border-alpha-lg {
  --bs-border-color: rgba(65, 73, 73, 0.2) !important;
}

.border-white-alpha-lg {
  --bs-border-color: rgba(255, 255, 255, 0.2) !important;
}

.border-white.border-alpha-lg {
  --bs-border-color: rgba(255, 255, 255, 0.2) !important;
}

.border-black-alpha-lg {
  --bs-border-color: rgba(35, 31, 32, 0.2) !important;
}

.border-black.border-alpha-lg {
  --bs-border-color: rgba(35, 31, 32, 0.2) !important;
}

.border-socgen-alpha-lg {
  --bs-border-color: rgba(230, 0, 40, 0.2) !important;
}

.border-socgen.border-alpha-lg {
  --bs-border-color: rgba(230, 0, 40, 0.2) !important;
}

.border-primary-alt-alpha-lg {
  --bs-border-color: rgba(255, 255, 255, 0.2) !important;
}

.border-primary-alt.border-alpha-lg {
  --bs-border-color: rgba(255, 255, 255, 0.2) !important;
}

.border.border-alpha-md {
  --bs-border-color: rgba(196, 200, 202, 0.1) !important;
}

.border-primary-alpha-md {
  --bs-border-color: rgba(0, 0, 0, 0.1) !important;
}

.border-primary.border-alpha-md {
  --bs-border-color: rgba(0, 0, 0, 0.1) !important;
}

.border-secondary-alpha-md {
  --bs-border-color: rgba(110, 119, 122, 0.1) !important;
}

.border-secondary.border-alpha-md {
  --bs-border-color: rgba(110, 119, 122, 0.1) !important;
}

.border-success-alpha-md {
  --bs-border-color: rgba(0, 170, 12, 0.1) !important;
}

.border-success.border-alpha-md {
  --bs-border-color: rgba(0, 170, 12, 0.1) !important;
}

.border-info-alpha-md {
  --bs-border-color: rgba(36, 105, 255, 0.1) !important;
}

.border-info.border-alpha-md {
  --bs-border-color: rgba(36, 105, 255, 0.1) !important;
}

.border-warning-alpha-md {
  --bs-border-color: rgba(218, 116, 0, 0.1) !important;
}

.border-warning.border-alpha-md {
  --bs-border-color: rgba(218, 116, 0, 0.1) !important;
}

.border-danger-alpha-md {
  --bs-border-color: rgba(221, 55, 36, 0.1) !important;
}

.border-danger.border-alpha-md {
  --bs-border-color: rgba(221, 55, 36, 0.1) !important;
}

.border-light-alpha-md {
  --bs-border-color: rgba(196, 200, 202, 0.1) !important;
}

.border-light.border-alpha-md {
  --bs-border-color: rgba(196, 200, 202, 0.1) !important;
}

.border-dark-alpha-md {
  --bs-border-color: rgba(65, 73, 73, 0.1) !important;
}

.border-dark.border-alpha-md {
  --bs-border-color: rgba(65, 73, 73, 0.1) !important;
}

.border-white-alpha-md {
  --bs-border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-white.border-alpha-md {
  --bs-border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-black-alpha-md {
  --bs-border-color: rgba(35, 31, 32, 0.1) !important;
}

.border-black.border-alpha-md {
  --bs-border-color: rgba(35, 31, 32, 0.1) !important;
}

.border-socgen-alpha-md {
  --bs-border-color: rgba(230, 0, 40, 0.1) !important;
}

.border-socgen.border-alpha-md {
  --bs-border-color: rgba(230, 0, 40, 0.1) !important;
}

.border-primary-alt-alpha-md {
  --bs-border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-primary-alt.border-alpha-md {
  --bs-border-color: rgba(255, 255, 255, 0.1) !important;
}

.border.border-alpha-sm {
  --bs-border-color: rgba(196, 200, 202, 0.05) !important;
}

.border-primary-alpha-sm {
  --bs-border-color: rgba(0, 0, 0, 0.05) !important;
}

.border-primary.border-alpha-sm {
  --bs-border-color: rgba(0, 0, 0, 0.05) !important;
}

.border-secondary-alpha-sm {
  --bs-border-color: rgba(110, 119, 122, 0.05) !important;
}

.border-secondary.border-alpha-sm {
  --bs-border-color: rgba(110, 119, 122, 0.05) !important;
}

.border-success-alpha-sm {
  --bs-border-color: rgba(0, 170, 12, 0.05) !important;
}

.border-success.border-alpha-sm {
  --bs-border-color: rgba(0, 170, 12, 0.05) !important;
}

.border-info-alpha-sm {
  --bs-border-color: rgba(36, 105, 255, 0.05) !important;
}

.border-info.border-alpha-sm {
  --bs-border-color: rgba(36, 105, 255, 0.05) !important;
}

.border-warning-alpha-sm {
  --bs-border-color: rgba(218, 116, 0, 0.05) !important;
}

.border-warning.border-alpha-sm {
  --bs-border-color: rgba(218, 116, 0, 0.05) !important;
}

.border-danger-alpha-sm {
  --bs-border-color: rgba(221, 55, 36, 0.05) !important;
}

.border-danger.border-alpha-sm {
  --bs-border-color: rgba(221, 55, 36, 0.05) !important;
}

.border-light-alpha-sm {
  --bs-border-color: rgba(196, 200, 202, 0.05) !important;
}

.border-light.border-alpha-sm {
  --bs-border-color: rgba(196, 200, 202, 0.05) !important;
}

.border-dark-alpha-sm {
  --bs-border-color: rgba(65, 73, 73, 0.05) !important;
}

.border-dark.border-alpha-sm {
  --bs-border-color: rgba(65, 73, 73, 0.05) !important;
}

.border-white-alpha-sm {
  --bs-border-color: rgba(255, 255, 255, 0.05) !important;
}

.border-white.border-alpha-sm {
  --bs-border-color: rgba(255, 255, 255, 0.05) !important;
}

.border-black-alpha-sm {
  --bs-border-color: rgba(35, 31, 32, 0.05) !important;
}

.border-black.border-alpha-sm {
  --bs-border-color: rgba(35, 31, 32, 0.05) !important;
}

.border-socgen-alpha-sm {
  --bs-border-color: rgba(230, 0, 40, 0.05) !important;
}

.border-socgen.border-alpha-sm {
  --bs-border-color: rgba(230, 0, 40, 0.05) !important;
}

.border-primary-alt-alpha-sm {
  --bs-border-color: rgba(255, 255, 255, 0.05) !important;
}

.border-primary-alt.border-alpha-sm {
  --bs-border-color: rgba(255, 255, 255, 0.05) !important;
}

.border.border-alpha-xxl {
  --bs-border-color: rgba(196, 200, 202, 0.7) !important;
}

.border-lvl1-alpha-xxl {
  --bs-border-color: rgba(255, 255, 255, 0.7) !important;
}

.border-lvl1.border-alpha-xxl {
  --bs-border-color: rgba(255, 255, 255, 0.7) !important;
}

.border-lvl2-alpha-xxl {
  --bs-border-color: rgba(245, 246, 247, 0.7) !important;
}

.border-lvl2.border-alpha-xxl {
  --bs-border-color: rgba(245, 246, 247, 0.7) !important;
}

.border-lvl3-alpha-xxl {
  --bs-border-color: rgba(236, 238, 239, 0.7) !important;
}

.border-lvl3.border-alpha-xxl {
  --bs-border-color: rgba(236, 238, 239, 0.7) !important;
}

.border-lvl4-alpha-xxl {
  --bs-border-color: rgba(227, 230, 231, 0.7) !important;
}

.border-lvl4.border-alpha-xxl {
  --bs-border-color: rgba(227, 230, 231, 0.7) !important;
}

.border.border-alpha-xl {
  --bs-border-color: rgba(196, 200, 202, 0.4) !important;
}

.border-lvl1-alpha-xl {
  --bs-border-color: rgba(255, 255, 255, 0.4) !important;
}

.border-lvl1.border-alpha-xl {
  --bs-border-color: rgba(255, 255, 255, 0.4) !important;
}

.border-lvl2-alpha-xl {
  --bs-border-color: rgba(245, 246, 247, 0.4) !important;
}

.border-lvl2.border-alpha-xl {
  --bs-border-color: rgba(245, 246, 247, 0.4) !important;
}

.border-lvl3-alpha-xl {
  --bs-border-color: rgba(236, 238, 239, 0.4) !important;
}

.border-lvl3.border-alpha-xl {
  --bs-border-color: rgba(236, 238, 239, 0.4) !important;
}

.border-lvl4-alpha-xl {
  --bs-border-color: rgba(227, 230, 231, 0.4) !important;
}

.border-lvl4.border-alpha-xl {
  --bs-border-color: rgba(227, 230, 231, 0.4) !important;
}

.border.border-alpha-lg {
  --bs-border-color: rgba(196, 200, 202, 0.2) !important;
}

.border-lvl1-alpha-lg {
  --bs-border-color: rgba(255, 255, 255, 0.2) !important;
}

.border-lvl1.border-alpha-lg {
  --bs-border-color: rgba(255, 255, 255, 0.2) !important;
}

.border-lvl2-alpha-lg {
  --bs-border-color: rgba(245, 246, 247, 0.2) !important;
}

.border-lvl2.border-alpha-lg {
  --bs-border-color: rgba(245, 246, 247, 0.2) !important;
}

.border-lvl3-alpha-lg {
  --bs-border-color: rgba(236, 238, 239, 0.2) !important;
}

.border-lvl3.border-alpha-lg {
  --bs-border-color: rgba(236, 238, 239, 0.2) !important;
}

.border-lvl4-alpha-lg {
  --bs-border-color: rgba(227, 230, 231, 0.2) !important;
}

.border-lvl4.border-alpha-lg {
  --bs-border-color: rgba(227, 230, 231, 0.2) !important;
}

.border.border-alpha-md {
  --bs-border-color: rgba(196, 200, 202, 0.1) !important;
}

.border-lvl1-alpha-md {
  --bs-border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-lvl1.border-alpha-md {
  --bs-border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-lvl2-alpha-md {
  --bs-border-color: rgba(245, 246, 247, 0.1) !important;
}

.border-lvl2.border-alpha-md {
  --bs-border-color: rgba(245, 246, 247, 0.1) !important;
}

.border-lvl3-alpha-md {
  --bs-border-color: rgba(236, 238, 239, 0.1) !important;
}

.border-lvl3.border-alpha-md {
  --bs-border-color: rgba(236, 238, 239, 0.1) !important;
}

.border-lvl4-alpha-md {
  --bs-border-color: rgba(227, 230, 231, 0.1) !important;
}

.border-lvl4.border-alpha-md {
  --bs-border-color: rgba(227, 230, 231, 0.1) !important;
}

.border.border-alpha-sm {
  --bs-border-color: rgba(196, 200, 202, 0.05) !important;
}

.border-lvl1-alpha-sm {
  --bs-border-color: rgba(255, 255, 255, 0.05) !important;
}

.border-lvl1.border-alpha-sm {
  --bs-border-color: rgba(255, 255, 255, 0.05) !important;
}

.border-lvl2-alpha-sm {
  --bs-border-color: rgba(245, 246, 247, 0.05) !important;
}

.border-lvl2.border-alpha-sm {
  --bs-border-color: rgba(245, 246, 247, 0.05) !important;
}

.border-lvl3-alpha-sm {
  --bs-border-color: rgba(236, 238, 239, 0.05) !important;
}

.border-lvl3.border-alpha-sm {
  --bs-border-color: rgba(236, 238, 239, 0.05) !important;
}

.border-lvl4-alpha-sm {
  --bs-border-color: rgba(227, 230, 231, 0.05) !important;
}

.border-lvl4.border-alpha-sm {
  --bs-border-color: rgba(227, 230, 231, 0.05) !important;
}

.border.border-alpha-xxl {
  --bs-border-color: rgba(196, 200, 202, 0.7) !important;
}

.border-lvl1-alpha-xxl {
  --bs-border-color: rgba(48, 51, 51, 0.7) !important;
}

.border-lvl1.border-alpha-xxl {
  --bs-border-color: rgba(48, 51, 51, 0.7) !important;
}

.border-lvl2-alpha-xxl {
  --bs-border-color: rgba(34, 36, 36, 0.7) !important;
}

.border-lvl2.border-alpha-xxl {
  --bs-border-color: rgba(34, 36, 36, 0.7) !important;
}

.border-lvl3-alpha-xxl {
  --bs-border-color: rgba(21, 22, 22, 0.7) !important;
}

.border-lvl3.border-alpha-xxl {
  --bs-border-color: rgba(21, 22, 22, 0.7) !important;
}

.border-lvl4-alpha-xxl {
  --bs-border-color: rgba(0, 0, 0, 0.7) !important;
}

.border-lvl4.border-alpha-xxl {
  --bs-border-color: rgba(0, 0, 0, 0.7) !important;
}

.border.border-alpha-xl {
  --bs-border-color: rgba(196, 200, 202, 0.4) !important;
}

.border-lvl1-alpha-xl {
  --bs-border-color: rgba(48, 51, 51, 0.4) !important;
}

.border-lvl1.border-alpha-xl {
  --bs-border-color: rgba(48, 51, 51, 0.4) !important;
}

.border-lvl2-alpha-xl {
  --bs-border-color: rgba(34, 36, 36, 0.4) !important;
}

.border-lvl2.border-alpha-xl {
  --bs-border-color: rgba(34, 36, 36, 0.4) !important;
}

.border-lvl3-alpha-xl {
  --bs-border-color: rgba(21, 22, 22, 0.4) !important;
}

.border-lvl3.border-alpha-xl {
  --bs-border-color: rgba(21, 22, 22, 0.4) !important;
}

.border-lvl4-alpha-xl {
  --bs-border-color: rgba(0, 0, 0, 0.4) !important;
}

.border-lvl4.border-alpha-xl {
  --bs-border-color: rgba(0, 0, 0, 0.4) !important;
}

.border.border-alpha-lg {
  --bs-border-color: rgba(196, 200, 202, 0.2) !important;
}

.border-lvl1-alpha-lg {
  --bs-border-color: rgba(48, 51, 51, 0.2) !important;
}

.border-lvl1.border-alpha-lg {
  --bs-border-color: rgba(48, 51, 51, 0.2) !important;
}

.border-lvl2-alpha-lg {
  --bs-border-color: rgba(34, 36, 36, 0.2) !important;
}

.border-lvl2.border-alpha-lg {
  --bs-border-color: rgba(34, 36, 36, 0.2) !important;
}

.border-lvl3-alpha-lg {
  --bs-border-color: rgba(21, 22, 22, 0.2) !important;
}

.border-lvl3.border-alpha-lg {
  --bs-border-color: rgba(21, 22, 22, 0.2) !important;
}

.border-lvl4-alpha-lg {
  --bs-border-color: rgba(0, 0, 0, 0.2) !important;
}

.border-lvl4.border-alpha-lg {
  --bs-border-color: rgba(0, 0, 0, 0.2) !important;
}

.border.border-alpha-md {
  --bs-border-color: rgba(196, 200, 202, 0.1) !important;
}

.border-lvl1-alpha-md {
  --bs-border-color: rgba(48, 51, 51, 0.1) !important;
}

.border-lvl1.border-alpha-md {
  --bs-border-color: rgba(48, 51, 51, 0.1) !important;
}

.border-lvl2-alpha-md {
  --bs-border-color: rgba(34, 36, 36, 0.1) !important;
}

.border-lvl2.border-alpha-md {
  --bs-border-color: rgba(34, 36, 36, 0.1) !important;
}

.border-lvl3-alpha-md {
  --bs-border-color: rgba(21, 22, 22, 0.1) !important;
}

.border-lvl3.border-alpha-md {
  --bs-border-color: rgba(21, 22, 22, 0.1) !important;
}

.border-lvl4-alpha-md {
  --bs-border-color: rgba(0, 0, 0, 0.1) !important;
}

.border-lvl4.border-alpha-md {
  --bs-border-color: rgba(0, 0, 0, 0.1) !important;
}

.border.border-alpha-sm {
  --bs-border-color: rgba(196, 200, 202, 0.05) !important;
}

.border-lvl1-alpha-sm {
  --bs-border-color: rgba(48, 51, 51, 0.05) !important;
}

.border-lvl1.border-alpha-sm {
  --bs-border-color: rgba(48, 51, 51, 0.05) !important;
}

.border-lvl2-alpha-sm {
  --bs-border-color: rgba(34, 36, 36, 0.05) !important;
}

.border-lvl2.border-alpha-sm {
  --bs-border-color: rgba(34, 36, 36, 0.05) !important;
}

.border-lvl3-alpha-sm {
  --bs-border-color: rgba(21, 22, 22, 0.05) !important;
}

.border-lvl3.border-alpha-sm {
  --bs-border-color: rgba(21, 22, 22, 0.05) !important;
}

.border-lvl4-alpha-sm {
  --bs-border-color: rgba(0, 0, 0, 0.05) !important;
}

.border-lvl4.border-alpha-sm {
  --bs-border-color: rgba(0, 0, 0, 0.05) !important;
}

.border.border-alpha-xxl {
  --bs-border-color: rgba(196, 200, 202, 0.7) !important;
}

.border-apricot-alpha-xxl {
  --bs-border-color: rgba(232, 142, 94, 0.7) !important;
}

.border-apricot.border-alpha-xxl {
  --bs-border-color: rgba(232, 142, 94, 0.7) !important;
}

.border-azure-alpha-xxl {
  --bs-border-color: rgba(56, 105, 159, 0.7) !important;
}

.border-azure.border-alpha-xxl {
  --bs-border-color: rgba(56, 105, 159, 0.7) !important;
}

.border-buttercup-alpha-xxl {
  --bs-border-color: rgba(246, 164, 22, 0.7) !important;
}

.border-buttercup.border-alpha-xxl {
  --bs-border-color: rgba(246, 164, 22, 0.7) !important;
}

.border-copper-rust-alpha-xxl {
  --bs-border-color: rgba(151, 74, 90, 0.7) !important;
}

.border-copper-rust.border-alpha-xxl {
  --bs-border-color: rgba(151, 74, 90, 0.7) !important;
}

.border-cornflower-blue-alpha-xxl {
  --bs-border-color: rgba(100, 136, 255, 0.7) !important;
}

.border-cornflower-blue.border-alpha-xxl {
  --bs-border-color: rgba(100, 136, 255, 0.7) !important;
}

.border-dodger-blue-alpha-xxl {
  --bs-border-color: rgba(93, 85, 255, 0.7) !important;
}

.border-dodger-blue.border-alpha-xxl {
  --bs-border-color: rgba(93, 85, 255, 0.7) !important;
}

.border-east-bay-alpha-xxl {
  --bs-border-color: rgba(74, 88, 133, 0.7) !important;
}

.border-east-bay.border-alpha-xxl {
  --bs-border-color: rgba(74, 88, 133, 0.7) !important;
}

.border-faded-jade-alpha-xxl {
  --bs-border-color: rgba(64, 129, 130, 0.7) !important;
}

.border-faded-jade.border-alpha-xxl {
  --bs-border-color: rgba(64, 129, 130, 0.7) !important;
}

.border-heather-alpha-xxl {
  --bs-border-color: rgba(180, 187, 203, 0.7) !important;
}

.border-heather.border-alpha-xxl {
  --bs-border-color: rgba(180, 187, 203, 0.7) !important;
}

.border-mako-alpha-xxl {
  --bs-border-color: rgba(68, 72, 82, 0.7) !important;
}

.border-mako.border-alpha-xxl {
  --bs-border-color: rgba(68, 72, 82, 0.7) !important;
}

.border-manatee-alpha-xxl {
  --bs-border-color: rgba(139, 147, 168, 0.7) !important;
}

.border-manatee.border-alpha-xxl {
  --bs-border-color: rgba(139, 147, 168, 0.7) !important;
}

.border-mars-alpha-xxl {
  --bs-border-color: rgba(213, 50, 41, 0.7) !important;
}

.border-mars.border-alpha-xxl {
  --bs-border-color: rgba(213, 50, 41, 0.7) !important;
}

.border-mercury-alpha-xxl {
  --bs-border-color: rgba(232, 232, 232, 0.7) !important;
}

.border-mercury.border-alpha-xxl {
  --bs-border-color: rgba(232, 232, 232, 0.7) !important;
}

.border-pacific-blue-alpha-xxl {
  --bs-border-color: rgba(0, 180, 187, 0.7) !important;
}

.border-pacific-blue.border-alpha-xxl {
  --bs-border-color: rgba(0, 180, 187, 0.7) !important;
}

.border-plantation-alpha-xxl {
  --bs-border-color: rgba(39, 88, 85, 0.7) !important;
}

.border-plantation.border-alpha-xxl {
  --bs-border-color: rgba(39, 88, 85, 0.7) !important;
}

.border-puerto-rico-alpha-xxl {
  --bs-border-color: rgba(65, 196, 157, 0.7) !important;
}

.border-puerto-rico.border-alpha-xxl {
  --bs-border-color: rgba(65, 196, 157, 0.7) !important;
}

.border-royal-blue-alpha-xxl {
  --bs-border-color: rgba(92, 86, 232, 0.7) !important;
}

.border-royal-blue.border-alpha-xxl {
  --bs-border-color: rgba(92, 86, 232, 0.7) !important;
}

.border-spicy-mix-alpha-xxl {
  --bs-border-color: rgba(133, 75, 75, 0.7) !important;
}

.border-spicy-mix.border-alpha-xxl {
  --bs-border-color: rgba(133, 75, 75, 0.7) !important;
}

.border-turquoise-blue-alpha-xxl {
  --bs-border-color: rgba(104, 239, 173, 0.7) !important;
}

.border-turquoise-blue.border-alpha-xxl {
  --bs-border-color: rgba(104, 239, 173, 0.7) !important;
}

.border-valencia-alpha-xxl {
  --bs-border-color: rgba(220, 73, 65, 0.7) !important;
}

.border-valencia.border-alpha-xxl {
  --bs-border-color: rgba(220, 73, 65, 0.7) !important;
}

.border-victoria-alpha-xxl {
  --bs-border-color: rgba(81, 75, 144, 0.7) !important;
}

.border-victoria.border-alpha-xxl {
  --bs-border-color: rgba(81, 75, 144, 0.7) !important;
}

.border.border-alpha-xl {
  --bs-border-color: rgba(196, 200, 202, 0.4) !important;
}

.border-apricot-alpha-xl {
  --bs-border-color: rgba(232, 142, 94, 0.4) !important;
}

.border-apricot.border-alpha-xl {
  --bs-border-color: rgba(232, 142, 94, 0.4) !important;
}

.border-azure-alpha-xl {
  --bs-border-color: rgba(56, 105, 159, 0.4) !important;
}

.border-azure.border-alpha-xl {
  --bs-border-color: rgba(56, 105, 159, 0.4) !important;
}

.border-buttercup-alpha-xl {
  --bs-border-color: rgba(246, 164, 22, 0.4) !important;
}

.border-buttercup.border-alpha-xl {
  --bs-border-color: rgba(246, 164, 22, 0.4) !important;
}

.border-copper-rust-alpha-xl {
  --bs-border-color: rgba(151, 74, 90, 0.4) !important;
}

.border-copper-rust.border-alpha-xl {
  --bs-border-color: rgba(151, 74, 90, 0.4) !important;
}

.border-cornflower-blue-alpha-xl {
  --bs-border-color: rgba(100, 136, 255, 0.4) !important;
}

.border-cornflower-blue.border-alpha-xl {
  --bs-border-color: rgba(100, 136, 255, 0.4) !important;
}

.border-dodger-blue-alpha-xl {
  --bs-border-color: rgba(93, 85, 255, 0.4) !important;
}

.border-dodger-blue.border-alpha-xl {
  --bs-border-color: rgba(93, 85, 255, 0.4) !important;
}

.border-east-bay-alpha-xl {
  --bs-border-color: rgba(74, 88, 133, 0.4) !important;
}

.border-east-bay.border-alpha-xl {
  --bs-border-color: rgba(74, 88, 133, 0.4) !important;
}

.border-faded-jade-alpha-xl {
  --bs-border-color: rgba(64, 129, 130, 0.4) !important;
}

.border-faded-jade.border-alpha-xl {
  --bs-border-color: rgba(64, 129, 130, 0.4) !important;
}

.border-heather-alpha-xl {
  --bs-border-color: rgba(180, 187, 203, 0.4) !important;
}

.border-heather.border-alpha-xl {
  --bs-border-color: rgba(180, 187, 203, 0.4) !important;
}

.border-mako-alpha-xl {
  --bs-border-color: rgba(68, 72, 82, 0.4) !important;
}

.border-mako.border-alpha-xl {
  --bs-border-color: rgba(68, 72, 82, 0.4) !important;
}

.border-manatee-alpha-xl {
  --bs-border-color: rgba(139, 147, 168, 0.4) !important;
}

.border-manatee.border-alpha-xl {
  --bs-border-color: rgba(139, 147, 168, 0.4) !important;
}

.border-mars-alpha-xl {
  --bs-border-color: rgba(213, 50, 41, 0.4) !important;
}

.border-mars.border-alpha-xl {
  --bs-border-color: rgba(213, 50, 41, 0.4) !important;
}

.border-mercury-alpha-xl {
  --bs-border-color: rgba(232, 232, 232, 0.4) !important;
}

.border-mercury.border-alpha-xl {
  --bs-border-color: rgba(232, 232, 232, 0.4) !important;
}

.border-pacific-blue-alpha-xl {
  --bs-border-color: rgba(0, 180, 187, 0.4) !important;
}

.border-pacific-blue.border-alpha-xl {
  --bs-border-color: rgba(0, 180, 187, 0.4) !important;
}

.border-plantation-alpha-xl {
  --bs-border-color: rgba(39, 88, 85, 0.4) !important;
}

.border-plantation.border-alpha-xl {
  --bs-border-color: rgba(39, 88, 85, 0.4) !important;
}

.border-puerto-rico-alpha-xl {
  --bs-border-color: rgba(65, 196, 157, 0.4) !important;
}

.border-puerto-rico.border-alpha-xl {
  --bs-border-color: rgba(65, 196, 157, 0.4) !important;
}

.border-royal-blue-alpha-xl {
  --bs-border-color: rgba(92, 86, 232, 0.4) !important;
}

.border-royal-blue.border-alpha-xl {
  --bs-border-color: rgba(92, 86, 232, 0.4) !important;
}

.border-spicy-mix-alpha-xl {
  --bs-border-color: rgba(133, 75, 75, 0.4) !important;
}

.border-spicy-mix.border-alpha-xl {
  --bs-border-color: rgba(133, 75, 75, 0.4) !important;
}

.border-turquoise-blue-alpha-xl {
  --bs-border-color: rgba(104, 239, 173, 0.4) !important;
}

.border-turquoise-blue.border-alpha-xl {
  --bs-border-color: rgba(104, 239, 173, 0.4) !important;
}

.border-valencia-alpha-xl {
  --bs-border-color: rgba(220, 73, 65, 0.4) !important;
}

.border-valencia.border-alpha-xl {
  --bs-border-color: rgba(220, 73, 65, 0.4) !important;
}

.border-victoria-alpha-xl {
  --bs-border-color: rgba(81, 75, 144, 0.4) !important;
}

.border-victoria.border-alpha-xl {
  --bs-border-color: rgba(81, 75, 144, 0.4) !important;
}

.border.border-alpha-lg {
  --bs-border-color: rgba(196, 200, 202, 0.2) !important;
}

.border-apricot-alpha-lg {
  --bs-border-color: rgba(232, 142, 94, 0.2) !important;
}

.border-apricot.border-alpha-lg {
  --bs-border-color: rgba(232, 142, 94, 0.2) !important;
}

.border-azure-alpha-lg {
  --bs-border-color: rgba(56, 105, 159, 0.2) !important;
}

.border-azure.border-alpha-lg {
  --bs-border-color: rgba(56, 105, 159, 0.2) !important;
}

.border-buttercup-alpha-lg {
  --bs-border-color: rgba(246, 164, 22, 0.2) !important;
}

.border-buttercup.border-alpha-lg {
  --bs-border-color: rgba(246, 164, 22, 0.2) !important;
}

.border-copper-rust-alpha-lg {
  --bs-border-color: rgba(151, 74, 90, 0.2) !important;
}

.border-copper-rust.border-alpha-lg {
  --bs-border-color: rgba(151, 74, 90, 0.2) !important;
}

.border-cornflower-blue-alpha-lg {
  --bs-border-color: rgba(100, 136, 255, 0.2) !important;
}

.border-cornflower-blue.border-alpha-lg {
  --bs-border-color: rgba(100, 136, 255, 0.2) !important;
}

.border-dodger-blue-alpha-lg {
  --bs-border-color: rgba(93, 85, 255, 0.2) !important;
}

.border-dodger-blue.border-alpha-lg {
  --bs-border-color: rgba(93, 85, 255, 0.2) !important;
}

.border-east-bay-alpha-lg {
  --bs-border-color: rgba(74, 88, 133, 0.2) !important;
}

.border-east-bay.border-alpha-lg {
  --bs-border-color: rgba(74, 88, 133, 0.2) !important;
}

.border-faded-jade-alpha-lg {
  --bs-border-color: rgba(64, 129, 130, 0.2) !important;
}

.border-faded-jade.border-alpha-lg {
  --bs-border-color: rgba(64, 129, 130, 0.2) !important;
}

.border-heather-alpha-lg {
  --bs-border-color: rgba(180, 187, 203, 0.2) !important;
}

.border-heather.border-alpha-lg {
  --bs-border-color: rgba(180, 187, 203, 0.2) !important;
}

.border-mako-alpha-lg {
  --bs-border-color: rgba(68, 72, 82, 0.2) !important;
}

.border-mako.border-alpha-lg {
  --bs-border-color: rgba(68, 72, 82, 0.2) !important;
}

.border-manatee-alpha-lg {
  --bs-border-color: rgba(139, 147, 168, 0.2) !important;
}

.border-manatee.border-alpha-lg {
  --bs-border-color: rgba(139, 147, 168, 0.2) !important;
}

.border-mars-alpha-lg {
  --bs-border-color: rgba(213, 50, 41, 0.2) !important;
}

.border-mars.border-alpha-lg {
  --bs-border-color: rgba(213, 50, 41, 0.2) !important;
}

.border-mercury-alpha-lg {
  --bs-border-color: rgba(232, 232, 232, 0.2) !important;
}

.border-mercury.border-alpha-lg {
  --bs-border-color: rgba(232, 232, 232, 0.2) !important;
}

.border-pacific-blue-alpha-lg {
  --bs-border-color: rgba(0, 180, 187, 0.2) !important;
}

.border-pacific-blue.border-alpha-lg {
  --bs-border-color: rgba(0, 180, 187, 0.2) !important;
}

.border-plantation-alpha-lg {
  --bs-border-color: rgba(39, 88, 85, 0.2) !important;
}

.border-plantation.border-alpha-lg {
  --bs-border-color: rgba(39, 88, 85, 0.2) !important;
}

.border-puerto-rico-alpha-lg {
  --bs-border-color: rgba(65, 196, 157, 0.2) !important;
}

.border-puerto-rico.border-alpha-lg {
  --bs-border-color: rgba(65, 196, 157, 0.2) !important;
}

.border-royal-blue-alpha-lg {
  --bs-border-color: rgba(92, 86, 232, 0.2) !important;
}

.border-royal-blue.border-alpha-lg {
  --bs-border-color: rgba(92, 86, 232, 0.2) !important;
}

.border-spicy-mix-alpha-lg {
  --bs-border-color: rgba(133, 75, 75, 0.2) !important;
}

.border-spicy-mix.border-alpha-lg {
  --bs-border-color: rgba(133, 75, 75, 0.2) !important;
}

.border-turquoise-blue-alpha-lg {
  --bs-border-color: rgba(104, 239, 173, 0.2) !important;
}

.border-turquoise-blue.border-alpha-lg {
  --bs-border-color: rgba(104, 239, 173, 0.2) !important;
}

.border-valencia-alpha-lg {
  --bs-border-color: rgba(220, 73, 65, 0.2) !important;
}

.border-valencia.border-alpha-lg {
  --bs-border-color: rgba(220, 73, 65, 0.2) !important;
}

.border-victoria-alpha-lg {
  --bs-border-color: rgba(81, 75, 144, 0.2) !important;
}

.border-victoria.border-alpha-lg {
  --bs-border-color: rgba(81, 75, 144, 0.2) !important;
}

.border.border-alpha-md {
  --bs-border-color: rgba(196, 200, 202, 0.1) !important;
}

.border-apricot-alpha-md {
  --bs-border-color: rgba(232, 142, 94, 0.1) !important;
}

.border-apricot.border-alpha-md {
  --bs-border-color: rgba(232, 142, 94, 0.1) !important;
}

.border-azure-alpha-md {
  --bs-border-color: rgba(56, 105, 159, 0.1) !important;
}

.border-azure.border-alpha-md {
  --bs-border-color: rgba(56, 105, 159, 0.1) !important;
}

.border-buttercup-alpha-md {
  --bs-border-color: rgba(246, 164, 22, 0.1) !important;
}

.border-buttercup.border-alpha-md {
  --bs-border-color: rgba(246, 164, 22, 0.1) !important;
}

.border-copper-rust-alpha-md {
  --bs-border-color: rgba(151, 74, 90, 0.1) !important;
}

.border-copper-rust.border-alpha-md {
  --bs-border-color: rgba(151, 74, 90, 0.1) !important;
}

.border-cornflower-blue-alpha-md {
  --bs-border-color: rgba(100, 136, 255, 0.1) !important;
}

.border-cornflower-blue.border-alpha-md {
  --bs-border-color: rgba(100, 136, 255, 0.1) !important;
}

.border-dodger-blue-alpha-md {
  --bs-border-color: rgba(93, 85, 255, 0.1) !important;
}

.border-dodger-blue.border-alpha-md {
  --bs-border-color: rgba(93, 85, 255, 0.1) !important;
}

.border-east-bay-alpha-md {
  --bs-border-color: rgba(74, 88, 133, 0.1) !important;
}

.border-east-bay.border-alpha-md {
  --bs-border-color: rgba(74, 88, 133, 0.1) !important;
}

.border-faded-jade-alpha-md {
  --bs-border-color: rgba(64, 129, 130, 0.1) !important;
}

.border-faded-jade.border-alpha-md {
  --bs-border-color: rgba(64, 129, 130, 0.1) !important;
}

.border-heather-alpha-md {
  --bs-border-color: rgba(180, 187, 203, 0.1) !important;
}

.border-heather.border-alpha-md {
  --bs-border-color: rgba(180, 187, 203, 0.1) !important;
}

.border-mako-alpha-md {
  --bs-border-color: rgba(68, 72, 82, 0.1) !important;
}

.border-mako.border-alpha-md {
  --bs-border-color: rgba(68, 72, 82, 0.1) !important;
}

.border-manatee-alpha-md {
  --bs-border-color: rgba(139, 147, 168, 0.1) !important;
}

.border-manatee.border-alpha-md {
  --bs-border-color: rgba(139, 147, 168, 0.1) !important;
}

.border-mars-alpha-md {
  --bs-border-color: rgba(213, 50, 41, 0.1) !important;
}

.border-mars.border-alpha-md {
  --bs-border-color: rgba(213, 50, 41, 0.1) !important;
}

.border-mercury-alpha-md {
  --bs-border-color: rgba(232, 232, 232, 0.1) !important;
}

.border-mercury.border-alpha-md {
  --bs-border-color: rgba(232, 232, 232, 0.1) !important;
}

.border-pacific-blue-alpha-md {
  --bs-border-color: rgba(0, 180, 187, 0.1) !important;
}

.border-pacific-blue.border-alpha-md {
  --bs-border-color: rgba(0, 180, 187, 0.1) !important;
}

.border-plantation-alpha-md {
  --bs-border-color: rgba(39, 88, 85, 0.1) !important;
}

.border-plantation.border-alpha-md {
  --bs-border-color: rgba(39, 88, 85, 0.1) !important;
}

.border-puerto-rico-alpha-md {
  --bs-border-color: rgba(65, 196, 157, 0.1) !important;
}

.border-puerto-rico.border-alpha-md {
  --bs-border-color: rgba(65, 196, 157, 0.1) !important;
}

.border-royal-blue-alpha-md {
  --bs-border-color: rgba(92, 86, 232, 0.1) !important;
}

.border-royal-blue.border-alpha-md {
  --bs-border-color: rgba(92, 86, 232, 0.1) !important;
}

.border-spicy-mix-alpha-md {
  --bs-border-color: rgba(133, 75, 75, 0.1) !important;
}

.border-spicy-mix.border-alpha-md {
  --bs-border-color: rgba(133, 75, 75, 0.1) !important;
}

.border-turquoise-blue-alpha-md {
  --bs-border-color: rgba(104, 239, 173, 0.1) !important;
}

.border-turquoise-blue.border-alpha-md {
  --bs-border-color: rgba(104, 239, 173, 0.1) !important;
}

.border-valencia-alpha-md {
  --bs-border-color: rgba(220, 73, 65, 0.1) !important;
}

.border-valencia.border-alpha-md {
  --bs-border-color: rgba(220, 73, 65, 0.1) !important;
}

.border-victoria-alpha-md {
  --bs-border-color: rgba(81, 75, 144, 0.1) !important;
}

.border-victoria.border-alpha-md {
  --bs-border-color: rgba(81, 75, 144, 0.1) !important;
}

.border.border-alpha-sm {
  --bs-border-color: rgba(196, 200, 202, 0.05) !important;
}

.border-apricot-alpha-sm {
  --bs-border-color: rgba(232, 142, 94, 0.05) !important;
}

.border-apricot.border-alpha-sm {
  --bs-border-color: rgba(232, 142, 94, 0.05) !important;
}

.border-azure-alpha-sm {
  --bs-border-color: rgba(56, 105, 159, 0.05) !important;
}

.border-azure.border-alpha-sm {
  --bs-border-color: rgba(56, 105, 159, 0.05) !important;
}

.border-buttercup-alpha-sm {
  --bs-border-color: rgba(246, 164, 22, 0.05) !important;
}

.border-buttercup.border-alpha-sm {
  --bs-border-color: rgba(246, 164, 22, 0.05) !important;
}

.border-copper-rust-alpha-sm {
  --bs-border-color: rgba(151, 74, 90, 0.05) !important;
}

.border-copper-rust.border-alpha-sm {
  --bs-border-color: rgba(151, 74, 90, 0.05) !important;
}

.border-cornflower-blue-alpha-sm {
  --bs-border-color: rgba(100, 136, 255, 0.05) !important;
}

.border-cornflower-blue.border-alpha-sm {
  --bs-border-color: rgba(100, 136, 255, 0.05) !important;
}

.border-dodger-blue-alpha-sm {
  --bs-border-color: rgba(93, 85, 255, 0.05) !important;
}

.border-dodger-blue.border-alpha-sm {
  --bs-border-color: rgba(93, 85, 255, 0.05) !important;
}

.border-east-bay-alpha-sm {
  --bs-border-color: rgba(74, 88, 133, 0.05) !important;
}

.border-east-bay.border-alpha-sm {
  --bs-border-color: rgba(74, 88, 133, 0.05) !important;
}

.border-faded-jade-alpha-sm {
  --bs-border-color: rgba(64, 129, 130, 0.05) !important;
}

.border-faded-jade.border-alpha-sm {
  --bs-border-color: rgba(64, 129, 130, 0.05) !important;
}

.border-heather-alpha-sm {
  --bs-border-color: rgba(180, 187, 203, 0.05) !important;
}

.border-heather.border-alpha-sm {
  --bs-border-color: rgba(180, 187, 203, 0.05) !important;
}

.border-mako-alpha-sm {
  --bs-border-color: rgba(68, 72, 82, 0.05) !important;
}

.border-mako.border-alpha-sm {
  --bs-border-color: rgba(68, 72, 82, 0.05) !important;
}

.border-manatee-alpha-sm {
  --bs-border-color: rgba(139, 147, 168, 0.05) !important;
}

.border-manatee.border-alpha-sm {
  --bs-border-color: rgba(139, 147, 168, 0.05) !important;
}

.border-mars-alpha-sm {
  --bs-border-color: rgba(213, 50, 41, 0.05) !important;
}

.border-mars.border-alpha-sm {
  --bs-border-color: rgba(213, 50, 41, 0.05) !important;
}

.border-mercury-alpha-sm {
  --bs-border-color: rgba(232, 232, 232, 0.05) !important;
}

.border-mercury.border-alpha-sm {
  --bs-border-color: rgba(232, 232, 232, 0.05) !important;
}

.border-pacific-blue-alpha-sm {
  --bs-border-color: rgba(0, 180, 187, 0.05) !important;
}

.border-pacific-blue.border-alpha-sm {
  --bs-border-color: rgba(0, 180, 187, 0.05) !important;
}

.border-plantation-alpha-sm {
  --bs-border-color: rgba(39, 88, 85, 0.05) !important;
}

.border-plantation.border-alpha-sm {
  --bs-border-color: rgba(39, 88, 85, 0.05) !important;
}

.border-puerto-rico-alpha-sm {
  --bs-border-color: rgba(65, 196, 157, 0.05) !important;
}

.border-puerto-rico.border-alpha-sm {
  --bs-border-color: rgba(65, 196, 157, 0.05) !important;
}

.border-royal-blue-alpha-sm {
  --bs-border-color: rgba(92, 86, 232, 0.05) !important;
}

.border-royal-blue.border-alpha-sm {
  --bs-border-color: rgba(92, 86, 232, 0.05) !important;
}

.border-spicy-mix-alpha-sm {
  --bs-border-color: rgba(133, 75, 75, 0.05) !important;
}

.border-spicy-mix.border-alpha-sm {
  --bs-border-color: rgba(133, 75, 75, 0.05) !important;
}

.border-turquoise-blue-alpha-sm {
  --bs-border-color: rgba(104, 239, 173, 0.05) !important;
}

.border-turquoise-blue.border-alpha-sm {
  --bs-border-color: rgba(104, 239, 173, 0.05) !important;
}

.border-valencia-alpha-sm {
  --bs-border-color: rgba(220, 73, 65, 0.05) !important;
}

.border-valencia.border-alpha-sm {
  --bs-border-color: rgba(220, 73, 65, 0.05) !important;
}

.border-victoria-alpha-sm {
  --bs-border-color: rgba(81, 75, 144, 0.05) !important;
}

.border-victoria.border-alpha-sm {
  --bs-border-color: rgba(81, 75, 144, 0.05) !important;
}

/*# sourceMappingURL=polyfills.css.map */